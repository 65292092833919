import { useNavigate, useLocation } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { seo_tag_update } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Select from 'react-select';

export default function EditSeoUser({ isLoader }) {
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state || {};
    console.log('locationState :', locationState);
    const { TagId, Tag, Content, panel_name } = locationState;
    const [selectAll, setSelectAll] = useState(false);
    const [selectedPanels, setSelectedPanels] = useState([]);

    const options = [
        { label: 'Landing Page', value: 'landing_page' },
        { label: 'Teacher', value: 'teacher' },
        { label: 'Organization', value: 'organization' },
        { label: 'Organization Teacher', value: 'org_teacher' },
        { label: 'Parents', value: 'below_18' },
        { label: 'Kids', value: 'learner' },
        { label: 'Over 18', value: 'above_18' },
        { label: 'Schools-After School Centers', value: 'after_school' }
    ];

    const handleSelectAllChange = (e) => {
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            setSelectedPanels(options.map(option => option.value));
        } else {
            setSelectedPanels([]);
        }
    };

    const handlePanelChange = (selectedOptions) => {
        setSelectedPanels(selectedOptions ? selectedOptions.map(option => option.value) : []);
        if (selectedOptions.length === options.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    };

    const seoSchema = Yup.object({
        tag: Yup.string()
            .matches(/^[\S]{4,12}$/, 'Tag Name must be 4 to 12 characters long and contain no whitespace')
            .required("Please enter tag name"),
        content: Yup.string()
            .required("Please enter content"),
        panels: Yup.array()
            .min(1, "Please select at least one panel")
            .required("Please select at least one panel"),
    });

    const { register, watch, handleSubmit, formState: { errors }, setValue, control } = useForm({
        resolver: yupResolver(seoSchema)
    });

    const onSubmit = async (values) => {
        const obj = {
            id: TagId,
            tag: values.tag,
            content: values.content,
            panels: selectedPanels
        };
        isLoader(true);
        seo_tag_update(obj).then((response) => {
            if (response.code === '1') {
                TOAST_SUCCESS(response.message);
                navigate('/seo-user');
                isLoader(false);
            } else {
                TOAST_ERROR(response.message);
                isLoader(false);
            }
        });
    };

    useEffect(() => {
        setValue("tag", Tag);
        setValue("content", Content);

        if (Array.isArray(panel_name)) {
            const selected = panel_name.map(panel => panel?.panel_name);
            setSelectedPanels(selected);
            setValue("panels", selected);
        } else {
            setSelectedPanels([]);
            setValue("panels", []);
        }
    }, [Tag, Content, panel_name, setValue]);


    const handleBack = () => {
        navigate(-1);
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <div className="mx-5 mt-4">

                    <span onClick={handleBack} className="mb-5">
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                    <div className="text-center fs-3 fw-500 mt-4 ms-4 mb-1">
                        Edit Tag Data
                    </div>
                    <div className="row align-items-center justify-content-center mt-5">
                        <div className="col-lg-8">
                            <div className="card border-purple p-4" >
                                <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4">

                                    <div className="form-group mb-4">
                                        <label className="mb-2">Tag Name:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register('tag')}
                                        />
                                        {errors.tag && <div className="text-danger">{errors.tag.message}</div>}
                                    </div>

                                    <div className="form-group mb-4 position-relative class_title">
                                        <label className="mb-2">Content:</label>
                                        <textarea
                                            className="form-control"
                                            {...register('content')}
                                            maxLength={2000}
                                            rows={4}
                                        />
                                        <span className="position-absolute word_count">{2000 - (watch("content")?.split("")?.length ?? 0)} Words</span>
                                        {errors.content && <div className="text-danger">{errors.content.message}</div>}
                                    </div>

                                    <div className="form-group mb-4">
                                        <label className="mb-2">In which side you want to apply this Tag:</label>
                                        {!selectAll && (
                                            <>
                                                <Controller
                                                    control={control}
                                                    name="panels"
                                                    defaultValue={selectedPanels}  // Important to set the default value
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={options}
                                                            value={options.filter(option => selectedPanels?.includes(option.value))}
                                                            className='mt-2'
                                                            onChange={(selectedOptions) => {
                                                                const panelsValue = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                field.onChange(panelsValue);
                                                                handlePanelChange(selectedOptions);
                                                            }}
                                                            isSearchable={true}
                                                            isMulti={true}
                                                            placeholder="Select Panels..."
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    border: '1px solid rgba(102, 102, 102, 0.35)',
                                                                    borderRadius: '12px',
                                                                    padding: '5px 10px',
                                                                    minHeight: '46px',
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    zIndex: 9999,
                                                                }),
                                                            }}
                                                        />
                                                    )}
                                                />

                                                {errors.panels && <div className="text-danger mt-2">{errors.panels.message}</div>}
                                            </>
                                        )}
                                        {selectAll && (
                                            <input
                                                type="text"
                                                className="form-control py-2 mt-2"
                                                name="select_all"
                                                placeholder="All are selected"
                                                readOnly
                                                style={{ borderRadius: "12px" }}
                                            />
                                        )}
                                    </div>

                                    <div className="form-check mt-2">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="selectAll"
                                            checked={selectAll}
                                            onChange={handleSelectAllChange}
                                        />
                                        <label className="form-check-label text-secondary" htmlFor="selectAll">Select All Panels</label>
                                    </div>

                                    <div className="d-flex justify-content-center mt-3">
                                        <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>
}
