import React, { useState, useEffect } from 'react';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import Hdashboard from '../../../components/Hdashboard';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';
import { checkBalance } from '../../../api/apiHandler';
import { Helmet } from 'react-helmet';

export default function EWalletBalance({isLoader}) {

    const [data, setData] = useState([]);

    const GetBalanceData = () => {
        isLoader(true)
        checkBalance().then((response) => {
            if (response.code == 1) {
                setData(response.data)
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        GetBalanceData()
    }, [])

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <Helmet>
                <style>{`
  
  .card-container {
  border: 1px solid #e1dfdf;
  box-shadow: rgba(0, 0, 0, 0.20) 0px 2px 5px;
  border-radius: 10px;
  background-color: #570861;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-container:hover {
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-content {
  transition: color 0.3s ease;
}

.card-container:hover .card-content {
  color: #ffffffcc; / Lighter shade of white /
}

  `}</style>
            </Helmet>
            <main>
                <div className="dashboard-app d-lg-flex px-5 mt-1">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </header>

                    <span onClick={handleBack} className="mt-3">
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>

                    <div className="d-flex justify-content-between title mt-4 mb-2">
                        <p className="fs-3 fw-500">E Wallet Balance</p>
                    </div>

                    <div className='d-flex'>
                        <div className='col-md-3 mt-5 mx-3 p-4 card-container'>
                            <div className='content card-content'>
                                <p className='fs-5 fw-600'>Available Balance</p>
                                <p className='fs-5 fw-500 mt-3'>US$ {data?.length === 0 ? "0.00" : data?.available_balance}</p>
                            </div>
                        </div>

                        <div className='col-md-3 mt-5 mx-3 p-4 card-container'>
                            <div className='content card-content'>
                                <p className='fs-5 fw-600'>Pending Payment</p>
                                <p className='fs-5 fw-500 mt-3'>US$ {data?.length === 0 ? "0.00" : data?.pending_balance}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
