import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { subAdminList, update_sub_admin } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import SwalOld from "sweetalert"
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Swal from "sweetalert2";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import { Link } from "react-router-dom";
import customStyles from "../../../utils/dataTableStyles";

export default function SubAdminList({isLoader}) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const adminId = localStorage.getItem("aID")
    const [activeActionPending, setActiveActionPending] = useState(false);

    const GetSubAdminData = () => {
        isLoader(true)
        subAdminList({ id: adminId, search: search }).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false)
            }else{
                setData([])
                isLoader(false)
            }
        })
    }

    const handleView = (id) => {
        navigate('/user_details', { state: { id: id } })
    }

    const handleDelete = async (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    update_sub_admin({ id: id, is_delete: 1 }).then((response) => {
                        if (response.code == 1) {
                            setData((prevData) => prevData.filter((user) => user.id !== id))
                            TOAST_SUCCESS(response.message);
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    })
                } catch (error) {
                    TOAST_ERROR("Error in deleting Sub admin");
                }
            }
        });
    };

    const clickActive = (id, is_active) => {
        const actionText = is_active === 1 ? 'deactivate' : 'activate';
        setActiveActionPending(true)
        SwalOld({
            title: 'Are you sure?',
            text: 'Do you want to ' + actionText + ' this user ?',
            icon: 'warning',
            buttons: {
                confirm: {
                    text: is_active === 1 ? 'Deactivate' : 'Activate',
                    className: 'btn-success'
                },
                cancel: 'Cancel',
            },
            dangerMode: true,
        })
            .then((activate) => {
                setActiveActionPending(false)
                if (activate) {
                    if (is_active === 1) {
                        HandleMakeInactive(id);
                    } else {
                        HandleMakeActive(id);
                    }
                } else {
                    GetSubAdminData();
                }
            });
    }

    const HandleMakeActive = async (id) => {
        let admin_id = `${id}`
        let objCategory = {
            id: admin_id,
            is_active: "1"
        };
        isLoader(true)
        update_sub_admin((objCategory)).then((response) => {
            if (response.code == "1") {
                TOAST_SUCCESS(response.message);
                GetSubAdminData();
                isLoader(false)
            } else {
                TOAST_ERROR(response.message);
                isLoader(false)
            }
        })
    };

    const HandleMakeInactive = async (id) => {
        let admin_id = `${id}`
        let objCategory = {
            id: admin_id,
            is_active: "0"
        };
        isLoader(true)
        update_sub_admin((objCategory)).then((response) => {
            if (response.code == "1") {
                TOAST_SUCCESS(response.message);
                GetSubAdminData();
                isLoader(false)
            } else {
                TOAST_ERROR(response.message);
                isLoader(false)
            }
        })
    };

    // Define a common style for font size
    const fontSizeStyle = { fontSize: '16px' };

    // Function to generate column names with style
    const colName = (name) => <p style={fontSizeStyle}>{name}</p>;

    // Common function for selector with font size style
    const selectorName = (selector) => (row) => (
        <p style={{ fontSize: '14px' }}>{selector(row)}</p>
    );

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "100px"
          },
        {
            // name: colName('Image'),
            name: 'Image',
            cell: (data) => (
                <img
                    src={data.profile_image}
                    style={{ width: '50px', height: '50px'}}
                />
            ),
            sortable: true,
            width: "150px"
        },
        {
            name: "Name",
            // selector: selectorName((row) => row.name),
            selector: row => row.name,
            sortable: true,
            width: "250px",
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "350px"
        },
        {
            name: 'Role',
            selector: row => row.role == "A" ? "Sub Admin " : "-",
            sortable: true,
            width: "200px"
        },
        {
            name: 'Actions',
            width: "300px",
            cell: (row) => (
                <div className="d-flex justify-content-center">

                    <div className="col me-2">
                        <FontAwesome
                            name="view"
                            className="fs-5 fa-solid fa-eye  mx-2 my-2 bg-light text-secondary"
                            onClick={() => navigate("/sub_admin_details", { state: { subAdminId: row.id } })}
                        ></FontAwesome>
                    </div>

                    <div className="col me-2">
                        <div>
                            <FontAwesome
                                name="edit"
                                className="fs-5 fa-solid fa-edit mx-2 my-2 text-info"
                                onClick={() => navigate("/update_sub_admin", { state: { subAdminId: row.id } })}
                            ></FontAwesome>
                        </div>
                    </div>

                    <div className="col me-2">
                        <FontAwesome
                            name="delete"
                            className="fs-5 fa-solid fa-trash  mx-2 my-2 bg-light text-danger"
                            onClick={() => handleDelete(row.id)}
                        ></FontAwesome>
                    </div>
                </div>
            ),
        },
        {
            name: 'Active/InActive',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    <BootstrapSwitchButton
                        checked={row.is_active === 1}
                        onlabel='InActive'
                        offlabel='Active'
                        width={90}
                        size="sm"
                        offstyle="light text-dark"
                        onChange={() => {
                            { clickActive(row.id, row.is_active) }
                        }}
                    />
                </div>
            )
        }
    ]

    useEffect(() => {
        GetSubAdminData()
    }, [search])

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <Link className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </Link>
                            <Link to="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </Link>
                        </div>

                    </div>
                </header>
                <>
                    <div className="fs-3 fw-500 mt-4 ms-4 mb-1">
                        Sub Admin Data
                    </div>
                    <div className="px-5 mt-1">

                        <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                            <div className="row me-2">
                                <div className="col">
                                    <CInputGroup>
                                        <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                        <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                            <FontAwesome className="fa-solid fa-search" name="search" />
                                        </CButton>
                                    </CInputGroup>
                                </div>
                            </div>
                            <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-1 d-inline-block" onClick={() => navigate("/create_sub_admin")}>
                                Create Sub Admin
                            </button>
                        </div>
                        <div>
                            {(
                                data && data.length > 0 ? (
                                    <>
                                        <DataTable columns={columns} data={data} pagination customStyles={customStyles}/>
                                    </>
                                ) : (
                                    <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                                )
                            )}
                        </div>
                    </div>
                </>
            </div>
        </main>
    </>
}