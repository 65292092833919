import React, { useState, useEffect } from 'react';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import { RefundsRequestListing } from '../../../api/apiHandler';
import 'jspdf-autotable';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { CButton, CFormInput, CInputGroup } from '@coreui/react';
import FontAwesome from 'react-fontawesome';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import customStyles from '../../../utils/dataTableStyles';

export default function RefundList({isLoader}) {

    const [refundData, setRefundData] = useState([]);
    const navigate = useNavigate();
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [search, setSearch] = useState('');

    const currentDate = new Date();
    const defaultStartDate = currentDate.toISOString().split('T')[0];
    const defaultStartMonth = currentDate.toISOString().slice(0, 7);
    let initialStartDate = defaultStartDate;
    let initialEndDate = defaultStartDate;

    if (selectedPeriod === 'daily') {
        initialStartDate = currentDate.toISOString().split('T')[0];
        initialEndDate = currentDate.toISOString().split('T')[0];
    } else if (selectedPeriod === 'weekly') {
        const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        initialStartDate = sevenDaysAgo.toISOString().split('T')[0];
        initialEndDate = currentDate.toISOString().split('T')[0];
    }

    // Set the state variables with the initial values
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [startMonth, setStartMonth] = useState(defaultStartMonth);
    const [endMonth, setEndMonth] = useState(defaultStartMonth);
    const currentYear = new Date().getFullYear();
    const [startYear, setStartYear] = useState(currentYear);
    const [endYear, setEndYear] = useState(currentYear);

    useEffect(() => {
        fetchEwalletData();
    }, [selectedPeriod, startDate, endDate, startMonth, endMonth, startYear, endYear, search]);

    const getCurrentMonthYear = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
        return `${year}-${month}`;
    };

    const currentMonthYear = getCurrentMonthYear();

    const fetchEwalletData = () => {
        let requestData = {};

        if (selectedPeriod === 'daily') {
            requestData = {
                type: 'daily',
                startDate: startDate,
                endDate: endDate
            };
        } else if (selectedPeriod === 'weekly') {
            requestData = {
                type: 'weekly',
                startDate: startDate,
                endDate: endDate
            };
        } else if (selectedPeriod === 'monthly') {
            requestData = {
                type: 'monthly',
                startDate: startMonth,
                endDate: endMonth
            };
        } else if (selectedPeriod === 'yearly') {
            requestData = {
                type: 'yearly',
                startYear: startYear,
                endYear: endYear
            };
        }
        const requestDataWithSearch = { ...requestData, search: search };
        isLoader(true)
        RefundsRequestListing(requestDataWithSearch)
            .then((response) => {
                if (response.code === "1") {
                    const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                    setRefundData(dataWithRowCount);
                    isLoader(false)
                } else {
                    setRefundData([]);
                    isLoader(false)
                }
            })
            .catch((error) => {
                setRefundData([]);
                isLoader(false)
            });
    };

    const handlePeriodChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSelectedPeriod(selectedOption.value);
        // Update start and end dates immediately
        const currentDate = new Date();
        if (selectedOption.value === 'daily') {
            setStartDate(currentDate.toISOString().split('T')[0]);
            setEndDate(currentDate.toISOString().split('T')[0]);
        } else if (selectedOption.value === 'weekly') {
            const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
            setStartDate(sevenDaysAgo.toISOString().split('T')[0]);
            setEndDate(currentDate.toISOString().split('T')[0]);
        }
    };

    const handleStartMonthChange = (value) => {
        setStartMonth(value);
    };

    const handleEndMonthChange = (value) => {
        setEndMonth(value);
    };

    const getYearOptions = (minYear) => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = minYear; year <= currentYear; year++) {
            years.push(year);
        }
        return years;
    };

    const renderYearContent = (year) => {
        const tooltipText = `Tooltip for year: ${year}`;
        return <span title={tooltipText}>{year}</span>;
    };

    const renderCustomHeader = ({
        date,
        changeYear,
        decreaseYear,
        increaseYear,
        prevYearButtonDisabled,
        nextYearButtonDisabled
    }) => (
        <div>
            <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                {'<'}
            </button>
            <span>{date.getFullYear()}</span>
            <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
                {'>'}
            </button>
        </div>
    );

    const refundColumns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "90px"
        },
        {
            name: 'Requested Date',
            selector: row => {
                const date = new Date(row.enrolled_at);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "200px"
        },
        {
            name: 'Request From',
            selector: row => row.refund_request_from,
            sortable: true,
            width: "250px",
            cell: row => (
                <span
                    onClick={() => navigate('/user_details', { state: { id: row.user_id } })}
                    style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                >
                    {row.refund_request_from}
                </span>
            )
        },
        {
            name: 'Request To',
            selector: row => row.refund_request_to,
            sortable: true,
            width: "250px",
            cell: row => (
                row.category == 'teacher' ? (
                    <span
                        onClick={() => navigate('/teacher_details', { state: { teacherId: row.teacher_id } })}
                        style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                    >
                        {row.refund_request_to}
                    </span>
                ) : row.category == 'organization' ? (
                    <span
                        onClick={() => navigate('/org_details', { state: { userId: row.teacher_id } })}
                        style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
                    >
                        {row.refund_request_to}
                    </span>
                ) : (
                    <span>{row.refund_request_to}</span>
                )
            )
        },
        {
            name: 'Amount',
            selector: row => `$ ${row.amount}`,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Transaction Id',
            selector: row => row.transaction_id,
            sortable: true,
            width: "300px"
        },
        // {
        //     name: 'Program Name',
        //     selector: row => row.activity_title,
        //     sortable: true,
        //     width: "150px"
        // },
        // {
        //     name: (
        //         <>
        //             Program<br />Type
        //         </>
        //     ),
        //     selector: row => row.activity_type,
        //     sortable: true,
        //     width: "90px"
        // },
        // {
        //     name: (
        //         <>
        //             Platform<br />Type
        //         </>
        //     ),
        //     selector: row => row.platform_type,
        //     sortable: true,
        //     width: "90px"
        // },
        // {
        //     name: 'Duration',
        //     selector: row => row.duration,
        //     sortable: true,
        //     width: "160px"
        // },
        {
            name: 'Status',
            selector: row => (
                <button className="bg-warning border-0 fw-500" style={{ width : "100px" }}>
                    {row.payment_status}
                </button>
            ),
            sortable: true,
            width: "180px"
        },
        {
            name: 'Request Status',
            selector: row => {
                if (row.refund_request_status === "Rejected") {
                    return (
                        <div>
                            <button
                                className="bg-danger border-0 text-light fw-500"
                                style={{ marginRight: "5px" ,width : "100px"}}
                            >
                                {row.refund_request_status}
                            </button>
                        </div>
                    );
                } else {
                    const buttonClass = row.refund_request_status === "Accepted"
                        ? "bg-success"
                        : "bg-warning";
                    return (
                        <button
                            className={`${buttonClass} border-0 text-light fw-500`}
                            style={{ width : "100px" }}
                        >
                            {row.refund_request_status}
                        </button>
                    );
                }
            },
            sortable: true,
            width: "200px"
        },
        {
            name: 'View Details',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    <div className="col me-2">
                        <FontAwesome
                            name="view"
                            className="fs-5 fa-solid fa-eye  mx-2 my-2 bg-light text-secondary"
                            onClick={() => navigate("/refund_request_details", { state: row })}
                        ></FontAwesome>
                    </div>
                </div>
            ),
        }
    ]

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <>
            <JqueryLoad />
            {/* <Hdashboard /> */}
            <main>
                {/* <div className="dashboard-app d-lg-flex px-5 mt-1"> */}
                <div className="px-5 mt-4">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </header>

                    <span onClick={handleBack} className="mt-3">
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>

                    <div className="d-flex justify-content-between title mt-3 mb-2">
                        <p className="fs-3 fw-500">Refund Request List</p>
                    </div>

                    <div className=' d-flex col-md-10 justify-content-start mt-3'>
                        <div className=' col-md-2'>
                            <label className="mb-2">Select Time Period :</label>
                            <Select
                                options={[
                                    { value: "daily", label: "Daily" },
                                    { value: "weekly", label: "Weekly" },
                                    { value: "monthly", label: "Monthly" },
                                    { value: "yearly", label: "Yearly" },
                                ]}
                                className="skip_pdf"
                                id="period-select"
                                value={selectedOption}
                                onChange={handlePeriodChange}
                                styles={{
                                    width: "100%",
                                    maxWidth: "200px",
                                    minHeight: "40px"
                                }}
                                placeholder="Select Time Period"
                            />
                        </div>

                        <div className='col-md-5'>

                            {selectedPeriod === 'daily' && (
                                <div className="d-flex justify-content-end">
                                    <div className='col-md-5 ms-4'>
                                        <label htmlFor="daily-start-date" className='mb-2'>Start Date:</label>
                                        <input
                                            type="date"
                                            name="start_date"
                                            id="daily-start-date"
                                            className="form-control"
                                            required=""
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-5 ms-4">
                                        <label htmlFor="daily-end-date" className='mb-2' >End Date:</label>
                                        <input
                                            type="date"
                                            name="end_date"
                                            id="daily-end-date"
                                            className="form-control"
                                            min={startDate}
                                            required=""
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            )}

                            {selectedPeriod === 'weekly' && (
                                <div className="d-flex justify-content-end">
                                    <div className='col-md-5 ms-4'>
                                        <label htmlFor="weekly-start-date" className='mb-2'>Start Date:</label>
                                        <input
                                            type="date"
                                            name="start_date"
                                            id="weekly-start-date"
                                            className="form-control"
                                            required=""
                                            value={startDate}
                                            onChange={(e) => {
                                                const selectedStartDate = e.target.value;
                                                const sevenDaysLater = new Date(new Date(selectedStartDate).getTime() + 7 * 24 * 60 * 60 * 1000);
                                                const formattedEndDate = sevenDaysLater.toISOString().split('T')[0];
                                                setStartDate(selectedStartDate);
                                                setEndDate(formattedEndDate);
                                            }}
                                        />
                                    </div>

                                    <div className='col-md-5 ms-4'>
                                        <label htmlFor="weekly-end-date" className="mb-2">End Date:</label>
                                        <input
                                            type="date"
                                            name="end_date"
                                            id="weekly-end-date"
                                            className="form-control"
                                            required=""
                                            value={endDate}
                                            onChange={(e) => {
                                                const selectedEndDate = e.target.value;
                                                const sevenDaysBefore = new Date(new Date(selectedEndDate).getTime() - 7 * 24 * 60 * 60 * 1000);
                                                const formattedStartDate = sevenDaysBefore.toISOString().split('T')[0];
                                                if (new Date(formattedStartDate) > new Date(startDate)) {
                                                    setStartDate(formattedStartDate);
                                                }
                                                setEndDate(selectedEndDate);
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            {selectedPeriod === 'monthly' && (
                                <div className="d-flex justify-content-end">
                                    <div className="col-md-5 ms-4">
                                        <label htmlFor="startMonthPicker" className="mb-2">Start Month-Year:</label>
                                        <input
                                            className="form-control"
                                            type="month"
                                            id="startMonthPicker"
                                            value={startMonth}
                                            onChange={(e) => handleStartMonthChange(e.target.value)}
                                            max={currentMonthYear}
                                        />
                                    </div>
                                    <div className="col-md-5 ms-4">
                                        <label htmlFor="endMonthPicker" className="mb-2">End Month-Year:</label>
                                        <input
                                            className="form-control"
                                            type="month"
                                            id="endMonthPicker"
                                            min={startMonth}
                                            value={endMonth}
                                            onChange={(e) => handleEndMonthChange(e.target.value)}
                                            max={currentMonthYear}
                                        />
                                    </div>
                                </div>
                            )}

                            {selectedPeriod === 'yearly' && (
                                <div className="d-flex justify-content-end">
                                    <div className='col-md-5 ms-4'>
                                        <label htmlFor="yearPicker" className='mb-2'>Start Year:</label>
                                        <DatePicker
                                            selected={new Date(startYear, 0, 1)}
                                            onChange={(date) => {
                                                const newStartYear = date.getFullYear();
                                                setStartYear(newStartYear);
                                                if (newStartYear > endYear) {
                                                    setEndYear(newStartYear);
                                                }
                                            }}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            renderCustomHeader={renderCustomHeader}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className='col-md-5 ms-4'>
                                        <label htmlFor="yearPickerEnd" className="mb-2">End Year:</label>
                                        <DatePicker
                                            selected={new Date(endYear, 0, 1)}
                                            onChange={(date) => {
                                                const newEndYear = date.getFullYear();
                                                setEndYear(newEndYear);
                                            }}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            renderCustomHeader={renderCustomHeader}
                                            includeDates={getYearOptions(startYear).map(year => new Date(year, 0, 1))}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-4 mb-4">
                        <CInputGroup>
                            <CFormInput type="text" placeholder="Search" value={search} onChange={(e) => {
                                setSearch(e.target.value);
                            }} />
                            <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                <FontAwesome className="fa-solid fa-search" name="search" />
                            </CButton>
                        </CInputGroup>
                    </div>

                    <div id='download_earnings'>
                        <div className="px-1 mt-5">
                            <>
                                {refundData && refundData.length > 0 ? (
                                    <DataTable columns={refundColumns} data={refundData} pagination customStyles={customStyles} />
                                ) : (
                                    <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
