import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { classLearnerList, learnerBlockUnblock, learnerDelete } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import FontAwesome from "react-fontawesome";
import Swal from "sweetalert2";
import SwalOld from "sweetalert"
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import customStyles from "../../../utils/dataTableStyles";

export default function ClassLearner({ isLoader }) {

    const [classLearnerData, setClassLearnerData] = useState([])
    const [activeActionPending, setActiveActionPending] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const classId = location.state && location.state.class_id
    const [search, setSearch] = useState('');
    const role = localStorage.getItem("adminRole");
    const AdminId = localStorage.getItem("aID")

    const GetClassLearnerData = () => {
        isLoader(true)
        classLearnerList({ class_id: classId, search: search }).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setClassLearnerData(dataWithRowCount);
                isLoader(false)
            } else {
                setClassLearnerData([])
                isLoader(false)
            }
        });
    }

    const handleDelete = async (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    learnerDelete({ id: id }).then((response) => {
                        if (response.code == 1) {
                            setClassLearnerData((prevData) => prevData.filter((user) => user.id !== id))
                            TOAST_SUCCESS(response.message);
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    })
                } catch (error) {
                    TOAST_ERROR("Error deleting learner");
                }
            }
        });
    };

    const handleView = (id) => {
        navigate('/learnerdetails', { state: { LearnerId: id } })
    }

    const handleView3 = (id) => {
        navigate('/learner-subscription-class-details', { state: { learner_id: id } });
    }

    const clickActive = (id, is_active, learner_name, learner_email) => {
        const actionText = is_active === 1 ? 'block' : 'unblock';
        setActiveActionPending(true); // Set pending action state
        SwalOld({
            title: 'Are you sure?',
            text: 'You want to ' + actionText + ' this learner ?',
            icon: 'warning',
            buttons: {
                confirm: {
                    text: is_active === 1 ? 'Block' : 'Unblock',
                    className: 'btn-success'
                },
                cancel: 'Cancel',
            },
            dangerMode: true,
        })
            .then((activate) => {
                setActiveActionPending(false); // Set pending action state
                if (activate) {
                    if (is_active === 1) {
                        HandleMakeInactive(id, learner_email, learner_name);
                    } else {
                        HandleMakeActive(id, learner_email, learner_name);
                    }
                } else {
                    GetClassLearnerData();
                }
            });
    }

    const HandleMakeActive = async (id, learner_email, learner_name) => {
        let catid = `${id}`
        let objCategory = {
            id: catid,
            is_active: "1",
            name: learner_name,
            email: learner_email
        };
        isLoader(true)
        learnerBlockUnblock((objCategory)).then((response) => {
            if (response.code == "1") {
                updateLearnerStatus(id, 1); // Update locally
                TOAST_SUCCESS(response.message);
                GetClassLearnerData();
                isLoader(false)
            } else {
                TOAST_ERROR(response.message);
                isLoader(false)
            }
        })
    };

    const HandleMakeInactive = async (id, learner_email, learner_name) => {
        let catid = `${id}`
        let objCategory = {
            id: catid,
            is_active: "0",
            name: learner_name,
            email: learner_email
        };
        isLoader(true)
        learnerBlockUnblock((objCategory)).then((response) => {
            if (response.code == "1") {
                updateLearnerStatus(id, 0); // Update locally
                TOAST_SUCCESS(response.message);
                GetClassLearnerData();
                isLoader(false)
            } else {
                TOAST_ERROR(response.message);
                isLoader(false)
            }
        })
    };

    const updateLearnerStatus = (id, status) => {
        setClassLearnerData(prevData =>
            prevData.map(learner =>
                learner.id === id ? { ...learner, is_active: status } : learner
            )
        );
    };

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "60px"
        },
        {
            name: 'Registered Date',
            selector: row => {
                const date = new Date(row.created_at);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "150px"
        },
        {
            name: 'Image',
            cell: (learnerData) => (
                <img
                    src={learnerData.learner_profile_img}
                    style={{ width: '50px', height: '50px'}}
                />
            ),
            sortable: true,
            width: "90px"
        },
        {
            name: 'Learner Name',
            selector: row => row.learner_name,
            sortable: true,
            width: "180px"
        },
        {
            name: 'Learner Email',
            selector: row => row.learner_email,
            sortable: true,
            width: "210px"
        },
        {
            name: 'Learner Age',
            selector: row => row.learner_age,
            sortable: true,
            width: "130px"
        },
        {
            name: 'Sub Category',
            selector: row => row.sub_cat,
            sortable: true,
            width: "190px"
        },
        {
            name: 'Subscriptions',
            cell: (row) => (
                <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }} onClick={() => handleView3(row.id)}>View Class</button>
            ),
            sortable: true,
            width: "160px"
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    <div className="col me-2">
                        <FontAwesome
                            name="view"
                            className="fs-5 fa-solid fa-eye  mx-2 my-2 bg-light text-secondary"
                            onClick={() => handleView(row.id)}
                        ></FontAwesome>
                    </div>

                    <div className="col me-2">
                        <FontAwesome
                            name="delete"
                            className="fs-5 fa-solid fa-trash  mx-2 my-2 bg-light text-danger"
                            onClick={() => handleDelete(row.id)}
                        ></FontAwesome>
                    </div>

                </div>
            ),
        },
        {
            name: 'Block/UnBlock',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    <BootstrapSwitchButton
                        checked={row.is_active === 1 ? true : false}
                        onlabel='Block'
                        offlabel='UnBlock'
                        width={90}
                        size="sm"
                        offstyle="light text-dark"
                        onChange={() => {
                            { clickActive(row.id, row.is_active, row.learner_name, row.learner_email) }
                        }}
                    />
                </div>
            ),
        },
    ]

    useEffect(() => {
        GetClassLearnerData()
    }, [search])

    const handleBack = () => {
        navigate(-1);
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>

                <span onClick={handleBack} className="mx-4 my-3 mt-4">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>

                    <span className="ms-2">Back</span>
                </span>
                <>
                    <div className="fs-3 fw-500 ms-4 mb-2">
                        Class Learner Data
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                        <div className="row me-5">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome className="fa-solid fa-search" name="search" />
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                    </div>

                    <div className="px-5 mt-1">
                        {classLearnerData.length === 0 ? (
                            <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No Data Found</p>
                        ) : (
                            classLearnerData && classLearnerData.length > 0 && (
                                <DataTable
                                    persistTableHead
                                    columns={columns}
                                    data={classLearnerData}
                                    pagination
                                    customStyles={customStyles}
                                    className="custom-data-table"
                                />
                            )
                        )}
                    </div>
                </>
            </div>
        </main>
    </>
}