import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { new_leads_class_statistics, request_by_preferred_and_non_preferred } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import DataTable from "react-data-table-component";
import customStyles from "../../../utils/dataTableStyles";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import FontAwesome from "react-fontawesome";

export default function NewLeadsClassLearner({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [search, setSearch] = useState('')

    const handleGetData = () => {
        isLoader(true)
        new_leads_class_statistics({search : search}).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    useEffect(() => {
        handleGetData();
    }, [search]);

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "200px"
        },
        {
            name: 'Learner Name',
            selector: row => row.full_name,
            sortable: true,
            width: "400px"
        },
        {
            name: 'Number of classes learner requested',
            selector: row => row.times_requested,
            sortable: true,
            width: "400px"
        },
        {
            name: 'Number of classes learners actually subscribed',
            selector: row => row.times_joined,
            sortable: true,
            width: "400px"
        }
    ]

    const handleBack = () => {
        navigate(-1);
    }

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <span onClick={handleBack} className="mt-4 ms-4">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>
                    <span className="ms-2">Back</span>
                </span>
                <>
                    <div className="px-5 mt-4">
                        <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                            <div className="row me-2">
                                <div className="col">
                                    <CInputGroup>
                                        <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                        <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                            <FontAwesome className="fa-solid fa-search" name="search" />
                                        </CButton>
                                    </CInputGroup>
                                </div>
                            </div>
                        </div>
                        <div>
                            {(data && data.length > 0 ? (
                                <DataTable columns={columns} data={data} pagination customStyles={customStyles} />
                            ) : (
                                <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No Data Found</p>
                            )
                            )}
                        </div>
                    </div>
                </>
            </div>
        </main>
    </>
}