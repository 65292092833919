import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { admin_permission_detail, seo_list, seo_tag_delete } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import Swal from "sweetalert2";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import customStyles from "../../../utils/dataTableStyles";

export default function SeoUser({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const AdminId = localStorage.getItem("aID")
    const [permissions, setPermission] = useState([]);
    const role = localStorage.getItem("adminRole");
    const [search, setSearch] = useState('')

    const handleGetData = () => {
        isLoader(true)
        seo_list({ search: search }).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    const handleEditSeo = (TagId, Tag, Content,panel_name) => {
        navigate('/edit-seo-user', { state: { TagId: TagId, Tag: Tag, Content: Content,panel_name:panel_name } })
    }

    const handleDeleteSeoTag = async (TagId) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    const response = await seo_tag_delete({ id: TagId });
                    if (response.code === '1') {
                        TOAST_SUCCESS(response.message);
                        handleGetData();
                        isLoader(false)
                    } else {
                        TOAST_ERROR(response.message);
                        isLoader(false)
                    }
                } catch (error) {
                    console.error("Error deleting data:", error);
                }
            }
        });
    };

    const subAdminPermission = () => {
        admin_permission_detail({ id: AdminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([])
                }
            })
            .catch((error) => {
                setPermission([])
            });
    }

    useEffect(() => {
        handleGetData();
        if (role !== "S") {
            subAdminPermission();
        }
    }, [search])

    const seoPermission = permissions.find(permission => permission.menu_name === "SEO Users");

    // Check if add,edit,delete is allowed for "SEO Users"
    const isAddAllowed = seoPermission && seoPermission.add === 1;
    const isEditAllowed = seoPermission && seoPermission.edit === 1;
    const isDeleteAllowed = seoPermission && seoPermission.delete === 1;

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "100px"
          },
        {
            name: 'Tag',
            selector: row => row.tag,
            sortable: true,
            width: "300px"
        },
        {
            name: 'Content',
            selector: row => row.content,
            sortable: true,
            width: "1000px"
        },
        {
            name: role == "S" || isEditAllowed || isDeleteAllowed ? 'Actions' : '',
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    <div className="col me-2">
                        {(role === "S" || isEditAllowed) &&
                            <div>
                                <FontAwesome
                                    name="edit"
                                    className="fs-5 fa-solid fa-edit mx-2 my-2 text-info"
                                    onClick={() => handleEditSeo(row.id, row.tag, row.content , row.panel_name)}
                                ></FontAwesome>
                            </div>
                        }
                    </div>
                    {(role === "S" || isDeleteAllowed) &&
                        <div className="col me-2">
                            <FontAwesome
                                name="delete"
                                className="fs-5 fa-solid fa-trash  mx-2 my-2 bg-light text-danger"
                                onClick={() => handleDeleteSeoTag(row.id)}
                            ></FontAwesome>
                        </div>
                    }
                </div>
            )
        }
    ]

    const addData = () => {
        navigate('/add-seo-user')
    }

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <>
                    <div className="fs-3 fw-500 mt-4 ms-4 mb-1">
                        SEO Tag Data
                    </div>
                    <div className="px-5 mt-1">
                        <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                            <div className="row me-2">
                                <div className="col">
                                    <CInputGroup>
                                        <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                        <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                            <FontAwesome className="fa-solid fa-search" name="search" />
                                        </CButton>
                                    </CInputGroup>
                                </div>
                            </div>
                            {(role === "S" || isAddAllowed) &&
                                <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={addData}>
                                    + Add Tag
                                </button>
                            }
                        </div>
                        <div>
                            {(
                                data && data.length > 0 ? (
                                    <DataTable columns={columns} data={data} pagination customStyles={customStyles}/>
                                ) : (
                                    <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                                )
                            )}
                        </div>
                    </div>
                </>
            </div>
        </main>
    </>
}