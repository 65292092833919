import React from 'react';
import Hdashboard from '../../../components/Hdashboard';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import { useNavigate } from 'react-router-dom';

const ReportItem = ({ onClick, text }) => (
    <div className='mx-5 mb-4 p-3 border-reports' onClick={onClick} style={{ cursor: "pointer" }}>
        <p className='fs-5 fw-500 ms-2'>{text}</p>
    </div>
);

export default function Reports() {
    const navigate = useNavigate();
    const reports = [
        { path: "/top_10_teacher_report", text: "1. Top 10 teachers generating the most income" },
        { path: "/top_10_organization_report", text: "2. Top 10 organizations generating the most income" },
        { path: "/earnings", text: "3. Total income received" },
        { path: "/total_payment_to_teacher_organization_report", text: "4. Total number of payments to teachers and organizations" },
        { path: "/total_review_received", text: "5. Number of Reviews received and total for each star" },
        { path: "/teacher_org_joined_from_each_country", text: "6. List of the countries and the number of teachers joined from each country" },
        { path: "/learner_joined_from_each_country", text: "7. List of countries and students that joined from each country" },
        { path: "/enrollement_for_each_subject", text: "8. List of the subjects and enrolments for each subject" },
        { path: "/complaint_received", text: "9. Most number of complaints received and the teachers and organizations" },
        { path: "/top_25_most_popular courses", text: "10. Top 25 most popular courses" },
    ];

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="index.php" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="fs-3 fw-500 mt-4 ms-4 mb-4">Reports</div>
                    <div className='mx-4 my-2 d-flex flex-column'>
                        {reports.map((report, index) => (
                            <ReportItem
                                key={index}
                                onClick={() => navigate(report.path)}
                                text={report.text}
                            />
                        ))}
                    </div>
                </div>
            </main>
        </>
    );
}
