import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../../utils/common.service";
import { uploadImageOnAWS } from "../../aws/Service";
import { update_category } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";

export default function EditCategory({isLoader}) {

    const navigate = useNavigate()
    const fileRef = useRef();
    const [image, setImage] = useState(null);
    const location = useLocation()

    const categoryId = location.state && location.state.categoryId
    const Image = location.state && location.state.Image
    const CategoryName = location.state && location.state.CategoryName

    const categorySchema = Yup.object().shape({
        category_name: Yup.string()
            .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, 'Please enter a proper category name')
            .required('Please enter a category name'),
        image: Yup.mixed()
            .test('fileType', 'Only image files are allowed', value => {
                if (!value || !(value instanceof Blob)) return true;
                return value && /^image\/.+$/i.test(value.type);
            })
            .required("Please upload a category image"),
    });

    const categoryForm = useFormik({
        initialValues: {
            category_name: '',
            image: null,
        },
        validationSchema: categorySchema,
        validateOnBlur: false,
        onSubmit: async (data) => {
            try {
                if (data.image !== null && data.image instanceof Blob) {
                    var main_category_image = await uploadImageOnAWS(data?.image, "main_category");
                }
                const obj = {
                    id: categoryId,
                    category_name: data.category_name,
                };
                if (data.image !== null && data.image !== undefined) {
                    obj.main_category_image = main_category_image
                }
                isLoader(true)
                update_category(obj).then((response) => {
                    if (response.code === '1') {
                        TOAST_SUCCESS(response.message);
                        navigate('/category');
                        isLoader(false)
                    } else {
                        TOAST_ERROR(response.message);
                        isLoader(false)
                    }
                });
            } catch (error) {
                console.error("Error in form submission:", error);
            }
        }
    });

    const uploadImage = () => {
        fileRef.current.click();
    };

    const uploadImageEvent = async (e) => {
        const imgUrl = URL.createObjectURL(e.target.files[0]);
        const bufferImage = await convertToBase64(e.target.files[0]);
        setImage(bufferImage);
        categoryForm.setFieldValue('image', e.target.files[0]);
    };

    useEffect(() => {
        categoryForm.setValues({
            category_name: CategoryName,
            image: Image
        });
        if (Image) {
            setImage(Image);
        }
    }, [CategoryName, Image]);

    const handleBack = () => {
        navigate(-1);
    };

    const displayError = (fieldName) =>
        categoryForm.touched[fieldName] && categoryForm.errors[fieldName];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <span onClick={handleBack} className="mb-3 ms-5 mt-3">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>
                    <span className="ms-2">Back</span>
                </span>

                <p className="d-flex justify-content-center fs-3 fw-500"> Edit Category</p>
                <div className="row align-items-center justify-content-center mt-5">
                    <div className="col-lg-6">
                        <div className="card border-purple p-4" >
                            <form onSubmit={categoryForm.handleSubmit} className="custom_form mt-4">
                                <div className="d-flex justify-content-center">
                                    <span onClick={uploadImage}>
                                        <img
                                            src={image === null ? "./assets/images/image_upload.png" : image}
                                            alt=""
                                            className="img img-responsive card border-purple p-2"
                                            height={"100px"}
                                            width={"100px"}
                                            accept="image/*"
                                            style={{ borderRadius: "50%" }}
                                        />
                                    </span>
                                    <input
                                        type="file"
                                        ref={fileRef}
                                        className="form-control d-none"
                                        name="image"
                                        onChange={uploadImageEvent}
                                    />
                                </div>
                                {displayError("image") && (
                                    <div className="text-danger d-flex justify-content-center">{categoryForm.errors.image}</div>
                                )}

                                <div className="form-group mb-4">
                                    <label htmlFor="name" className="mb-2">Category Name:</label>
                                    <input
                                    id="name"
                                        type="text"
                                        className="form-control"
                                        name="category_name"
                                        onChange={categoryForm.handleChange}
                                        // onBlur={categoryForm.handleBlur}
                                        value={categoryForm.values.category_name}
                                    />
                                    {displayError("category_name") && (
                                        <div className="text-danger">{categoryForm.errors.category_name}</div>
                                    )}
                                </div>
                                <div className="d-flex justify-content-center mt-3">
                                    <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>
}