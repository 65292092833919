import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React from "react";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";

export default function SubjectDetails() {

  const navigate = useNavigate()
  const location = useLocation()
  const categoryName = location.state && location.state.categoryName
  const mainCategoryName = location.state && location.state.mainCategoryName
  const subjectName = location.state && location.state.subjectName
  const Image = location.state && location.state.Image

  const handleBack = () => {
    navigate(-1);
  };

  return <>
    <JqueryLoad />
    <Hdashboard />
    <main>
      <div className="dashboard-app d-lg-flex">
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
          <div className="align-items-center justify-content-between d-flex">
            <div className="d-flex align-items-center">
              <a className="menu-toggle align-items-center d-lg-none d-flex">
                <i className="fa fa-bars" />
              </a>
              <a href="index.php" className="d-lg-none d-block brand-logo">
                <img src="./logo.png" alt="logo" />
              </a>
            </div>
          </div>
        </header>
        <div className="mx-5 mt-4">
          <span onClick={handleBack} className="mb-5">
            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
            </svg>
            <span className="ms-2">Back</span>
          </span>
          <p className="d-flex justify-content-center fs-3 fw-500">Subject Details</p>
          <div className="class_box mb-md-5 mb-4 mt-5 d-flex justify-content-center">

            <div className="card single-box d-flex flex-column align-items-center" style={{ width: "600px" }} >
              <img
                src={Image}
                className="img img-responsive preview_post mb-2 mt-4"
                height={'150px'}
                width={'150px'}
                alt="CategoryImage"
                style={{ borderRadius: '50%' }}
              /><br /><br />
              <p className="mb-3 fw-500">Main Category Name :- {mainCategoryName}</p>
              <p className="mb-3 fw-500">Category Name :- {categoryName}</p>
              <p className="mb-5 fw-500">Subject Name :- {subjectName}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </>
}