import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { admin_detail, admin_permission_detail } from "../../../api/apiHandler";
import Image from "../../../user.png";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";

export default function SubAdminDetails({isLoader}) {
    
    const navigate = useNavigate()
    const location = useLocation()
    const [adminData, setAdminData] = useState({});
    const [adminPermissions, setAdminPermissions] = useState([]);
    const subAdminId = location.state && location.state.subAdminId;

    const GetSubAdminDetails = () => {
        isLoader(true)
        admin_detail({ id: subAdminId })
            .then((response) => {
                if (response.code === '1') {
                    setAdminData(response.data);
                    isLoader(true)
                    admin_permission_detail({ id: subAdminId })
                        .then((response_permission) => {
                            if (response_permission.code === '1') {
                                setAdminPermissions(response_permission.data);
                                isLoader(false)
                            } else {
                                 setAdminPermissions([]);
                                 isLoader(false)
                            }
                        })
                        .catch((error) => {
                             setAdminPermissions([]);
                        });
                } else {
                     setAdminPermissions([]);
                }
            })
            .catch((error) => {
                 setAdminPermissions([]);
            });
    }

    useEffect(() => {
        GetSubAdminDetails();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>

            </div>
        </header>

        <section className="main_sec">

            <div className="mx-5 mt-4">
                <div className="">
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>

                        <span className="ms-2">Back</span>
                    </span>
                </div>
                <p className="fs-4 fw-500 text-center mb-5">Sub Admin Details</p>

                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col col-lg-6 mb-4 mb-lg-0">
                        <div className="card mb-5 p-3" style={{ borderRadius: ".5rem" }}>
                            <div className="row g-0">
                                <div className="col-md-12">

                                    <div className="d-flex justify-content-center align-items-center  flex-column">
                                        <img src={adminData.profile_image ? adminData.profile_image : Image}
                                            alt="Avatar" className="img-fluid mb-2" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                        <p className="fs-6 fw-600 text-dark">{adminData.name}</p>
                                    </div>

                                    <div className="card-body p-4">
                                        <h6 className="mb-2">Information</h6>
                                        <hr className="mt-0 mb-4" />
                                        <div className="row pt-1">
                                            <div className="col-6 mb-3">
                                                <p className="fw-600">Email</p>
                                                <p className="text-muted mt-2">{adminData.email}</p>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <p className="fw-600">Role</p>
                                                <p className="text-muted mt-2">{adminData.role === "A" ? "Sub Admin" : "Super Admin"}</p>
                                            </div>
                                        </div>

                                        <h6 className="mb-2">Permissions</h6>
                                        <hr className="mt-0 mb-4" />
                                        
                                        <div className="col-12">
                                            <div className="row">
                                                {adminPermissions.map((permission, index) => (
                                                    <div key={index} className="col-lg-4 col-md-6 mb-3"  >
                                                        <div className="card p-3" style={{height : "210px"}}>
                                                            <p className="fw-600">{permission.menu_name}</p>
                                                            <p className="text-muted">
                                                                Add: {permission.add ? "Yes" : "No"}<br />
                                                                Edit: {permission.edit ? "Yes" : "No"}<br />
                                                                Delete: {permission.delete ? "Yes" : "No"}<br />
                                                                View: {permission.view ? "Yes" : "No"}<br />
                                                                Block/Unblock: {permission.status ? "Yes" : "No"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}