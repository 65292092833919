import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { AccessAccount, Top_10_Organization, Top_10_Teachers } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import DataTable from "react-data-table-component";
import Select from 'react-select';
import { el } from "date-fns/locale";
import generatePDF from "react-to-pdf";
import customStyles from "../../../utils/dataTableStyles";
import { TOAST_ERROR } from "../../../utils/common.service";
import DatePicker from 'react-datepicker';
import { encryptString } from "../../../api/apiClient";

const options = {
    filename: "Organization_Reports.pdf",
    page: {
        margin: 20
    }
};
const getTargetElement = () => document.getElementById("download_report");
const downloadPdf = () => {
    // Exclude Apply and Clear buttons from the PDF
    const excludedElements = document.querySelectorAll('.skip_pdf');
    excludedElements.forEach(element => element.style.display = 'none');

    // Generate PDF with the remaining content
    generatePDF(getTargetElement, options);

    // Restore the display of the excluded elements
    excludedElements.forEach(element => element.style.display = '');
};

export default function SecondReport({isLoader}) {

    const [data, setData] = useState([])
    const navigate = useNavigate()

    const handleBack = () => {
        navigate(-1);
    };

    // Function to download reports in Excel format
    const downloadExcel = (data) => {

        const dataArray = Object.values(data);

        const filteredData = dataArray
            .filter(item => item !== null) // Filter out null items
            .map(item => ({
                Id_Code: item.code_generate,
                Name: item.full_name,
                Earnings: item.earnings,
                Category: item.category,
                Email: item.email,
                Country: item.country_name,
                Phone: item.phone
            }));

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'organization_report.xlsx');
    };

    const handleRedirect = (id, category) => {
        isLoader(true)
        AccessAccount({ user_id: id })
            .then((response) => {
                if (response.code === "1") {

                    localStorage.setItem("Ttoken", response.data[0]?.token);
                    localStorage.setItem("TisLogin", true)
                    localStorage.setItem("type", category)
                    localStorage.setItem("Tid", id)
                    localStorage.setItem("role","admin");
                    isLoader(false);

                    // navigate('https://studentscholars.com/my_class_details', {
                    //     state: { id }
                    // })

                    // const url = `https://studentscholars.com/my_class_details`;
                    // window.open(url, '_blank'); 

                    const encryptedId = encryptString(id);

                    const url = `https://studentscholars.com/organization-profile-details/${encryptedId}`;
                    window.open(url, '_blank');

                } else {
                    isLoader(false);
                    TOAST_ERROR("ERROR..!!");
                }
            })
            .catch((error) => {
                TOAST_ERROR("ERROR..!!");
            });
    };

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + 1,
            sortable: false,
            width: "130px"
        },
        {
            name: 'ID Code',
            selector: row => row.code_generate,
            sortable: true,
            width: "150px",
            cell: row => row.code_generate == null ? "0000" : row.code_generate
        },
        {
            name: 'Organization Name',
            selector: row => row.full_name,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Organization Email',
            selector: row => row.email,
            sortable: true,
            width: "300px"
        },
        {
            name: 'Earnings',
            selector: row => row.earnings,
            sortable: true,
            width: "180px"
        },
        {
            name: 'Profile Link in Admin',
            cell: (row) => (
                <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                    onClick={() => navigate("/org_details", { state: { userId: row.id } })}
                >Click Here</button>
            ),
            sortable: true,
            width: "250px"
        },
        {
            name: 'Profile Link in Website',
            cell: (row) => (
                <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                    onClick={() => handleRedirect(row.id, row.category)}
                >Click Here</button>
            ),
            sortable: true,
            width: "200px"
        }
    ]

     // Logic for filter

     const [selectedPeriod, setSelectedPeriod] = useState('yearly');
     const [selectedOption, setSelectedOption] = useState({ value: "yearly", label: "Yearly" });
     const [earningData, setEarningData] = useState([]);
 
     const currentDate = new Date();
     const defaultStartDate = currentDate.toISOString().split('T')[0];
     const defaultStartMonth = currentDate.toISOString().slice(0, 7);
 
     let initialStartDate = defaultStartDate;
     let initialEndDate = defaultStartDate;
 
     if (selectedPeriod === 'daily') {
         initialStartDate = currentDate.toISOString().split('T')[0];
         initialEndDate = currentDate.toISOString().split('T')[0];
     } else if (selectedPeriod === 'weekly') {
         const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
         initialStartDate = sevenDaysAgo.toISOString().split('T')[0];
         initialEndDate = currentDate.toISOString().split('T')[0];
     }
 
     // Set the state variables with the initial values
     const [startDate, setStartDate] = useState(initialStartDate);
     const [endDate, setEndDate] = useState(initialEndDate);
     const [startMonth, setStartMonth] = useState(defaultStartMonth);
     const [endMonth, setEndMonth] = useState(defaultStartMonth);
     const currentYear = new Date().getFullYear();
     const [startYear, setStartYear] = useState(currentYear);
     const [endYear, setEndYear] = useState(currentYear);
 
     useEffect(() => {
         fetchEarningsData();
     }, [selectedPeriod, startDate, endDate, startMonth, endMonth, startYear, endYear]);
 
     const fetchEarningsData = () => {
         let requestData = {};
 
         if (selectedPeriod === 'daily' || selectedPeriod === 'weekly') {
             requestData = {
                 type: selectedPeriod,
                 startDate: startDate,
                 endDate: endDate
             };
         } else if (selectedPeriod === 'monthly') {
             requestData = {
                 type: 'monthly',
                 startDate: startMonth,
                 endDate: endMonth
             };
         } else if (selectedPeriod === 'yearly') {
             requestData = {
                 type: 'yearly',
                 startYear: startYear,
                 endYear: endYear
             };
         }
         // Call the API with the updated request data
         isLoader(true)
        Top_10_Organization(requestData)
            .then((response) => {
            console.log('response :', response);
                if (response.code === "1") {
                    setData(response.data);
                    isLoader(false)
                } else {
                    setData([]);
                    isLoader(false)
                }
            })
            .catch((error) => {
                setData([]);
                isLoader(false)
            });
 
     };
 
     const handlePeriodChange = (selectedOption) => {
         setSelectedOption(selectedOption);
         setSelectedPeriod(selectedOption.value);
 
         // Update start and end dates immediately
         const currentDate = new Date();
         if (selectedOption.value === 'daily') {
             setStartDate(currentDate.toISOString().split('T')[0]);
             setEndDate(currentDate.toISOString().split('T')[0]);
         } else if (selectedOption.value === 'weekly') {
             const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
             setStartDate(sevenDaysAgo.toISOString().split('T')[0]);
             setEndDate(currentDate.toISOString().split('T')[0]);
         }
     };
 
     const handleStartMonthChange = (value) => {
         setStartMonth(value);
     };
 
     const handleEndMonthChange = (value) => {
         setEndMonth(value);
     };
 
     const getYearOptions = (minYear) => {
         const currentYear = new Date().getFullYear();
         const years = [];
         for (let year = minYear; year <= currentYear; year++) {
             years.push(year);
         }
         return years;
     };
 
     const renderYearContent = (year) => {
         const tooltipText = `Tooltip for year: ${year}`;
         return <span title={tooltipText}>{year}</span>;
     };
 
     const getCurrentMonthYear = () => {
         const now = new Date();
         const year = now.getFullYear();
         const month = (now.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
         return `${year}-${month}`;
     };
 
     const currentMonthYear = getCurrentMonthYear();
 
     const renderCustomHeader = ({
         date,
         changeYear,
         decreaseYear,
         increaseYear,
         prevYearButtonDisabled,
         nextYearButtonDisabled
     }) => (
         <div>
             <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                 {'<'}
             </button>
             <span>{date.getFullYear()}</span>
             <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
                 {'>'}
             </button>
         </div>
     );
 
    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </header>

        <section className="main_sec" id="download_report">
            <div className="mt-4 mx-5">
                <div className="skip_pdf">
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                </div>
                <p className="fs-4 fw-500 text-center mb-5">Top 10 organizations generating the most income</p>

                <div className="d-flex align-items-center">

                    <div className="col-md-2">
                        <label className="mb-2">Select Time Period :</label>
                        <Select
                            options={[
                                { value: "daily", label: "Daily" },
                                { value: "weekly", label: "Weekly" },
                                { value: "monthly", label: "Monthly" },
                                { value: "yearly", label: "Yearly" },
                            ]}
                            className="skip_pdf"
                            id="period-select"
                            value={selectedOption}
                            onChange={handlePeriodChange}
                            styles={{
                                width: "100%",
                                maxWidth: "200px",
                                minHeight: "40px"
                            }}
                            placeholder="Select Time Period"
                        />
                    </div>

                    {selectedPeriod === 'daily' && (
                        <div className="d-flex align-items-center ms-4">
                            <div className='ms-4 col-md-6'>
                                <label htmlFor="daily-start-date" className='mb-2'>Start Date:</label>
                                <input
                                    type="date"
                                    name="start_date"
                                    id="daily-start-date"
                                    className="form-control"
                                    required=""
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>

                            <div className="ms-4 col-md-6">
                                <label htmlFor="daily-end-date" className='mb-2' >End Date:</label>
                                <input
                                    type="date"
                                    name="end_date"
                                    id="daily-end-date"
                                    className="form-control"
                                    required=""
                                    value={endDate}
                                    min={startDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                    )}

                    {selectedPeriod === 'weekly' && (
                        <div className="d-flex align-items-center ms-4">
                            <div className='ms-4 col-md-6'>
                                <label htmlFor="weekly-start-date" className='mb-2'>Start Date:</label>
                                <input
                                    type="date"
                                    name="start_date"
                                    id="weekly-start-date"
                                    className="form-control"
                                    required=""
                                    value={startDate}
                                    onChange={(e) => {
                                        const selectedStartDate = e.target.value;
                                        const sevenDaysLater = new Date(new Date(selectedStartDate).getTime() + 7 * 24 * 60 * 60 * 1000);
                                        const formattedEndDate = sevenDaysLater.toISOString().split('T')[0];
                                        setStartDate(selectedStartDate);
                                        setEndDate(formattedEndDate);
                                    }}
                                />
                            </div>

                            <div className='ms-4 col-md-6'>
                                <label htmlFor="weekly-end-date" className="mb-2">End Date:</label>
                                <input
                                    type="date"
                                    name="end_date"
                                    id="weekly-end-date"
                                    className="form-control"
                                    required=""
                                    value={endDate}
                                    min={startDate}
                                    onChange={(e) => {
                                        const selectedEndDate = e.target.value;
                                        const sevenDaysBefore = new Date(new Date(selectedEndDate).getTime() - 7 * 24 * 60 * 60 * 1000);
                                        const formattedStartDate = sevenDaysBefore.toISOString().split('T')[0];
                                        if (new Date(formattedStartDate) > new Date(startDate)) {
                                            setStartDate(formattedStartDate);
                                        }
                                        setEndDate(selectedEndDate);
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {selectedPeriod === 'monthly' && (
                        <div className="d-flex justify-content-end">
                            <div className="col-md-5 ms-4">
                                <label htmlFor="startMonthPicker" className="mb-2">Start Month-Year:</label>
                                <input
                                    className="form-control"
                                    type="month"
                                    id="startMonthPicker"
                                    value={startMonth}
                                    onChange={(e) => handleStartMonthChange(e.target.value)}
                                    max={currentMonthYear}
                                />
                            </div>
                            <div className="col-md-5 ms-4">
                                <label htmlFor="endMonthPicker" className="mb-2">End Month-Year:</label>
                                <input
                                    className="form-control"
                                    type="month"
                                    id="endMonthPicker"
                                    min={startMonth}
                                    value={endMonth}
                                    onChange={(e) => handleEndMonthChange(e.target.value)}
                                    max={currentMonthYear}
                                />
                            </div>
                        </div>
                    )}

                    {selectedPeriod === 'yearly' && (
                        <div className="d-flex align-items-center">
                            <div className='ms-4 col-md-3'>
                                <label htmlFor="yearPicker" className='mb-2'>Start Year:</label>
                                <DatePicker
                                    selected={new Date(startYear, 0, 1)}
                                    onChange={(date) => {
                                        const newStartYear = date.getFullYear();
                                        setStartYear(newStartYear);
                                        if (newStartYear > endYear) {
                                            setEndYear(newStartYear);
                                        }
                                    }}
                                    showYearPicker
                                    dateFormat="yyyy"
                                    renderCustomHeader={renderCustomHeader}
                                    className="form-control"
                                />
                            </div>
                            <div className='ms-4 col-md-3'>
                                <label htmlFor="yearPickerEnd" className="mb-2">End Year:</label>
                                <DatePicker
                                    selected={new Date(endYear, 0, 1)}
                                    onChange={(date) => {
                                        const newEndYear = date.getFullYear();
                                        setEndYear(newEndYear);
                                    }}
                                    showYearPicker
                                    dateFormat="yyyy"
                                    renderCustomHeader={renderCustomHeader}
                                    includeDates={getYearOptions(startYear).map(year => new Date(year, 0, 1))}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    )}

                    <div className="ms-auto d-flex align-items-center">
                        <button onClick={downloadPdf} className="btn-theme bg-yellow m-2 skip_pdf">Download PDF</button>
                        <button onClick={() => downloadExcel(data)} className="btn-theme bg-yellow m-2 skip_pdf">Download Excel</button>
                    </div>
                </div>
                <div className="mt-4" >
                    {data && data.length > 0 ? (
                        <>
                            <DataTable columns={columns} data={data} pagination customStyles={customStyles}/>
                        </>
                    ) : (
                        <>
                            <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                        </>
                    )}
                </div>

            </div>
        </section>
    </>
}

