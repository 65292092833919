import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { learnerDetails } from "../../../api/apiHandler";
import Image from "../../../user.png";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";

export default function Learner_Details({isLoader}) {
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const learnerId = location.state && location.state.LearnerId

    const LearnerDetails = () => {
        isLoader(true)
        learnerDetails({ id: learnerId }).then((response) => {
            if (response.code == 1) {
                setData(response.data[0])
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        });
    }

    useEffect(() => {
        LearnerDetails();
    }, [])

    const handleBack = () => {
        navigate(-1);
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </header>

        <section className="main_sec">
            <div className="mt-4 mx-5">
                <div className="">
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                </div>
                <p className="ms-3 fs-4 fw-500 text-center mb-5">Learner Details</p>

                {isLoader && data.length !== 0 ? (
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col col-lg-6 mb-4 mb-lg-0">
                            <div className="card mb-3 p-3" style={{ borderRadius: ".5rem" }}>
                                <div className="row g-0">
                                    <div className="col-md-4 gradient-custom text-center text-white mt-4">
                                        <img src={data.learner_profile_img ? data.learner_profile_img : Image}
                                            alt={data.learner_profile_img ? data.learner_profile_img : Image} className="img-fluid my-3" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                        <p className="fs-5 fw-600 text-dark">{data.learner_name}</p>
                                        <p className="text-dark mt-2">Learner</p>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body p-4">
                                            <h6 className="mb-2">Information</h6>
                                            <hr className="mt-0 mb-4" />
                                            <div className="row pt-1">
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Email</p>
                                                    <p className="text-muted mt-2">{data.learner_email}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Phone Number</p>
                                                    <p className="text-muted mt-2">{data.country_code + " " + data.phone}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Age</p>
                                                    <p className="text-muted mt-2">{data.learner_age}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Sub Category</p>
                                                    <p className="text-muted mt-2">{data.sub_cat}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Country</p>
                                                    <p className="text-muted mt-2">{data.country}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <p className="fw-600">Time zone</p>
                                                    <p className="text-muted mt-2">{data.time_zone}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    null
                )}


            </div>
        </section>
    </>
}