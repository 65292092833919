import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import { update_cms_pages } from '../../../api/apiHandler';

const Editor = ({ content, onChange }) => {

    return (
        <ReactQuill
            value={content}
            onChange={onChange}
            modules={Editor.modules}
            formats={Editor.formats}
            theme="snow"
            placeholder="Write something..."
        />
    );
};
// Define the modules and formats for the editor
Editor.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
    ],
};

Editor.formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
];

function Ckeditor({ data }) {
    const [pageData, setPageData] = useState('');

    useEffect(() => {
        setPageData(data.content || '');
    }, [data.content]);

    const submitBtn = () => {
        const obj = {
            keyword: data.keyword,
            content: pageData,
        };
        update_cms_pages(obj).then(response => {
            if (response.code == 1) {
                TOAST_SUCCESS(response.message);
            } else {
                TOAST_ERROR(response.message);
            }
        });
    };

    const handleEditorChange = (value) => {
        setPageData(value);
    };

    return (
        <div>
            <Editor content={pageData} onChange={handleEditorChange} />
            <div className="d-flex justify-content-end mt-3">
                <button
                    type="submit"
                    className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block"
                    onClick={submitBtn}
                >
                    Submit
                </button>
            </div>
        </div>
    );
}

export default Ckeditor;
