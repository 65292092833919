import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { admin_permission_detail, delete_faq, get_faq } from "../../../api/apiHandler";
import FontAwesome from "react-fontawesome";
import Swal from "sweetalert2";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { Link } from "react-router-dom";

export default function Faq({isLoader}) {

    const [data, setData] = useState([])
    const [permissions, setPermission] = useState([]);
    const [role, setRole] = useState(localStorage.getItem("adminRole"));
    const [adminId, setAdminId] = useState(localStorage.getItem("aID"));
    const [search, setSearch] = useState('')

    const navigate = useNavigate()

    const handleGetData = () => {
        isLoader(true)
        get_faq({ search: search }).then((resposnse) => {
        console.log('resposnse :', resposnse);
            if (resposnse.code == 1) {
                setData(resposnse.data)
                isLoader(false)
            } else {
                setData([]);
                isLoader(false)
            }
        });
    }

    const handleEditFaq = (FaqId, Title, Description , panels) => {
        navigate('/edit-faq', { state: { FaqId: FaqId, Title: Title, Description: Description , Panels : panels } })
    }

    const handleViewVideo = (FaqId) => {
        navigate('/video', { state: { FaqId: FaqId } })
    }

    const handleDeleteFaq = async (faqId) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    const response = await delete_faq({ id: faqId });
                    if (response.code === '1') {
                        TOAST_SUCCESS(response.message);
                        handleGetData();
                        isLoader(false)
                    } else {
                        TOAST_ERROR(response.message);
                        isLoader(false)
                    }
                } catch (error) {
                    TOAST_ERROR("Error");
                }
            }
        });
    };

    // const columns = [
    //     {
    //         name: 'Title',
    //         selector: row => row.title,
    //         sortable: true,
    //         width: "300px"
    //     },
    //     {
    //         name: 'Description',
    //         selector: row => row.description,
    //         sortable: true,
    //         width: "650px"
    //     },
    //     {
    //         name: 'Videos',
    //         cell: (row) => (
    //             <div className="d-flex justify-content-center">
    //                 <div className="col me-2">
    //                     <div onClick={() => handleViewVideo(row.id)}>
    //                         View Videos
    //                     </div>
    //                 </div>
    //             </div>
    //         ),
    //     },
    //     {
    //         name: 'Actions',
    //         cell: (row) => (
    //             <div className="d-flex justify-content-center">
    //                 <div className="col me-2">
    //                     <div>
    //                         <FontAwesome
    //                             name="edit"
    //                             className="fs-5 fa-solid fa-edit mx-2 my-2 text-info"
    //                             onClick={() => handleEditFaq(row.id, row.title, row.description)}
    //                         ></FontAwesome>
    //                     </div>
    //                 </div>
    //                 <div className="col me-2">
    //                     <FontAwesome
    //                         name="delete"
    //                         className="fs-5 fa-solid fa-trash  mx-2 my-2 bg-light text-danger"
    //                         onClick={() => handleDeleteFaq(row.id)}
    //                     ></FontAwesome>
    //                 </div>

    //             </div>
    //         )
    //     }
    // ]

    useEffect(() => {
        handleGetData()
        if (role !== "S") {
            subAdminPermission();
        }
    }, [search])

    const addData = () => {
        navigate('/add-faq')
    }

    const subAdminPermission = () => {
        admin_permission_detail({ id: adminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([])
                }
            })
            .catch((error) => {
                setPermission([])
            });
    }

    const faqPermission = permissions.find(permission => permission.menu_name === "FAQ's - Training Videosogs");

    // Check if add,edit,delete is allowed for "Blogs"
    const isAddAllowed = faqPermission && faqPermission.add === 1;
    const isEditAllowed = faqPermission && faqPermission.edit === 1;
    const isDeleteAllowed = faqPermission && faqPermission.delete === 1;
    const isViewAllowed = faqPermission && faqPermission.view === 1;

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>

                    </div>
                </header>
                <div className="fs-3 fw-500 mt-4 ms-4">
                    FAQ's Data
                </div>
                <div className="container">
                    {(role === "S" || isAddAllowed) && (
                        <div className="d-flex justify-content-end">
                            <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={addData}>
                                + Add FAQ
                            </button>
                        </div>
                    )}

                    <div className="row mt-3">
                        {/*start-Search*/}
                        <div className="search_box mb-4 position-relative">
                          <input type="text" name placeholder="Search" onChange={(e) => setSearch(e.target.value)}/>
                          <span className="ms-4 position-absolute">
                          <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" fill="#666666" fillOpacity="0.4" />
                          </svg>
                          </span>
                          {/* <img src="./assets/images/rec_icon.png" alt="rec_icon" className="position-absolute rec_search_icon" /> */}
                        </div>
                        {/*end-Search*/}
                        <ul className="traning_list ps-lg-3">
                            {data && data.length > 0 ? (
                                data.map(item => (
                                    <li key={item.id} className="mb-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="col-lg-10 ">
                                                <Link to="/video" state={item.id} className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <h3 className="fs-5 poppins fw_500">{item.title}</h3>
                                                    </div>
                                                    <div>
                                                        <svg width={20} height={15} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M23.6164 9.06753L14.9491 0.400093C14.7017 0.15268 14.372 0.0168762 14.0204 0.0168762C13.6684 0.0168762 13.3388 0.152875 13.0914 0.400093L12.3045 1.18721C12.0573 1.43423 11.9211 1.76418 11.9211 2.11598C11.9211 2.46759 12.0573 2.80866 12.3045 3.05568L17.3608 8.12315H1.29657C0.572288 8.12315 0 8.69017 0 9.41465V10.5274C0 11.2519 0.572288 11.8761 1.29657 11.8761H17.4182L12.3047 16.9719C12.0575 17.2193 11.9213 17.5402 11.9213 17.892C11.9213 18.2435 12.0575 18.5691 12.3047 18.8163L13.0916 19.6009C13.339 19.8483 13.6686 19.9832 14.0206 19.9832C14.3722 19.9832 14.7019 19.8466 14.9493 19.5992L23.6166 10.9319C23.8646 10.6837 24.001 10.3524 24 10.0002C24.0008 9.64685 23.8646 9.31534 23.6164 9.06753Z" fill="black" />
                                                        </svg>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="d-flex justify-content-end me-5">
                                                {(role === "S" || isEditAllowed) && (
                                                    <div className="me-4" onClick={() => handleEditFaq(item.id, item.title, item.description , item.panel_name)} style={{ cursor: 'pointer' }}>
                                                        <FontAwesome className="fs-5 fa-solid fa-edit mx-2 my-2 text-info" name="edit"></FontAwesome>
                                                    </div>
                                                )}
                                                {(role === "S" || isDeleteAllowed) && (
                                                    <div className="me-3" onClick={() => handleDeleteFaq(item.id)} style={{ cursor: 'pointer' }}>
                                                        <FontAwesome name="delete" className="fs-5 fa-solid fa-trash  mx-2 my-2 bg-light text-danger"></FontAwesome>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <li>No Data Found</li>
                            )}
                        </ul>
                    </div>

                </div>
            </div>
        </main>
    </>
}