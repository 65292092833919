import React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import Hdashboard from '../../../components/Hdashboard';
import RelativeTime from '../../../utils/RelativeTime';

export default function VideoDetails() {

  const navigate = useNavigate();
  const location = useLocation();
  var videoData = location?.state;

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <JqueryLoad />
      <Hdashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <Link className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </Link>
                <Link className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </Link>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              {/*start*/}
              <span className="mb-4 arrow_left mb-3 d-block" onClick={handleBack} >
                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                </svg>
                <span className="ms-2">Back</span>
              </span>
              {/*end*/}
              {/*start*/}
              <div>
                <div>
                  {/*start*/}
                  <div className="mb-5">
                    <h2 className="mb-2 poppins">{videoData.title}</h2>
                  </div>
                  {/*end*/}
                  {/*start*/}
                  <div className="row blogs_detail">
                    <div className="col-lg-12 mb-4">
                      <div className="certi_box bg-transparent shadow-none">
                        <div className="certi_images mb-3 position-relative text-center bg-transparent p-0 border-bottom-0">
                          <div className="blog_img border-radius-10">
                            {videoData.media_type === 'video' && videoData.media ? (
                              <video id="video" className="video-js vjs-default-skin" loop width="100%" controls poster="">
                                <source src={videoData.media} type="video/mp4" />
                              </video>
                            ) : (
                              videoData.media && <img src={videoData.media} alt="Blog Image" />
                            )}
                          </div>
                        </div>
                        <div className="certi_ctn border-top border-dark px-0">
                          <div className="dwnload_btm d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="dwnl_img">
                                <img src={videoData.profile_image} alt="center_div_img2" style={{borderRadius : "50px"}} />
                              </div>
                              <div className="ms-3">
                                <span className="font-14 fw-bold">{videoData.admin_name}</span>
                              </div>
                            </div>
                            <div>
                              <span className='fw-600'>{videoData.formatted_date}</span> • <span className='fw-600'><RelativeTime dateString={videoData.created_at} /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end*/}
                  {/*start*/}
                  <div>
                    {/*start-content*/}
                    <div className='w-100 mb-5'>
                      <p>{videoData.description}</p>
                    </div>
                    {/*end-content*/}
                    {/* <h3 className="mb-4 poppins text-dif-black">Natural light is a highly sought-after feature in any space</h3>
                    <div className="row mb-5 middle_contn">
                      <div className="col-xl-6 col-lg-6 col-md-6 mb-md-0 mb-4">
                        <div className="certi_box bg-transparent shadow-none">
                          <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                            <div className="blog_img">
                              <img src="./assets/images/blog2.png" alt="blog1" className="w-100 img-fluid border-radius-10" />
                              <Link className="play_btn_op1" tabIndex={0}>
                                <img src="./assets/images/play.png" alt="play" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="certi_box bg-transparent shadow-none">
                          <div className="certi_images text-center bg-transparent p-0 border-bottom-0">
                            <div className="blog_img">
                              <img src="./assets/images/blog2.png" alt="blog1" className="w-100 img-fluid border-radius-10" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/*start-content*/}
                    {/* <h3 className="mb-4 poppins text-dif-black">Natural light is a highly sought-after feature in any space</h3>
                    <p className="mb-4 fs-6">Optimize for search land it in region today shall be a cloudy day, thanks to blue sky thinking, we can now deploy our new ui to the cloud . Unlock meaningful moments of relaxation forcing function , or weaponize the data. At the end of the day you better eat a reality sandwich before you walk back in that boardroom, and onward and upward, productize the deliverables and focus on the bottom line, but what do you feel you would bring to the table if you were hired for this position. This vendor is incompetent mumbo jumbo, optimize for search, yet run it up the flagpole</p>
                    <p className="mb-4 fs-6">You better eat a reality sandwich before you walk back in that boardroom can we jump on a zoom through the lens of product launch, so sacred cow pulling teeth put your feelers out. We've bootstrapped the model your work on this project has been really impactful, overcome key issues to meet key milestones, optics, for we just need to put these last issues to bed, or disband the squad but rehydrate as needed. Cross pollination across our domains we need to build it so that it scales.</p> */}
                    {/*end-content*/}
                    {/*start-content*/}
                    {/* <h3 className="mb-4 poppins text-dif-black">Natural light is a highly sought-after feature in any space</h3>
                    <p className="mb-4 fs-6">Optimize for search land it in region today shall be a cloudy day, thanks to blue sky thinking, we can now deploy our new ui to the cloud . Unlock meaningful moments of relaxation forcing function , or weaponize the data. At the end of the day you better eat a reality sandwich before you walk back in that boardroom, and onward and upward, productize the deliverables and focus on the bottom line, but what do you feel you would bring to the table if you were hired for this position. This vendor is incompetent mumbo jumbo, optimize for search, yet run it up the flagpole</p>
                    <p className="mb-4 fs-6">You better eat a reality sandwich before you walk back in that boardroom can we jump on a zoom through the lens of product launch, so sacred cow pulling teeth put your feelers out. We've bootstrapped the model your work on this project has been really impactful, overcome key issues to meet key milestones, optics, for we just need to put these last issues to bed, or disband the squad but rehydrate as needed. Cross pollination across our domains we need to build it so that it scales.</p> */}
                    {/*end-content*/}
                  </div>
                  {/*end*/}
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
