import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import "./Ratingstyle.css";
import Hdashboard from '../../../components/Hdashboard';
import { teacher_detail_who_accepted_new_leads } from '../../../api/apiHandler';

export default function RespondedTeacherList() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const location = useLocation();

    const navigate = useNavigate();
    var request_class_id = location?.state && location.state.request_class_id

    var [data, setData] = useState([]);

    const NewLeadsDetail = () => {
        teacher_detail_who_accepted_new_leads({ request_class_id: request_class_id }).then((resposnse) => {
            console.log('resposnse :', resposnse);
            if (resposnse?.code == 1) {
                setData(resposnse?.data)
            } else {
                setData([])
            }
        });
    }

    useEffect(() => {
        NewLeadsDetail();
    }, []);

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block">
                            <span>
                                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                </svg>
                                <span className="ms-2">Back</span>
                            </span>
                        </a>
                        <div className="row">
                            <div className="col-xl-9">
                                <p className="ms-3 fs-3 fw-500 mb-3">Responded Teacher List</p>
                                <div className="lead_request pt-2">
                                    {data.length > 0 ? (
                                        data.map((teacher, index) => (
                                            <div className="card mb-4" key={index}>
                                                <div className="lead_req_img d-flex position-relative align-items-center">
                                                    <img className="rounded-circle" src={teacher.profile_image} alt="user" />
                                                    <div className="ms-3">
                                                        <span className="fw-600">{teacher.full_name}</span>
                                                    </div>
                                                    <div className="ms-3">
                                                        <span className="fw-600">{teacher.name}</span>
                                                        {teacher?.is_green == 1 ?
                                                            <span className="mt-2 green_dots position-absolute" /> : <></>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-md-flex align-items-end justify-content-between mt-2">
                                                    <ul>
                                                        <li className="mb-2">
                                                            <span className="fw-500 font-14" style={{ color: '#570861' }}>Teacher’s Country:
                                                                <span className="fw-500 text-dif-black font-14"> &nbsp;{teacher.country_name ? teacher.country_name : "N/A"}</span>
                                                            </span>
                                                        </li>
                                                        <li className="mb-2">
                                                            <span className="fw-500 font-14" style={{ color: '#570861' }}> Category:
                                                                <span className="fw-500 text-dif-black font-14">&nbsp;{teacher.category}</span>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <h4 className="text-center mt-5" style={{ color: "#570861" }}>Data Not Found</h4>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
