import { CButton, CFormInput, CInputGroup } from '@coreui/react';
import React from 'react'
import FontAwesome from 'react-fontawesome';

export default function Leeds_Filter({ CategoriesData, subCategoriesData, setCategoriesData, setSubCategoriesData, startDate, setStartDate, endDate, setEndDate, classSize, setclassSize, search, setSearch }) {

  const handleSelectedIngredients = (Categories_data) => {

    if (subCategoriesData.includes(Categories_data)) {
      let filteredArray = subCategoriesData.filter(item => item !== Categories_data);
      setSubCategoriesData(filteredArray)
    } else {
      setSubCategoriesData(prevSelectSubject => [...new Set([...prevSelectSubject, Categories_data])]);
    }
  }

  const handleSelectedsize = (data) => {
    setclassSize(data)
  }

  const Start_Date = (data) => {
    setStartDate(data)
  }

  const End_Date = (data) => {
    setEndDate(data)
  }

  const Search = (data) => {
    setSearch(data)
  }

  return (
    <>
      <div className="search-container col-md-4">
        <CInputGroup>
          <CFormInput type="text" placeholder="Search"
            onChange={(e) => Search(e.target.value)}
          />
          <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
            <FontAwesome name="search" className="fa-solid fa-search" />
          </CButton>
        </CInputGroup>
      </div>

      <div className="fliter_list">
        <ul className="list-unstyled slider_filter_list justify-content-end d-flex flex-wrap align-items-center">
          {/* <li className="ms-2 mb-2"> */}
          <li>
            <div className="dropdown">
              <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Select Date
              </button>
              <div className="dropdown-menu class_size_start filter_box">
                <form className action id>
                  <div className="form-group mb-4">
                    <label className="mb-2">From Date</label>
                    <input type="date" className="form-control" id onChange={(e) => { Start_Date(e.target.value) }} placeholder="Start" name />
                  </div>
                  <div className="form-group mb-4">
                    <label className="mb-2">To Date</label>
                    <input type="date" className="form-control" min={startDate} id onChange={(e) => { End_Date(e.target.value) }} placeholder="Start" name />
                  </div>
                </form>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}
