import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { new_leads_class_statistics, new_leads_class_statistics_combined, request_by_preferred_and_non_preferred, top_10_teachers_not_picked, top_10_teachers_picked } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import customStyles from "../../../utils/dataTableStyles";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Accordion } from 'react-bootstrap';

export default function Statistics({ isLoader }) {

    const [data, setData] = useState([])
    const [topTeacherData, setTopTeacherData] = useState([])
    const [bottomTeacherData, setBottomTeacherData] = useState([])
    const navigate = useNavigate()
    const [search, setSearch] = useState('')

    const topTeachers = () => {
        isLoader(true)
        top_10_teachers_picked().then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setTopTeacherData(dataWithRowCount);
                isLoader(false)
            } else {
                setTopTeacherData([])
                isLoader(false)
            }
        })
    }

    const bottomTeachers = () => {
        isLoader(true)
        top_10_teachers_not_picked().then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setBottomTeacherData(dataWithRowCount);
                isLoader(false)
            } else {
                setBottomTeacherData([])
                isLoader(false)
            }
        })
    }

    useEffect(() => {
        topTeachers();
        bottomTeachers();
    }, []);

    const topColumns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "400px"
        },
        {
            name: 'Teacher Name',
            selector: row => row.full_name,
            sortable: true,
            width: "500px"
        },
        {
            name: 'Number of times learner subscribed their class',
            selector: row => row.times_joined,
            sortable: true,
            width: "500px"
        }
    ]

    const bottomColumns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "400px"
        },
        {
            name: 'Teacher Name',
            selector: row => row.full_name,
            sortable: true,
            width: "500px"
        },
        {
            name: 'Number of times learner subscribed their class',
            selector: row => row.times_joined,
            sortable: true,
            width: "500px"
        }
    ]

    // Logic for filter

    const [selectedPeriod, setSelectedPeriod] = useState('yearly');
    const [selectedOption, setSelectedOption] = useState({ value: "yearly", label: "Yearly" });
    const [earningData, setEarningData] = useState([]);

    const currentDate = new Date();
    const defaultStartDate = currentDate.toISOString().split('T')[0];
    const defaultStartMonth = currentDate.toISOString().slice(0, 7);

    let initialStartDate = defaultStartDate;
    let initialEndDate = defaultStartDate;

    if (selectedPeriod === 'daily') {
        initialStartDate = currentDate.toISOString().split('T')[0];
        initialEndDate = currentDate.toISOString().split('T')[0];
    } else if (selectedPeriod === 'weekly') {
        const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        initialStartDate = sevenDaysAgo.toISOString().split('T')[0];
        initialEndDate = currentDate.toISOString().split('T')[0];
    }

    // Set the state variables with the initial values
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [startMonth, setStartMonth] = useState(defaultStartMonth);
    const [endMonth, setEndMonth] = useState(defaultStartMonth);
    const currentYear = new Date().getFullYear();
    const [startYear, setStartYear] = useState(currentYear);
    const [endYear, setEndYear] = useState(currentYear);

    useEffect(() => {
        fetchStatisticsData();
    }, [selectedPeriod, startDate, endDate, startMonth, endMonth, startYear, endYear]);

    const fetchStatisticsData = () => {
        let requestData = {};

        if (selectedPeriod === 'daily' || selectedPeriod === 'weekly') {
            requestData = {
                type: selectedPeriod,
                startDate: startDate,
                endDate: endDate
            };
        } else if (selectedPeriod === 'monthly') {
            requestData = {
                type: 'monthly',
                startDate: startMonth,
                endDate: endMonth
            };
        } else if (selectedPeriod === 'yearly') {
            requestData = {
                type: 'yearly',
                startYear: startYear,
                endYear: endYear
            };
        }
        const requestDataWithSearch = { ...requestData, search: search };
        isLoader(true)
        new_leads_class_statistics_combined(requestDataWithSearch).then((response) => {
        console.log('new_leads_class_statistics_combined', response);
            if (response.code == 1) {
                setData(response.data);
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    };

    const handlePeriodChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSelectedPeriod(selectedOption.value);

        // Update start and end dates immediately
        const currentDate = new Date();
        if (selectedOption.value === 'daily') {
            setStartDate(currentDate.toISOString().split('T')[0]);
            setEndDate(currentDate.toISOString().split('T')[0]);
        } else if (selectedOption.value === 'weekly') {
            const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
            setStartDate(sevenDaysAgo.toISOString().split('T')[0]);
            setEndDate(currentDate.toISOString().split('T')[0]);
        }
    };

    const handleStartMonthChange = (value) => {
        setStartMonth(value);
    };

    const handleEndMonthChange = (value) => {
        setEndMonth(value);
    };

    const getYearOptions = (minYear) => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = minYear; year <= currentYear; year++) {
            years.push(year);
        }
        return years;
    };

    const renderYearContent = (year) => {
        const tooltipText = `Tooltip for year: ${year}`;
        return <span title={tooltipText}>{year}</span>;
    };

    const getCurrentMonthYear = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
        return `${year}-${month}`;
    };

    const currentMonthYear = getCurrentMonthYear();

    const renderCustomHeader = ({
        date,
        changeYear,
        decreaseYear,
        increaseYear,
        prevYearButtonDisabled,
        nextYearButtonDisabled
    }) => (
        <div>
            <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                {'<'}
            </button>
            <span>{date.getFullYear()}</span>
            <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
                {'>'}
            </button>
        </div>
    );

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <header className="dashboard-toolbar d-lg-flex justify-content-between">
                <div className="align-items-center justify-content-between d-flex">
                    <div className="d-flex align-items-center">
                        <a className="menu-toggle align-items-center d-lg-none d-flex">
                            <i className="fa fa-bars" />
                        </a>
                        <a href="index.php" className="d-lg-none d-block brand-logo">
                            <img src="./assets/images/logo.png" alt="logo" />
                        </a>
                    </div>
                </div>
            </header>
            <>

                <div className=' d-flex justify-content-start mt-3 mb-5'>
                    <div className='col-md-2'>
                        <label className="mb-2 fw-600">Select Time Period :</label>
                        <Select
                            options={[
                                // { value: "daily", label: "Daily" },
                                { value: "weekly", label: "Weekly" },
                                { value: "monthly", label: "Monthly" },
                                { value: "yearly", label: "Yearly" },
                            ]}
                            className="skip_pdf"
                            id="period-select"
                            value={selectedOption}
                            onChange={handlePeriodChange}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    border: "1px solid rgba(102, 102, 102, 0.35)",
                                    borderRadius: "12px",
                                    padding: "5px 10px",
                                    height: "46px",
                                    width: "230px"
                                }),
                            }}
                            placeholder="Select Time Period"
                        />
                    </div>

                    <div className='col-md-5'>
                        {selectedPeriod === 'daily' && (
                            <div className="d-flex justify-content-end">
                                <div className='col-md-5 ms-4'>
                                    <label htmlFor="daily-start-date" className='mb-2 fw-600'>Start Date:</label>
                                    <input
                                        type="date"
                                        name="start_date"
                                        id="daily-start-date"
                                        className="form-control"
                                        required=""
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        style={{
                                            borderRadius: '12px',
                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                            padding: '10px',
                                            height: '46px',
                                        }}
                                    />
                                </div>

                                <div className="col-md-5 ms-4">
                                    <label htmlFor="daily-end-date" className='mb-2 fw-600'>End Date:</label>
                                    <input
                                        type="date"
                                        name="end_date"
                                        id="daily-end-date"
                                        className="form-control"
                                        min={startDate}
                                        required=""
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        style={{
                                            borderRadius: '12px',
                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                            padding: '10px',
                                            height: '46px',
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {selectedPeriod === 'weekly' && (
                            <div className="d-flex">
                                <div className='col-md-5 ms-4'>
                                    <label htmlFor="weekly-start-date" className='mb-2 fw-600'>Start Date:</label>
                                    <input
                                        type="date"
                                        name="start_date"
                                        id="weekly-start-date"
                                        className="form-control"
                                        required=""
                                        value={startDate}
                                        onChange={(e) => {
                                            const selectedStartDate = e.target.value;
                                            const sevenDaysLater = new Date(new Date(selectedStartDate).getTime() + 7 * 24 * 60 * 60 * 1000);
                                            const formattedEndDate = sevenDaysLater.toISOString().split('T')[0];
                                            setStartDate(selectedStartDate);
                                            setEndDate(formattedEndDate);
                                        }}
                                        style={{
                                            borderRadius: '12px',
                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                            padding: '10px',
                                            height: '46px',
                                        }}
                                    />
                                </div>

                                <div className='col-md-5 ms-4'>
                                    <label htmlFor="weekly-end-date" className="mb-2 fw-600">End Date:</label>
                                    <input
                                        type="date"
                                        name="end_date"
                                        id="weekly-end-date"
                                        className="form-control"
                                        required=""
                                        value={endDate}
                                        onChange={(e) => {
                                            const selectedEndDate = e.target.value;
                                            const sevenDaysBefore = new Date(new Date(selectedEndDate).getTime() - 7 * 24 * 60 * 60 * 1000);
                                            const formattedStartDate = sevenDaysBefore.toISOString().split('T')[0];
                                            if (new Date(formattedStartDate) > new Date(startDate)) {
                                                setStartDate(formattedStartDate);
                                            }
                                            setEndDate(selectedEndDate);
                                        }}
                                        style={{
                                            borderRadius: '12px',
                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                            padding: '10px',
                                            height: '46px',
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {selectedPeriod === 'monthly' && (
                            <div className="d-flex">
                                <div className="col-md-5 ms-4">
                                    <label htmlFor="startMonthPicker" className="mb-2 fw-600">Start Month-Year:</label>
                                    <input
                                        className="form-control"
                                        type="month"
                                        id="startMonthPicker"
                                        value={startMonth}
                                        onChange={(e) => handleStartMonthChange(e.target.value)}
                                        max={currentMonthYear}
                                        style={{
                                            borderRadius: '12px',
                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                            padding: '10px',
                                            height: '46px',
                                        }}
                                    />
                                </div>
                                <div className="col-md-5 ms-4">
                                    <label htmlFor="endMonthPicker" className="mb-2 fw-600">End Month-Year:</label>
                                    <input
                                        className="form-control"
                                        type="month"
                                        id="endMonthPicker"
                                        min={startMonth}
                                        value={endMonth}
                                        onChange={(e) => handleEndMonthChange(e.target.value)}
                                        max={currentMonthYear}
                                        style={{
                                            borderRadius: '12px',
                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                            padding: '10px',
                                            height: '46px',
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {selectedPeriod === 'yearly' && (
                            <div className="d-flex">
                                <div className='col-md-5 ms-4'>
                                    <label htmlFor="yearPicker" className='mb-2 fw-600'>Start Year:</label>
                                    <DatePicker
                                        selected={new Date(startYear, 0, 1)}
                                        onChange={(date) => {
                                            const newStartYear = date.getFullYear();
                                            setStartYear(newStartYear);
                                            if (newStartYear > endYear) {
                                                setEndYear(newStartYear);
                                            }
                                        }}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        renderCustomHeader={renderCustomHeader}
                                        className="form-control"
                                        wrapperClassName="date-picker-wrapper"
                                    />
                                </div>
                                <div className='col-md-5 ms-4'>
                                    <label htmlFor="yearPickerEnd" className="mb-2 fw-600">End Year:</label>
                                    <DatePicker
                                        selected={new Date(endYear, 0, 1)}
                                        onChange={(date) => {
                                            const newEndYear = date.getFullYear();
                                            setEndYear(newEndYear);
                                        }}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        renderCustomHeader={renderCustomHeader}
                                        includeDates={getYearOptions(startYear).map(year => new Date(year, 0, 1))}
                                        className="form-control"
                                        wrapperClassName="date-picker-wrapper"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-lg-3 col-sm-4" onClick={() => navigate("/learners_classes_list")}
                    >
                        <div className="widget-panel1 widget-style-2 d-flex align-items-center justify-content-between">
                            <div >
                                <h2 className="m-0  counter font-600 total_cards start_number">
                                    {data?.result?.[0]?.times_requested}
                                </h2>
                                <div className="counter-text m-t-5 fs-5">Number of classes learner requested</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4" onClick={() => navigate("/learners_classes_list")}
                    >
                        <div className="widget-panel1 widget-style-2 d-flex align-items-center justify-content-between">
                            <div>
                                <h2 className="m-0  counter font-600 total_cards start_number">
                                    {data?.result?.[0]?.times_joined}
                                </h2>
                                <div className="counter-text m-t-5 fs-5">Number of classes learners actually subscribed
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4"
                    >
                        <div className="widget-panel1 widget-style-2 d-flex align-items-center justify-content-between">
                            <div>
                                <h2 className="m-0  counter font-600 total_cards start_number">
                                    {data?.non_preferred_teacher?.non_preferred_teacher}
                                </h2>   
                                <div className="counter-text m-t-5 fs-5">Number of non preferred teachers replied to the requested
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4"
                    >
                        <div className="widget-panel1 widget-style-2 d-flex align-items-center justify-content-between">
                            <div>
                                <h2 className="m-0  counter font-600 total_cards start_number">
                                    {data?.preferred_teacher?.preferred_teacher}
                                </h2>
                                <div className="counter-text m-t-5 fs-5">Number of preferred teachers replied to the requested
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <h4 className="mt-4">The top 10 teachers the learners picked</h4>

                <div className="px-2 mt-4">
                    <div>
                        {(topTeacherData && topTeacherData.length > 0 ? (
                            <DataTable columns={topColumns} data={topTeacherData} customStyles={customStyles} />
                        ) : (
                            <h4 className="d-flex justify-content-center mt-5" style={{ "color": "#570861" }}>Data Not Found</h4>
                        )
                        )}
                    </div>
                </div> */}

                {/* <hr className="mt-2 mb-2" /> */}

                {/* <h4 className="mt-4">The bottom 10 teachers who are not picked</h4>

                <div className="px-2 mt-4">
                    <div>
                        {(bottomTeacherData && bottomTeacherData.length > 0 ? (
                            <DataTable columns={bottomColumns} data={bottomTeacherData} customStyles={customStyles} />
                        ) : (
                            <h4 className="d-flex justify-content-center mt-5" style={{ "color": "#570861" }}>Data Not Found</h4>
                        )
                        )}
                    </div>
                </div> */}

                {/* <hr className="mb-3" /> */}

               {/* Top 10 Teachers Accordion */}
                <Accordion className="mt-4">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <p className="m-0 fw-500 fs-5" style={{ color: "#570861" }}>The top 10 teachers the learners picked</p>
                        </Accordion.Header>
                        <Accordion.Body>
                            {topTeacherData && topTeacherData.length > 0 ? (
                                <DataTable
                                    columns={topColumns}
                                    data={topTeacherData}
                                    customStyles={customStyles}                                    
                                />
                            ) : (
                                <h4 className="d-flex justify-content-center mt-5" style={{ color: "#570861" }}>
                                    Data Not Found
                                </h4>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

               {/* Bottom 10 Teachers Accordion */}
               <Accordion className="mt-4">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <p className="m-0 fw-500 fs-5" style={{ color: "#570861" }}>The bottom 10 teachers who are not picked</p>
                        </Accordion.Header>
                        <Accordion.Body>
                            {bottomTeacherData && bottomTeacherData.length > 0 ? (
                                <DataTable
                                    columns={bottomColumns}
                                    data={bottomTeacherData}
                                    customStyles={customStyles}                                    
                                />
                            ) : (
                                <h4 className="d-flex justify-content-center mt-5" style={{ color: "#570861" }}>
                                    Data Not Found
                                </h4>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>
        </main>
    </>
}
