import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { admin_permission_detail, delete_News_Letter, NewsLetterListing } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import FontAwesome from "react-fontawesome";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import Image from "../../../user.png";
import RelativeTime from "../../../utils/RelativeTime";
import { Link } from "react-router-dom";
import moment from 'moment';

export default function NewsLetter({ isLoader }) {

    const [data, setData] = useState([])
    const [permissions, setPermission] = useState([]);
    const [search, setSearch] = useState('')
    const [role, setRole] = useState(localStorage.getItem("adminRole"));
    const [adminId, setAdminId] = useState(localStorage.getItem("aID"));
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [action, setAction] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handleClose = () => setShow(false);
    const handleShow = (action) => {
        setShow(true);
        setAction(action);
    };

    const handleGetData = (search) => {
        isLoader(true)
        NewsLetterListing({ search: search }).then((response) => {
            console.log('response :', response);
            if (response.code == 1) {
                setData(response.data)
                setTotalPages(response?.data?.totalPages);
                isLoader(false)
            } else {
                setData([]);
                isLoader(false)
            }
        })
    }

    const handleDelete = (id, admin_id) => {

        if (role === "A" && adminId != admin_id) {
            handleShow('delete');
        } else {
            Swal.fire({
                icon: "warning",
                title: "Are you sure you want to delete?",
                showCancelButton: true,
                confirmButtonText: "Delete",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        isLoader(true)
                        delete_News_Letter({ newsletter_id: id }).then((response) => {
                            if (response.code == 1) {
                                TOAST_SUCCESS(response.message);
                                handleGetData(search, currentPage)
                                isLoader(false)
                            } else {
                                TOAST_ERROR(response.message);
                                isLoader(false)
                            }
                        });
                    } catch (error) {
                        TOAST_ERROR("Error!!");
                    }
                }
            });
        }
    };

    useEffect(() => {
        handleGetData(search)
        if (role !== "S") {
            subAdminPermission();
        }
    }, [search])

    const addNewsLetter = () => {
        navigate('/add_news_letter')
    }

    const subscriptionUsers = () => {
        navigate('/subscription_users')
    }

    const subAdminPermission = () => {
        admin_permission_detail({ id: adminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([])
                }
            })
            .catch((error) => {
                setPermission([])
            });
    }

    const newsLetterPermission = permissions.find(permission => permission.menu_name === "Newsletter");

    // Check if add,edit,delete is allowed for "Blogs"
    const isAddAllowed = newsLetterPermission && newsLetterPermission.add === 1;
    const isDeleteAllowed = newsLetterPermission && newsLetterPermission.delete === 1;

    const formatDate = (dateString) => {
        if (!dateString) return '';

        return moment(dateString).format('D MMMM YYYY HH:mm:ss');
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <Link className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </Link>
                            <Link to="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </Link>
                        </div>
                    </div>
                </header>

                <div className="mx-5 mt-1">
                    <p className="fs-3 fw-500 mt-2">News Letter</p>
                    <div className="d-flex justify-content-end align-items-center mb-1 search-container">
                        <div className="row me-2">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome className="fa-solid fa-search" name="search" />
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                        {(role === "S" || isAddAllowed) &&
                            <div className="d-flex">
                                <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={addNewsLetter}>
                                    + Add News Letter
                                </button>
                                <button className="ms-2 btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={subscriptionUsers}>
                                    View Subscription Users
                                </button>
                            </div>
                        }
                    </div>

                    <div className="review_dack">
                        {data?.length > 0 ?
                            data?.map((classroom_data, index) => {
                                // console.log('classroom_data :', classroom_data);
                                return (
                                    <div key={index} className="card review_box mb-4 mt-4">
                                        <div className="review_top">
                                            <div className="list_btw_dots mb-4">
                                                <ul className="d-sm-flex align-items-center">
                                                    <span className="pe-4 fw-600 position-relative">News Letter <span className=" position-absolute" /></span>
                                                </ul>
                                            </div>
                                            <div className="d-lg-flex justify-content-between mb-3 align-items-satrt">
                                                <div className="d-flex align-items-center">
                                                    <div className="fw-600">
                                                        <span className="d-block">{classroom_data?.title}</span>
                                                    </div>
                                                </div>
                                                <div className="d-lg-flex d-block font-14 mb-lg-0 mb-2 fw-600 align-items-center">
                                                    <span>Created By</span>: &nbsp;<span>{classroom_data?.name}</span>
                                                </div>
                                            </div>
                                            <div className="review_text row mb-4">
                                                <div className="col-lg-8">
                                                    {/* <TruncateText text={classroom_data?.description} length={25} className="fs-6" /> */}
                                                </div>
                                            </div>
                                            <div className="d-flex flex-sm-row  flex-column-reverse justify-content-between align-items-sm-center">
                                                <div className="button d-flex align-items-center justify-content-between">
                                                    <Link to="/news_letter_details" state={{ id: classroom_data?.id }} className="btn-theme px-3 d-inline-block text-center">View Details</Link>
                                                    {(role === "S" || isDeleteAllowed) &&
                                                        <ul className="edit_delete d-flex ms-4 align-items-center justify-content-end">
                                                            <li>
                                                                <a
                                                                    onClick={() => handleDelete(classroom_data.id, classroom_data.admin_id)}
                                                                    className>
                                                                    <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                                                    </svg>
                                                                </a>
                                                            </li>
                                                            {/* <li>
                                                            <Link to="/edit-lesson" state={classroom_data?.id} className>
                                                                <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                                                    <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                                                </svg>
                                                            </Link>
                                                        </li> */}
                                                        </ul>
                                                    }
                                                </div>
                                                <div className="list_btw_dots mb-sm-0 mb-4">
                                                    <ul className="d-sm-flex flex-column align-items-start">
                                                        <li>
                                                            <span className="text-purple fw-500">
                                                                <span className="text-dif-black">Created Date :</span> {formatDate(classroom_data?.created_at)}
                                                            </span>
                                                        </li>
                                                        <li className="mt-2">
                                                            <span className="text-purple fw-500">
                                                                <span className="text-dif-black">Posted On : </span>
                                                                {classroom_data?.send_time === "now"
                                                                    ? formatDate(classroom_data?.created_at)
                                                                    : formatDate(`${classroom_data?.schedule_date} ${classroom_data?.schedule_time}`)}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) :
                            <h4 className="d-flex justify-content-center mt-5" style={{ "color": "#570861" }}>Data Not Found</h4>
                        }
                    </div>
                </div>
            </div>
        </main>

        {/* Modal for show error */}
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body className="fs-5 mt-3 text-danger text-center">
                {action === "edit" ? "You don't have permission to edit this blog." : "You don't have permission to delete this blog."}
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <Button variant="warning" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}