import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import { accept_request_class, New_Leads_details, reject_new_leads, teacher_detail_of_leads, teacher_select_class_list } from '../../../api/apiHandler'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import Modals from 'react-modal';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service'
import Swal from 'sweetalert2';
import "./Ratingstyle.css";
import Hdashboard from '../../../components/Hdashboard';
var COMMON_STYLE_FOR_POPUP = {
  content: {
    // backgroundColor: 'rgb(0 0 0 / 56%)',
    background: 'none',
    border: '0px',
  },
}

export default function NonPrefferedTeacherDetails() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const location = useLocation();

  const navigate = useNavigate();
  var id = location?.state;
  console.log('location :', id); 
   var request_id = id?.id
   console.log('request_id :', request_id);
  const [modalIsOpen, setIsOpen] = useState(false);

  var [data, setData] = useState([]);
  var [newData, setNewData] = useState([]);
  var [selectClass, setselectClass] = useState([]);


  const schema = yup.object().shape({
    select_class: yup.string().required("Class is required "),

  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  let onSubmit = async (data) => {
    let class_data = {

      request_class_id: id?.id,
      slect_class_id: data?.select_class,
      is_status: "Accepted",

    }
    // accept_request_class(class_data).then((res) => {

    //   if (res?.code == 1) {
    //     TOAST_SUCCESS(res.message);
    //     navigate('/new_leads');
    //   } else {
    //     TOAST_ERROR(res.message);
    //   }
    // });
    reset();

  }


  const NewLeadsDetail = () => {


    // New_Leads_details({ class_id: id?.id }).then((resposnse) => {

    //   if (resposnse?.code == 1) {
    //     setData(resposnse?.data[0])
    //     setNewData(resposnse?.data[0].class_details[0])

    //   } else {
    //   }
    // });
  }

  useEffect(() => {
    NewLeadsDetail();
    selectClassData();
  }, []);

  var [teacherData, setTeacherData] = useState([]);


  const TeacherResponsedata = () => {


    // teacher_detail_of_leads().then((resposnse) => {

    //   if (resposnse?.code == 1) {
    //     if (resposnse.data[0].teacher_ratings == null || resposnse.data[0].teacher_ratings == undefined || resposnse.data[0].teacher_ratings == '') {
    //       resposnse.data[0].teacher_ratings = 0;
    //     }
    //     setTeacherData(resposnse?.data[0])
    //     setIsOpen(true)

    //   } else {
    //   }
    // });
  }


  const selectClassData = () => {

    // teacher_select_class_list().then((resposnse) => {

    //   if (resposnse?.code == 1) {

    //     setselectClass(resposnse?.data)

    //   } else {
    //   }
    // });
  }

  const RejectRequest = (request_class_id) => {

    // alert(id)

    Swal.fire({
      title: 'Are you sure you want to reject ?',
      // text: "You won't be able to revert this!",
      icon: 'question',
      background: 'white',
      showCancelButton: true,
      confirmButtonColor: '#570861',
      cancelButtonColor: '#FFC924',
      confirmButtonText: 'Yes, Reject it! ',
      cancelButtonClass: 'swal-cancel-button',
    }).then((result) => {
      if (result.isConfirmed) {
        // reject_new_leads({ request_class_id: request_class_id }).then((response) => {
        //   if (response.code == 1) {
        //     TOAST_SUCCESS(response.message);
        //     navigate("/new_leads")
        //   } else {
        //     TOAST_ERROR(response.message);
        //   }
        // })
      }
    })
  }

  return (
    <>
      <JqueryLoad />
      <Hdashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span  className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div>
                  <div className="row">
                    <div className="col-xl-9">
                      <div className="d-flex mb-4 justify-content-between">
                        <h2 className>Class Details</h2>
                        {/* <a href="request_class.php">
                          <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 0.5C14.5388 0.5 11.1554 1.52636 8.27753 3.44928C5.39967 5.37221 3.15665 8.10533 1.83212 11.303C0.507582 14.5007 0.161024 18.0194 0.836266 21.4141C1.51151 24.8088 3.17822 27.927 5.62564 30.3744C8.07306 32.8218 11.1913 34.4885 14.5859 35.1637C17.9806 35.839 21.4993 35.4924 24.697 34.1679C27.8947 32.8434 30.6278 30.6003 32.5507 27.7225C34.4736 24.8446 35.5 21.4612 35.5 18C35.4926 13.361 33.6465 8.91409 30.3662 5.63381C27.0859 2.35353 22.639 0.507405 18 0.5ZM23.88 22.025C24.1241 22.272 24.261 22.6052 24.261 22.9525C24.261 23.2998 24.1241 23.633 23.88 23.88C23.7584 24.0021 23.6138 24.099 23.4547 24.1651C23.2955 24.2313 23.1249 24.2653 22.9525 24.2653C22.7802 24.2653 22.6095 24.2313 22.4503 24.1651C22.2912 24.099 22.1466 24.0021 22.025 23.88L18 19.855L13.975 23.88C13.8534 24.0021 13.7088 24.099 13.5497 24.1651C13.3905 24.2313 13.2199 24.2653 13.0475 24.2653C12.8752 24.2653 12.7045 24.2313 12.5453 24.1651C12.3862 24.099 12.2416 24.0021 12.12 23.88C11.8759 23.633 11.739 23.2998 11.739 22.9525C11.739 22.6052 11.8759 22.272 12.12 22.025L16.145 18L12.12 13.975C11.8882 13.7262 11.762 13.3971 11.768 13.0571C11.774 12.7171 11.9117 12.3926 12.1522 12.1522C12.3926 11.9117 12.7171 11.7739 13.0571 11.7679C13.3971 11.7619 13.7262 11.8882 13.975 12.12L18 16.145L22.025 12.12C22.2738 11.8882 22.6029 11.7619 22.9429 11.7679C23.283 11.7739 23.6074 11.9117 23.8478 12.1522C24.0883 12.3926 24.2261 12.7171 24.2321 13.0571C24.2381 13.3971 24.1118 13.7262 23.88 13.975L19.855 18L23.88 22.025Z" fill="black" />
                          </svg>
                        </a>	 */}
                      </div>
                      <div className="lead_request pt-2">
                        <div className="card">
                          <div className="lead_req_img d-flex position-relative align-items-center">
                            {/* <img src="./assets/images/user/user.png" alt="user" /> */}
                            <img className="rounded-circle" src={data.profile_image} alt="user" />

                            <div className="ms-3">
                              <span className="fw-600">{data.full_name}</span>
                            </div>
                          </div>
                          <div className="d-md-flex align-items-end  justify-content-between ">
                            <ul>
                              <li className="mb-2">
                                <span className="font-12">

                                  <span className="fw-500 font-14" style={{ color: '#570861' }}>Learner’s Country : <span className="fw-500 text-dif-black font-14"> &nbsp;{data.country_name != null ? data.country_name : "N/A"}</span></span>
                                </span>
                              </li>
                              <li className="mb-2">
                                <span className="fw-500 font-14" style={{ color: '#570861' }}>Learner’s Local Time : <span className="fw-500 text-dif-black font-14">&nbsp;{data.local_time}</span></span>
                              </li>
                              <li className="mb-2">
                                <span className="fw-500 font-14" style={{ color: '#570861' }}>Requested Categories : <span className="fw-500 text-dif-black font-14">&nbsp;{data.category_list}</span></span>
                              </li>
                              <li className="mb-2">
                                <span className="fw-500 font-14" style={{ color: '#570861' }}>Requested Subjects : <span className="fw-500 text-dif-black font-14">&nbsp;{data.subcategory_list} </span></span>
                              </li>
                              <li className="mb-2">
                                <span className="fw-500 font-14" style={{ color: '#570861' }}>Class Size :
                                  <span className="fw-500 text-dif-black font-14">&nbsp;{data.class_size == "Individual/private" ? "1-on-1 Private Lesson" : "Group Lesson"}</span>
                                </span>
                              </li>
                            </ul>
                            {/* { id.count < 2 ?  */}
                            {/* <> */}
                            <div className="button">
                              <Link className="btn-theme bg-yellow d-inline-block text-center font-14" state={id} onClick={() => TeacherResponsedata()} >Contact</Link>
                              <Link className="btn-theme  d-inline-block text-center font-14 ms-sm-3" state={id} onClick={() => RejectRequest(newData.id)}>Reject</Link>
                            </div>
                            {/* </> : <></> */}

                            {/* } */}
                          </div>
                        </div>
                      </div>
                      <div className="card request_class_info">
                        <div className="req_info_ques">
                          <span className="text-purple">What is your budget?</span>
                          <p>{newData.budget_price}</p>
                        </div>
                        <div className="req_info_ques">
                          <span className="text-purple">What is your day preference?</span>
                          <p>{newData.day_preference}</p>
                        </div>
                        <div className="req_info_ques">
                          <span className="text-purple">What you are expecting from the class?</span>
                          <p>{newData.your_expectation}</p>
                        </div>
                        <div className="req_info_ques">
                          <span className="text-purple">How often do you want lessons?</span>
                          <p>{newData.want_lessons}</p>
                        </div>
                        <div className="req_info_ques">
                          <span className="text-purple">Are you ready to hire someone?</span>
                          <p>{newData.hire_someone}</p>
                        </div>
                        <div className="req_info_ques">
                          <span className="text-purple">Any Comments</span>
                          <p>{newData.comments != "null" ? newData.comments : ""}</p>
                        </div>
                        {/* <div className="mb-2">
                          <span>
                            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M3.75 6.75C3.75 3.8475 6.0975 1.5 9 1.5C11.9025 1.5 14.25 3.8475 14.25 6.75C14.25 10.6875 9 16.5 9 16.5C9 16.5 3.75 10.6875 3.75 6.75ZM7.125 6.75C7.125 7.785 7.965 8.625 9 8.625C10.035 8.625 10.875 7.785 10.875 6.75C10.875 5.715 10.035 4.875 9 4.875C7.965 4.875 7.125 5.715 7.125 6.75Z" fill="#570861" />
                            </svg>
                            <span className="ms-2">USA, Illinois 854***</span>
                          </span>
                        </div>
                        <div className>
                          <iframe width="100%" height={640} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3142813.6245756545!2d-91.90605051033272!3d39.713723242217334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880b2d386f6e2619%3A0x7f15825064115956!2sIllinois%2C%20USA!5e0!3m2!1sen!2sin!4v1688636395726!5m2!1sen!2sin" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >
        {/* <Teacher_Response setIsOpen={setIsOpen} setTeacherData={setTeacherData} teacherData={teacherData}/> */}
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 pb-0">
              <h5 className="modal-title" id="exampleModalLabel" />
              <button type="button" className="btn-close" onClick={() => setIsOpen(false)} />
            </div>
            <div className="modal-body meet_techer">
              <div className="meet_details mb-5 d-lg-flex align-items-start">
                <div className="meet_img mb-lg-0 mb-4">
                  <img src={teacherData.profile_image} alt="meet_techer" />
                </div>
                <div className="meet_right">
                  <div className>
                    <div className="deg_name d-flex mb-xl-0 mb-3 align-items-center">
                      <h2 className="poppins fw-600 text-purple">{teacherData.full_name}</h2>
                      <span className=" ms-2 text-dif-black">(MSC.BED)</span>
                    </div>
                  </div>
                  <div className="live_canada align-items-center mb-3 d-flex">
                    <span className="fs-5">Lives in {teacherData.country_name}</span>
                    <img src="./assets/images/country_flag.png" alt="country_flag" className="ms-3" />
                  </div>
                  <p className="fs-5 text-dif-black mb-3 fw-600">Experienced Multi-Subject Teacher: Creative Arts &amp; World Travels</p>
                  <div className="mb-3 d-lg-flex align-items-center">

                    {/* <ul className="d-flex mb-lg-0 mb-2 align-items-center"> */}
                    <i data-star={teacherData.teacher_ratings}></i>
                    <span className="fs-5 fw-600 ms-lg-3">{teacherData.teacher_ratings} Reviews</span>
                    <span className="fw-bold text-gray d-sm-inline-block d-flex fs-5 ms-sm-2">({teacherData.teacher_reviews} Total Reviews)</span>
                  </div>
                  <span className="text-gray mb-3 d-block fw-600">238 Completed Class</span>
                  <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                    <div className="form-group mb-4">
                      <label className="mb-2">Select A Class</label>
                      <select className="form-select" {...register("select_class")} name="select_class" aria-label="Default select example">
                        <option value='' selected disabled>select class</option>

                        {selectClass?.map((coupon, index) => {

                          return (

                            <option key={index} value={coupon?.class_id} >{coupon?.title}</option>
                          )
                        })}

                      </select>
                      <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.select_class?.message}</span></p>

                    </div>
                    <div className="d-xl-flex align-items-center">
                      <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" >
                        Contact Learner
                      </button>
                      {/* <a href="#!" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Contact Student</a> */}
                      <Link className="btn-theme d-inline-block text-center ms-xl-3" state={id} onClick={() => setIsOpen(false)}>Cancel</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modals>
    </>
  )
}
