import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";

import { Link, useLocation, useNavigate } from "react-router-dom";

export default function PreviewNewsLetter({ isLoader }) {

    // const [data, setData] = useState({});

    const location = useLocation();
    const data = location.state?.previewData;
    console.log('data :', data);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const openLinkInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const handleVideoClick = (videoUrl) => {
        window.open(videoUrl, "_blank", "noopener,noreferrer");
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <Link className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </Link>
                            <Link to="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </Link>
                        </div>
                    </div>
                </header>
                <span onClick={handleBack} className="mx-4 mt-3">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>
                    <span className="ms-2">Back</span>
                </span>

                <div className="mx-5">
                    <p className="fs-3 fw-500 mt-2">Preview News Letter</p>
                    {/* <div className="d-flex justify-content-center">
                        <div className="d-flex align-items-center  col-md-6 mb-5 p-2">
                            {data.title ? (
                                <div className="newsletter-details card border-purple mb-3">
                                    <p className="fw-500 fs-4 text-center mt-3">{data.title}</p>
                                    <div className="descriptions">
                                        {data.description.map(desc => (
                                            <div key={desc.id} className="description mb-2 p-4">
                                                {desc.attachments && (
                                                    <div className="attachment mt-2 position-relative col-md-12" > 
                                                        {desc.attachment_type === "image" ? (
                                                            <img
                                                                src={desc.attachment}
                                                                alt="Attachment"
                                                                className="img-fluid"
                                                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                                                            />
                                                        ) : desc.attachment_type === "video" ? (
                                                            <div className="position-relative" style={{ width: '100%' }}>
                                                                <img
                                                                    src={desc.thumb_image}
                                                                    alt="Thumbnail"
                                                                    className="img-fluid"
                                                                    style={{ width: '100%', height: 'auto', borderRadius: '8px', cursor: 'pointer' }}
                                                                    onClick={() => handleVideoClick(desc.attachment)}
                                                                />
                                                                <div
                                                                    className="play-icon position-absolute top-50 start-50 translate-middle"
                                                                    onClick={() => handleVideoClick(desc.attachment)}
                                                                    style={{ width: '50px', height: '50px' }}
                                                                >
                                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="25" cy="25" r="25" fill="rgba(0, 0, 0, 0.6)" />
                                                                        <path d="M15 12.5V37.5L37.5 25L15 12.5Z" fill="white" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <a href={desc.attachment} target="_blank" rel="noopener noreferrer">View Attachment</a>
                                                        )}
                                                    </div>
                                                )}
                                                <div dangerouslySetInnerHTML={{ __html: desc.description }} className="mt-3" />
                                                {desc.link && (
                                                    <div className="mt-4 mb-2">
                                                        <button
                                                            onClick={() => openLinkInNewTab(desc.link)}
                                                            className="btn-theme col-md-12"
                                                        >
                                                            Read More
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <p className="text-purple fw-500 fs-4 ms-5 mt-3">No data available</p>                      
                            )}
                        </div>
                    </div> */}
                    <div className="d-flex justify-content-center">
                        <div className="d-flex align-items-center col-md-6 mb-5 p-2">
                            {data.title ? (
                                <div className="newsletter-details card border-purple mb-3">
                                    <p className="fw-500 fs-4 text-center mt-3">{data.title}</p>
                                    <div className="descriptions">
                                        {data.description.map(desc => (
                                            <div key={desc.id} className="description mb-2 p-4">
                                                {desc.attachments && (
                                                    <div className="attachments-container mt-2">
                                                        {desc.attachments.map((attachment, index) => (
                                                            <div key={index} className="attachment mt-2 position-relative col-md-12">
                                                                {attachment ? (
                                                                    <>
                                                                        {attachment.attachment_type === "image" ? (
                                                                            <img
                                                                                src={attachment.attachments}
                                                                                alt="Attachment"
                                                                                className="img-fluid"
                                                                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                                                                            />
                                                                        ) : attachment.attachment_type === "video" ? (
                                                                            <div className="position-relative" style={{ width: '100%' }}>
                                                                                <img
                                                                                    src={attachment.thumb_image}
                                                                                    alt="Thumbnail"
                                                                                    className="img-fluid"
                                                                                    style={{ width: '100%', height: 'auto', borderRadius: '8px', cursor: 'pointer' }}
                                                                                    onClick={() => handleVideoClick(attachment.attachments)}
                                                                                />
                                                                                <div
                                                                                    className="play-icon position-absolute top-50 start-50 translate-middle"
                                                                                    onClick={() => handleVideoClick(attachment.attachments)}
                                                                                    style={{ width: '50px', height: '50px' }}
                                                                                >
                                                                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <circle cx="25" cy="25" r="25" fill="rgba(0, 0, 0, 0.6)" />
                                                                                        <path d="M15 12.5V37.5L37.5 25L15 12.5Z" fill="white" />
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <a href={attachment.attachments} target="_blank" rel="noopener noreferrer">View Attachment</a>
                                                                        )}
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                <div dangerouslySetInnerHTML={{ __html: desc.description }} className="mt-3" />
                                                {desc.link && (
                                                    <div className="mt-4 mb-2">
                                                        <button
                                                            onClick={() => openLinkInNewTab(desc.link)}
                                                            className="btn-theme col-md-12"
                                                        >
                                                            Read More
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <p className="text-purple fw-500 fs-4 ms-5 mt-3">No data available</p>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </main>
    </>
}