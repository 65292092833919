import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useRef, useState } from "react";
import { classDetails, sendCommentClassroom, AcceptRejectClassroom } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";

export default function ClassDetail({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const classId = location.state && location.state.class_id;

    const GetClassDetails = () => {
        isLoader(true)
        classDetails({ id: classId, type: "class" }).then((response) => {
            console.log('response :', response);
            if (response.code == 1) {
                setData(response.data[0])
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    useEffect(() => {
        GetClassDetails();
    }, [classId]);

    const handleBack = () => {
        navigate(-1);
    };

    const handleAccpet = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to accept this class?",
            showCancelButton: true,
            confirmButtonText: "Accept",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objAccept = {
                        common_id: classId,
                        email: data.email,
                        is_accepted: 1,
                        type: "class",
                        status : "Listed"
                    };
                    isLoader(true)
                    AcceptRejectClassroom(objAccept).then((response) => {
                        if (response.code == 1) {
                            isLoader(false)
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, is_accepted: "1" }));
                        } else {
                            isLoader(false)
                            TOAST_ERROR(response.message);
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!");
                }
            }
        });
    }

    const handleReject = () => {

        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to reject this class?",
            showCancelButton: true,
            confirmButtonText: "Reject",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objReject = {
                        common_id: classId,
                        email: data.email,
                        is_accepted: 2,
                        type: "class",
                        status : "Unlisted"
                    };
                    isLoader(true)
                    AcceptRejectClassroom(objReject).then((response) => {
                        if (response.code == 1) {
                            isLoader(false)
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, is_accepted: "2" }));
                        } else {
                            isLoader(false)
                            TOAST_ERROR(response.message);
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!");
                }
            }
        });
    }

    const handleResubmit = () => {

        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to request for resubmit this class?",
            showCancelButton: true,
            confirmButtonText: "Resubmit",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objReject = {
                        common_id: classId,
                        email: data.email,
                        is_accepted: 3,
                        type: "class",
                        status : "Request for Resubmit"
                    };
                    isLoader(true)
                    AcceptRejectClassroom(objReject).then((response) => {
                        if (response.code == 1) {
                            isLoader(false)
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, is_accepted: "3" }));
                        } else {
                            isLoader(false)
                            TOAST_ERROR(response.message);
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!");
                }
            }
        });
    }

    const fileInputs = useRef([]);
    const resetFileInputs = () => {
        fileInputs.current.forEach((input) => {
            input.value = '';
        });
    };

    const commentSchema = Yup.object({
        comment: Yup.string()
    });

    const commentForm = useFormik({
        initialValues: {
            comment: '',
        },
        validationSchema: commentSchema,

        onSubmit: async (values) => {

            values.id = classId;
            values.email = data.email;
            values.type = "class";
            isLoader(true)
            sendCommentClassroom(values).then((response) => {
                if (response.code == 1) {
                    TOAST_SUCCESS(response.message);
                    commentForm.resetForm();
                    resetFileInputs();
                    GetClassDetails();
                    isLoader(false)
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            });
        },
    });

    const displayError = (fieldName) =>
        commentForm.touched[fieldName] && commentForm.errors[fieldName];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </header>
        <section className="main_sec">
            <div className="mt-4 mx-5 mb-5">
                <div>
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                </div>
                {(
                    <>
                        <p className="ms-3 fs-3 fw-500 text-center mb-4">Class Details</p>
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col col-lg-8 mb-4 mb-lg-0">
                                <div className="card mb-3 p-3" style={{ borderRadius: ".5rem" }}>
                                    <div className="row g-0">
                                        <div className="col-md-12">
                                            <div className="card-body p-4">
                                                <p className="mb-2 fs-5 fw-600">Information</p>
                                                <hr className="mt-0 mb-4" />
                                                <div className="row pt-1">
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Class Title </p>
                                                        <p className="text-muted mt-1">{data.title}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Class Type</p>
                                                        <p className="text-muted mt-1">{data.type}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Class Length</p>
                                                        <p className="text-muted mt-1">{data.length}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Class Summary</p>
                                                        <p className="text-muted mt-1">{data.summary}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Class Video</p>
                                                        {data.video_file && (
                                                            <a href={data.video_file} className="text-purple underline mt-1"> View Video</a>
                                                        )}
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Main Category</p>
                                                        <p className="text-muted mt-1">{data.main_category}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Sub Category</p>
                                                        <p className="text-muted mt-1">{data.subject_category}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Minimum Learner Age</p>
                                                        <p className="text-muted mt-1">{data.min_age}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Maximum Learner Age</p>
                                                        <p className="text-muted mt-1">{data.max_age}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Class Size</p>
                                                        <p className="text-muted mt-1">{data.class_size}</p>
                                                    </div>
                                                    {data.class_size != "private" &&
                                                        <>
                                                            <div className="col-4 mb-4">
                                                                <p className="fw-500">Minimum Learner</p>
                                                                <p className="text-muted mt-1">{data.min_learner}</p>
                                                            </div>
                                                            <div className="col-4 mb-4">
                                                                <p className="fw-500">Maximum Learner</p>
                                                                <p className="text-muted mt-1">{data.max_learner}</p>
                                                            </div>
                                                        </>}
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Meeting Duration</p>
                                                        <p className="text-muted mt-1">{data.duration}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Pre-requisites</p>
                                                        <p className="text-muted mt-1">{data.pre_requisites}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Language of instruction</p>
                                                        <p className="text-muted mt-1">{data.class_language}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Description </p>
                                                        <p className="text-muted mt-1">{data.description}</p>
                                                    </div>
                                                    {(data.homeworks != "" && data.homeworks != null) &&
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500">Homework(Optional) </p>
                                                            <p className="text-muted mt-1">{data.homeworks}</p>
                                                        </div>
                                                    }
                                                    {(data.assessment != "" && data.assessment != null) &&
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500">Assessment(Optional) </p>
                                                            <p className="text-muted mt-1">{data.assessment}</p>
                                                        </div>
                                                    }
                                                    {(data.parental_guide != "" && data.parental_guide != null) &&
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500"> Parental Guidance (Optional) - For Learners Under 18 Years Old </p>
                                                            <p className="text-muted mt-1">{data.parental_guide}</p>
                                                        </div>
                                                    }
                                                    {(data.source != "" && data.source != null) &&
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500"> Sources (Required For Certain Topics – Optional)</p>
                                                            <p className="text-muted mt-1">{data.source}</p>
                                                        </div>
                                                    }
                                                    {(data.supply_list != "" && data.supply_list != null) &&
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500">Learner Supply List(Optional)</p>
                                                            <p className="text-muted mt-1">{data.supply_list}</p>
                                                        </div>
                                                    }
                                                    {(data.next_class != "" && data.next_class != null) &&
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500">Next Class(Optional)</p>
                                                            <p className="text-muted mt-1">{data.next_class}</p>
                                                        </div>
                                                    }
                                                    <div className="col-3 mb-4">
                                                        <p className="fw-500">Class Price</p>
                                                        <p className="text-muted mt-1">{data.price}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Class Created Date</p>
                                                        <p className="text-muted mt-1"> {moment(data.created_at).format("DD MMMM YYYY")}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Admin Comments</p>
                                                        <p className="text-muted mt-1">{data.comments}</p>
                                                    </div>
                                                </div>

                                                <form className="custom_form mt-4" name="commentForm" onSubmit={commentForm.handleSubmit}>

                                                    <div className="form-group mb-4">
                                                        <p className="fw-500 mb-2">Any comments you have, please add them here for this class.</p>
                                                        <textarea type="text" className="form-control" name="comment" value={commentForm.values.comment} rows="5" cols="33" onChange={commentForm.handleChange} ></textarea>
                                                        {displayError("comment") && (
                                                            <div className="text-danger">{commentForm.errors.comment}</div>
                                                        )}
                                                        <div className="button mt-2 mb-2 d-flex justify-content-end">
                                                            <button type="submit" className="btn-theme px-4 py-2  text-center text-dif-black fw-500 bg-yellow mb-xl-0 mb-3 d-inline-block">Send</button>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="d-flex justify-content-center mt-3">
                                                    <button className="btn-theme mx-1" onClick={() => handleResubmit()}>
                                                        ReSubmit
                                                    </button>
                                                    <button className="btn-theme bg-yellow mx-1" onClick={() => handleAccpet()}>
                                                        {data.is_accepted == "1" ? "Accpted" : "Accept"}
                                                    </button>
                                                    <button className="btn-theme mx-1" onClick={() => handleReject()}>
                                                        {data.is_accepted == "2" ? "Rejected" : "Reject"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </section>
    </>
}

