import { useNavigate, useLocation } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { delete_faq_video, get_faq_video } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import RelativeTime from "../../../utils/RelativeTime";
import Swal from "sweetalert2";

export default function Video({isLoader}) {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const AdminId = localStorage.getItem("aID");
    const location = useLocation();
    const FaqId = location.state;

    const handleVideoDetails = (videoId) => {
        const selectedData = data.find(item => item.id === videoId);
        if (selectedData) {
            navigate("/video-details", { state: selectedData });
        } else {
            console.log("Data not found for videoId:", videoId);
        }
    };

    const handleGetData = () => {
        isLoader(true)
        get_faq_video({ id: FaqId }).then((response) => {
            if (response.code == 1) {
                setData(response.data);
                isLoader(false)
            }else{
                setData([]);
                isLoader(false)
            }
        });
    };

    const handleEditClick = (VideoId) => {
        const videoData = data.find(video => video.id === VideoId);
        if (videoData) {
            navigate('/edit-video', { state: { videoData: videoData } });
        }
    };

    const handleDelete = (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to delete this FAQ?",
            showCancelButton: true,
            confirmButtonText: "Delete",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    isLoader(true)
                    delete_faq_video({ id: id }).then((response) => {
                        if (response.code == 1) {
                            TOAST_SUCCESS(response.message);
                            handleGetData();
                            setData(data.filter(o => o.id !== id));
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error deleting FAQ video");
                }
            }
        });
    };

    useEffect(() => {
        handleGetData();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const addVideo = () => {
        navigate('/add-video', { state: { FaqId: FaqId } });
    };

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <Link className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars"></i>
                            </Link>
                            <Link to="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </Link>
                        </div>
                    </header>

                    <div className="mx-5 mt-4">
                        <span onClick={handleBack} className="mb-5" style={{ cursor: "pointer" }}>
                            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313"></path>
                            </svg>
                            <span className="ms-2">Back</span>
                        </span>
                        <h2 className="mt-3">FAQ's - Training Videos</h2>
                        <div className="d-flex justify-content-end mb-4">
                            <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={addVideo}>
                                + Add Video
                            </button>
                        </div>
                        <div className="row mb-5">
                            {data.length === 0 ? (
                                <h4 className="d-flex justify-content-center" style={{ color: "#570861", width: "100%" }}>Data Not Found</h4>
                            ) : (
                                data.map((video) => (
                                    <div key={video.id} className="col-xl-4 col-lg-6 col-md-6 mb-4">
                                        <div className="certi_box bg-transparent shadow-none">
                                            <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0" onClick={() => handleVideoDetails(video.id)}>
                                                {video.media_type === "video" ? (
                                                    <video controls className="w-100 img-fluid border-radius-10" style={{ height: "300px", objectFit: "cover" }}>
                                                        <source src={video.media} type="video/mp4" />
                                                    </video>
                                                ) : (
                                                    <img src={video.media} alt="video" className="w-100 img-fluid border-radius-10" style={{ height: "300px", objectFit: "cover" }} />
                                                )}
                                            </div>

                                            <div className="certi_ctn px-3">
                                                <div className="mt-2">
                                                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                                                        <h2 className="fw-600 text-dif-black text-capitalize poppins cursor-pointer" onClick={() => handleVideoDetails(video.id)} style={{ maxWidth: "calc(100% - 100px)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                            {video.title}
                                                        </h2>
                                                        <div className="d-flex gap-3">
                                                            <div>
                                                                <FontAwesome
                                                                    name="edit"
                                                                    tabIndex={0}
                                                                    onClick={() => handleEditClick(video.id)}
                                                                    className="fs-5 fa-solid fa-edit text-info cursor-pointer"
                                                                ></FontAwesome>
                                                            </div>
                                                            <div>
                                                                <FontAwesome
                                                                    name="delete"
                                                                    className="fs-5 fa-solid fa-trash bg-light text-danger cursor-pointer"
                                                                    onClick={() => handleDelete(video.id)}
                                                                ></FontAwesome>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="description" style={{ maxHeight: "4.5rem", overflow: "hidden" }} onClick={() => handleVideoDetails(video.id)}>
                                                        <p className="text-dif-black text-capitalize poppins mb-0 mt-3" style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                                            {video.description.length > 50 ? video.description.substring(0, 50) + "..." : video.description}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mt-3" onClick={() => handleVideoDetails(video.id)}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="dwnl_img">
                                                            <img src={video.profile_image ? video.profile_image : Image} alt="profile_image" className="rounded-circle" style={{ height: "50px", width: "50px" }} />
                                                        </div>
                                                        <div className="ms-3">
                                                            <span className="fs-5 fw-500 overflow-hidden">{video.admin_name}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="mx-3">{video.formatted_date}</span> • <span className="mx-1"> <RelativeTime dateString={video.created_at} /> </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
