import { toast } from "react-toastify";
import dayjs from 'dayjs';

// import 'react-toastify/dist/ReactToastify.css';

const TOAST_SUCCESS = (message) => {
    return toast.success(message);
};

const TOAST_INFO = (message) => {
    return toast.info(message);
};

const TOAST_ERROR = (message) => {
    return toast.error(message);
};

const TOAST_WARNING = (message) => {
    return toast.warning(message);
};


// const convertToBase64 = async (file) => {
//   console.log(file)
//   console.log('video/mp4',file.type.includes("video"))
  
//     if (file.type.includes("video"))  return URL.createObjectURL(file);

//     return new Promise((resolve, reject) => {
//       const fileReader = new FileReader();
//       fileReader.onload = () => {
//         resolve(fileReader.result);
//       };
//       fileReader.onerror = reject;
//       file && fileReader.readAsDataURL(file);
//     });
//   };

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (file.type.includes("video")) {
      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(file);

      videoElement.onloadedmetadata = () => {
        if (videoElement.duration <= 90) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = reject;
          fileReader.readAsDataURL(file);
        } else {
          toast.error("Video duration must be 90 seconds or less.");
          URL.revokeObjectURL(videoElement.src);
          reject(new Error("Video duration exceeds 90 seconds."));

        }
      };
    } else {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsDataURL(file);
    }
  });
};

const formatDate = (date, format) => {
  return dayjs(date).format(format);
};


export {
    TOAST_SUCCESS,
    TOAST_INFO,
    TOAST_ERROR,
    TOAST_WARNING,
    convertToBase64,
    formatDate

};