import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useRef, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../../utils/common.service";
import { uploadImageOnAWS } from "../../aws/Service";
import { add_faq, add_faq_video } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Select from 'react-select';

export default function AddBlog({ isLoader }) {

    const navigate = useNavigate()
    const fileRef = useRef();
    const [image, setImage] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedPanels, setSelectedPanels] = useState([]);

    const options = [
        { label: 'Landing Page', value: 'landing_page' },
        { label: 'Teacher', value: 'teacher' },
        { label: 'Organization', value: 'organization' },
        { label: 'Organization Teacher', value: 'org_teacher' },
        { label: 'Parents', value: 'below_18' },
        { label: 'Kids', value: 'learner' },
        { label: 'Over 18', value: 'above_18' },
        { label: 'Schools-After School Centers', value: 'after_school' }
    ];

    const handleSelectAllChange = (e) => {
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            setSelectedPanels(options.map(option => option.value));
        } else {
            setSelectedPanels([]);
        }
    };

    const handlePanelChange = (selectedOptions) => {
        setSelectedPanels(selectedOptions ? selectedOptions.map(option => option.value) : []);
        faqForm.setFieldValue('panels', selectedOptions ? selectedOptions.map(option => option.value) : []);
        if (selectedOptions.length === options.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    };

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
        if (!showForm) {
            if (image) {
                setImage(null)
            }
            faqForm.setFieldValue('video_title', '');
            faqForm.setFieldValue('video_description', '');
            faqForm.setFieldValue('media', null);

            faqForm.setErrors(errors => {
                return {
                    ...errors,
                    media: ''
                };
            });
        }
    };

    const AdminId = localStorage.getItem("aID")

    const faqSchema = Yup.object({
        faq_title: Yup.string()
            .matches(/^[a-zA-Z?]+(?: [a-zA-Z?]+)*$/, 'Please enter a proper FAQ title')
            .required("Please enter FAQ title"),
        faq_description: Yup.string().required("Please enter FAQ description"),
        panels: Yup.array().min(1, "Please select at least one panel").required("Please select at least one panel"),
        video_title: showForm ? Yup.string()
            .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, 'Please enter proper FAQ video title')
            .required("Please enter FAQ video title") : Yup.string(),
        video_description: showForm ? Yup.string().required("Please enter FAQ video description") : Yup.string(),
        ...(showForm && {
            media: Yup.mixed().test('fileType', 'Invalid file format, only videos are allowed', function (value) {
                if (!value) return true;  // If no file is provided, it's considered valid
                return value && value.type.startsWith('video/');
            }).required("Please upload FAQ video")
        })
    });

    const faqForm = useFormik({
        initialValues: {
            faq_title: '',
            faq_description: '',
            panels: [],
            video_title: '',
            video_description: '',
            media: showForm ? null : ''
        },
        validationSchema: faqSchema,
        onSubmit: async (values) => {

            if (values.media != null) {
                var video = await uploadImageOnAWS(values.media, "training_videos");
            }

            var obj = {
                title: values.faq_title,
                description: values.faq_description,
                panels: selectedPanels
            };

            isLoader(true)
            add_faq(obj).then((response) => {
                console.log('response :', response);

                if (response.code == 1) {
                    isLoader(false)
                    var faq_id = response.data
                    console.log('faq_id :', faq_id);
                    if (showForm) {

                        var obj1 = {
                            admin_id: AdminId,
                            faq_id: faq_id,
                            media_type: 'video'
                        }

                        if (values.media !== null) {
                            obj1.media = video;
                        }
                        if (values.video_title !== null) {
                            obj1.title = values.video_title;
                        }
                        if (values.video_description !== null) {
                            obj1.description = values.video_description;
                        }
                        isLoader(true)
                        add_faq_video(obj1).then((response) => {
                            if (response.code == 1) {
                                TOAST_SUCCESS(response.message);
                                navigate('/faq')
                                isLoader(false)
                            } else {
                                TOAST_ERROR(response.message);
                                isLoader(false)
                            }
                        });
                    } else {
                        TOAST_SUCCESS(response.message);
                        navigate('/faq');
                        isLoader(false)
                    }
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            });
        }

    });

    const uploadImage = () => {

        fileRef.current.click();
        faqForm.setFieldValue('media', null);

        if (image) {
            setImage(null)
        }
    };

    const uploadImageEvent = async (e) => {
        const bufferImage = await convertToBase64(e.target.files[0]);
        setImage(bufferImage);
        faqForm.setFieldValue('media', e.target.files[0]);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const displayError = (fieldName) => {
        if (fieldName === 'media') {
            return faqForm.touched[fieldName] && faqForm.errors[fieldName] && showForm;
        }
        return faqForm.touched[fieldName] && faqForm.errors[fieldName];
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>
                <div className="mx-5 mt-4">

                    <span onClick={handleBack} className="mb-5">
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                    <div className="text-center fs-3 fw-500 mt-4 ms-4 mb-1">
                        Add FAQ
                    </div>
                    <div className="row align-items-center justify-content-center mt-5 mb-5">
                        <div className="col-lg-8">
                            <div className="card border-purple p-4" >

                                <form onSubmit={faqForm.handleSubmit} className="custom_form mt-4 ">
                                    <div className="form-group mb-4">
                                        <label className="mb-2">FAQ Title:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="faq_title"
                                            onChange={faqForm.handleChange}
                                            value={faqForm.values.faq_title}
                                        />
                                        {displayError("faq_title") && (
                                            <div className="text-danger">{faqForm.errors.faq_title}</div>
                                        )}
                                    </div>

                                    <div className="form-group mb-4">
                                        <label className="mb-2">FAQ Description:</label>
                                        <textarea
                                            className="form-control"
                                            name="faq_description"
                                            onChange={faqForm.handleChange}
                                            value={faqForm.values.faq_description}
                                            rows={4}
                                        />
                                        {displayError("faq_description") && (
                                            <div className="text-danger">{faqForm.errors.faq_description}</div>
                                        )}
                                    </div>

                                    <div className="form-group mb-4">
                                        <label className="mb-2">In which side you want to show this FAQ:</label>
                                        {!selectAll && (
                                            <Select
                                                options={options}
                                                value={options.filter(option => selectedPanels.includes(option.value))}
                                                className='mt-2'
                                                onChange={handlePanelChange}
                                                isSearchable={true}
                                                isMulti={true}
                                                placeholder="Select Panels..."
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        border: '1px solid rgba(102, 102, 102, 0.35)',
                                                        borderRadius: '12px',
                                                        padding: '5px 10px',
                                                        minHeight: '46px',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                            />
                                        )}
                                        {displayError("panels") && (
                                            <div className="text-danger">{faqForm.errors.panels}</div>
                                        )}
                                        {selectAll && (
                                            <input
                                                type="text"
                                                className="form-control py-2 mt-2"
                                                name="select_all"
                                                placeholder="All are selected"
                                                readOnly
                                                style={{ borderRadius: "12px" }}
                                            />
                                        )}
                                    </div>

                                    <div className="form-check mt-2">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="selectAll"
                                            checked={selectAll}
                                            onChange={handleSelectAllChange}
                                        />
                                        <label className="form-check-label text-secondary" htmlFor="selectAll">Select All Panels</label>
                                    </div>

                                    <div className="d-flex justify-content-end mt-3">
                                        <span className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={toggleFormVisibility}>
                                            Add Video
                                        </span>
                                    </div>

                                    {showForm && (
                                        <div>
                                            <div className="d-flex justify-content-center">
                                                <span onClick={uploadImage}>
                                                    {image !== null ? (
                                                        <video controls height="120px" width="200px" className="border-purple card p-1 video-element">
                                                            <source src={image} type="video/mp4" accept="video/*" />
                                                        </video>
                                                    ) : (
                                                        <img
                                                            src={image === null ? "./assets/images/image_upload.png" : image}
                                                            alt=""
                                                            className="img img-responsive card border-purple p-2"
                                                            height={"100px"}
                                                            width={"100px"}
                                                            accept="image/*"
                                                            style={{ borderRadius: "50%" }}
                                                        />
                                                    )}
                                                </span>
                                                <input
                                                    type="file"
                                                    ref={fileRef}
                                                    className="form-control d-none"
                                                    name="media"
                                                    accept={'video/mp4'}
                                                    onChange={uploadImageEvent}
                                                />
                                            </div>
                                            {showForm && displayError("media") && (
                                                <div className="text-danger d-flex justify-content-center">
                                                    {faqForm.errors.media}
                                                </div>
                                            )}

                                            <div className="form-group mb-4">
                                                <label className="mb-2">Video Title:</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="video_title"
                                                    onChange={faqForm.handleChange}
                                                    value={faqForm.values.video_title}
                                                />
                                                {displayError("video_title") && (
                                                    <div className="text-danger">{faqForm.errors.video_title}</div>
                                                )}
                                            </div>

                                            <div className="form-group mb-4">
                                                <label className="mb-2">Video Description:</label>
                                                <textarea
                                                    className="form-control"
                                                    name="video_description"
                                                    onChange={faqForm.handleChange}
                                                    value={faqForm.values.video_description}
                                                    rows={4}

                                                />
                                                {displayError("video_description") && (
                                                    <div className="text-danger">{faqForm.errors.video_description}</div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-center mt-3">
                                        <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </main >
    </>
}
