import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { AccessAccount, LearnerSubscriptionclassList } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import customStyles from "../../../utils/dataTableStyles";
import { TOAST_ERROR } from "../../../utils/common.service";
import { encryptURL } from "../../../api/apiClient";

export default function LearnerSubscriptionClass({ isLoader }) {

    const [classData, setClassData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const learnerId = location.state && location.state.learner_id
    const learnerName = location.state && location.state.LearnerName

    const [search, setSearch] = useState('');

    const GetParentLearnerData = () => {
        isLoader(true)
        LearnerSubscriptionclassList({ learner_id: learnerId, search: search }).then((response) => {
        console.log('response :', response);
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setClassData(dataWithRowCount);
                isLoader(false)
            } else {
                setClassData([]);
                isLoader(false)
            }
        });
    }
    const handleRedirect = (teacher_id, class_id, activity_type, platform_type) => {
        isLoader(true);
        AccessAccount({ user_id: learnerId })
          .then((response) => {
            if (response.code === "1") {
              localStorage.setItem("PAtoken", response.data[0]?.token);
              localStorage.setItem("MAisLogin", true);
              localStorage.setItem("userType", "learner");
              localStorage.setItem("PAid", learnerId);
              localStorage.setItem("MAname", learnerName);
              localStorage.setItem("role", "admin");
      
              isLoader(false);
      
              const data = {
                class_id,
                teacher_id,
                type: activity_type,
                platform_type,
              };
      
              const url = generatePath(data);
              window.open(url, '_blank');
            } else {
              isLoader(false);
              TOAST_ERROR("ERROR..!!");
            }
          })
          .catch((error) => {
            TOAST_ERROR("ERROR..!!");
          });
      };
      
      const generatePath = (data) => {
        const basePath = data.activity_type === "club"
          ? "/my_courses_details_clubs"
          : data.activity_type === "quiz"
            ? "/quiz-info"
            : data.activity_type === "competition"
              ? "/my_courses_details_competitions"
              : "/my_courses_detail";
      
        const queryParams = new URLSearchParams({
          class_id: data.class_id,
          teacher_id: data.teacher_id,
          id: data.class_id,
          type: data.activity_type,
          class_type: data.platform_type,
        }).toString();
      
        const fullPath = `https://studentscholars.com${basePath}?${queryParams}`;
        const encryptedURL = encryptURL(fullPath);
        return encryptedURL;
      };
      

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "70px"
        },
        {
            name: 'Type',
            selector: row => row.activity_type,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Category',
            selector: row => row.main_category,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Class Name',
            selector: row => row.activity_title,
            sortable: true,
            width: "300px"
        },
        {
            name: 'Teacher Name',
            selector: row => row.teacher_name,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Subscription Date',
            selector: row => {
                const date = new Date(row.created_at);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "180px"
        },
        {
            name: 'Unsubscription Date',
            selector: row => row.unsubcribe_date ? row.unsubcribe_date : '-',
            sortable: true,
            width: "180px"
        },
        {
            name: 'URL',
            cell: (row) => (
              <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                onClick={() => handleRedirect(row.teacher_id,row.activity_id, row.activity_type , row.platform_type)}
              >Click Here</button>
            ),
            sortable: true,
            width: "90px"
          },
    ]

    useEffect(() => {
        GetParentLearnerData()
    }, [search])

    const handleBack = () => {
        navigate(-1);
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </div>
                </header>

                <span onClick={handleBack} className="mx-4 my-3 mt-2">
                    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                    </svg>
                    <span className="ms-2">Back</span>
                </span>
                <>
                    <div className="fs-3 fw-500 ms-4 mb-2">
                        Learner Subscription Class Data
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                        <div className="row me-5">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome className="fa-solid fa-search" name="search" />
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                    </div>

                    <div className="px-5 mt-1">
                        {classData.length === 0 ? (
                            <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No Data Found</p>
                        ) : (
                            classData && classData.length > 0 && (
                                <DataTable
                                    persistTableHead
                                    columns={columns}
                                    data={classData}
                                    pagination
                                    customStyles={customStyles}
                                    className="custom-data-table"
                                />
                            )
                        )}
                    </div>
                </>
            </div>
        </main>
    </>
}