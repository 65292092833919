import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { AccessAccount, get_contactus_details } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { TOAST_ERROR } from "../../../utils/common.service";

export default function ContactUsDetails({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const PersonId = location.state && location.state.PersonId

    const GetPersonDetails = () => {
        isLoader(true)
        get_contactus_details({ id: PersonId }).then((response) => {
            console.log('response :', response);
            if (response.code == 1) {
                setData(response.data)
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        });
    }

    useEffect(() => {
        GetPersonDetails();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleReply = (id, email) => {
        navigate("/contactus_reply", { state: { PersonId: id, Email: email } })
    };

    const handleRedirect = (id, category, name) => {
        isLoader(true);
        AccessAccount({ user_id: id })
            .then((response) => {
                if (response.code === "1") {
                    isLoader(false);
                    localStorage.setItem("PAtoken", response.data[0]?.token);
                    localStorage.setItem("MAisLogin", true);
                    localStorage.setItem("userType", category);
                    localStorage.setItem("PAid", id);
                    localStorage.setItem("MAname", name);
                    localStorage.setItem("role","admin");

                    // Open the URL in a new tab
                    if (data?.url) {
                        window.open(data?.url, '_blank');
                    }

                } else {
                    isLoader(false);
                    TOAST_ERROR("ERROR..!!");
                }
            })
            .catch((error) => {
                TOAST_ERROR("ERROR..!!");
            });
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>

            </div>
        </header>

        <section className="main_sec">
            <div className="mx-5 mt-4">
                <div>
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>

                        <span className="ms-2">Back</span>
                    </span>
                </div>
                <p className="ms-3 fs-4 fw-500 text-center mb-5">Person Details</p>
                {isLoader && data?.length !== 0 ? (
                    <div className="row d-flex justify-content-center align-items-center mb-5">
                        <div className="col col-lg-6 mb-4 mb-lg-0">
                            <div className="card mb-3 p-3" style={{ borderRadius: ".5rem" }}>
                                <div className="row g-0">
                                    <div className="col-md-12">
                                        <div className="card-body p-4">
                                            <h6 className="mb-2">Information</h6>
                                            <hr className="mt-0 mb-4" />
                                            <div className="row pt-1">
                                                {data.name && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Name</p>
                                                        <p className="text-muted mt-2">{data?.name}</p>
                                                    </div>
                                                )}
                                                {data.category && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Role</p>
                                                        <p className="text-muted mt-2">
                                                            {data.category === "below_18" && "Parent"}
                                                            {data.category === "after_school" && "School - After School"}
                                                            {data.category === "above_18" && "Above 18 Learner"}
                                                            {data.category === "learner" && "Kids"}
                                                            {data.category}
                                                        </p>
                                                    </div>
                                                )}
                                                {data.email && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Email</p>
                                                        <p className="text-muted mt-2">{data?.email}</p>
                                                    </div>
                                                )}
                                                {data.code && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Code</p>
                                                        <p className="text-muted mt-2">{data?.code}</p>
                                                    </div>
                                                )}
                                                {data.reason && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Reason</p>
                                                        <p className="text-muted mt-2">{data?.reason}</p>
                                                    </div>
                                                )}
                                                {data.phone && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Phone</p>
                                                        <p className="text-muted mt-2">{data?.phone}</p>
                                                    </div>
                                                )}
                                                {data.country_code && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Country Code</p>
                                                        <p className="text-muted mt-2">{data?.country_code}</p>
                                                    </div>
                                                )}
                                                {data.country && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Country</p>
                                                        <p className="text-muted mt-2">{data?.country}</p>
                                                    </div>
                                                )}
                                                {data.time_zone && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Time Zone</p>
                                                        <p className="text-muted mt-2">{data?.time_zone}</p>
                                                    </div>
                                                )}
                                                {data.current_status && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Current Status</p>
                                                        <p className="text-muted mt-2">{data?.current_status}</p>
                                                    </div>
                                                )}
                                                {data.who_you_are && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Who Are You?</p>
                                                        <p className="text-muted mt-2">{data?.who_you_are}</p>
                                                    </div>
                                                )}
                                                {data.url && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Url</p>
                                                        <p className="text-purple mt-2" style={{ textDecoration: 'underline' }} onClick={() => handleRedirect(data?.user_id, data?.category, data?.name)}>Click Here to view classroom</p>
                                                    </div>
                                                )}

                                                {data.message && (
                                                    <div className="col-6 mb-3">
                                                        <p className="fw-600">Message</p>
                                                        <p className="text-muted mt-2">{data?.message}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {data?.reply_status == "1" ? (
                                    <div className="d-flex justify-content-center mb-3 mt-2">
                                        <button className="btn-theme px-4 fw-600">Already replied</button>
                                    </div>

                                ) : (
                                    <div className="d-flex justify-content-center mb-3 mt-2">
                                        <button onClick={() => handleReply(data?.id, data?.email)} className="btn-theme px-4 fw-600">
                                            Reply
                                        </button>
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>
                ) : (
                    null
                )}
            </div>
        </section>
    </>
}
