// // import AWS, { CloudFormation } from "aws-sdk";
// import AWS, { CloudFormation } from "aws-sdk";

// AWS.config.update({
//   region: 'ap-south-1',
//   // accessKeyId: 'AKIA3MEJ26PRZH3B2UGL',
//   // secretAccessKey: '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',
//   accessKeyId: "AKIA3MEJ26PR5TKQSMSN",
//   secretAccessKey: "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",
// });

// export const uploadImageOnAWS = async (file, folder) => {
  
//   // console.log(file,"10")
//   const s3 = new AWS.S3();
//   // console.log("file unique",file)
//   // console.log("file unique type",file.type)
//   // console.log("file unique name123",file.name)
//   const fileName = `${new Date().getTime()}${file.type?.split("/")?.[0] === "image" ? ".png" : ".mp4"}`;
//   // console.log(file.type?.split("/")?.[0],"14")
//   // console.log("fileName unique",fileName)
//   // console.log(file.type,"15")
//   const params = {
//     Bucket: 'hlink-bhavinp-s3',
//     Key: `student_scholar/${folder}/${fileName}`,
//     Body: file,
//     ACL: "public-read-write",
//   };
//   // console.log('params :', params);
  
//   try {
//     await s3.upload(params).promise();
//     // console.log("Filename",fileName)
//     return fileName;
//   } catch (error) {
//   // console.log('error in S3 upload :', error);
//     throw error;
//   }
// };


// import AWS, { CloudFormation } from "aws-sdk";
import AWS, { CloudFormation } from "aws-sdk";

// AWS.config.update({
//   region: "ap-south-1",
//   accessKeyId: "AKIA3MEJ26PR5TKQSMSN",
//   secretAccessKey: "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",
// });

AWS.config.update({
  region: "ca-central-1",
  accessKeyId: "AKIA2UC3AKXKMA4GUXES",
  secretAccessKey: "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",
});

export const uploadImageOnAWS = async (file, folder) => {
console.log('file :', file);
  // console.log(file);
  const s3 = new AWS.S3();
  const fileName = `${new Date().getTime()}${file.type?.split("/")?.[0] == "image" ? ".png" : file.type?.split("/")?.[0] == "video" ? ".mp4" : file?.name}`;
  const params = {
    // Bucket: "hlink-bhavinp-s3",
    Bucket: "studentscholars",    
    // Key: `student_scholar/${folder}/${fileName}`,
    Key: `${folder}/${fileName}`,
    Body: file,
    ACL: "public-read-write",
  };
  console.log('params :', params);
    
  try {
    await s3.upload(params).promise();
    console.log("Filename", fileName);
    return fileName;
  } catch (error) {
    console.log("error in S3 upload :", error);
    throw error;
  }
};  