import { add_class_country_list, admin_permission_detail, dashboardCountApi } from "../../api/apiHandler";
import Hdashboard from "../../components/Hdashboard";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import JqueryLoad from "../../JqueryLoad/JqueryLoad";
import FontAwesome from "react-fontawesome";
import { useSpring, animated } from "react-spring";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Select from 'react-select';
import * as XLSX from 'xlsx';
import generatePDF from "react-to-pdf";
import ReactCountryFlag from "react-country-flag";
import countryList from "react-select-country-list";
import { useForm } from "react-hook-form";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const options = {
    filename: "Dashboard.pdf",
    page: {
        margin: 20
    }
};
const getTargetElement = () => document.getElementById("download_reports");
const downloadPdf = () => {
    const excludedElements = document.querySelectorAll('.btn-theme');
    excludedElements.forEach(element => element.style.display = 'none');
    generatePDF(getTargetElement, options);
    excludedElements.forEach(element => element.style.display = '');
};

export default function Dashboard({ isLoader }) {
    const { register, setValue, clearErrors } = useForm();
    const [total, setTotal] = useState([]);
    const AdminId = localStorage.getItem("aID");
    const [permissions, setPermission] = useState([]);
    const role = localStorage.getItem("adminRole");
    const [countryName, setCountryName] = useState([]);

    const student_count = parseFloat(total.learner_count) + parseFloat(total.above_18_count);

    const [value, setCountry] = useState("");

    const options = useMemo(() => {
        return countryList().getData().map((country) => ({
            value: country.value,
            label: (
                <div>
                    <ReactCountryFlag
                        countryCode={country.value}
                        style={{ fontSize: "1.5em", marginRight: "5px" }}
                    />
                    {country.label}
                </div>
            ),
        }));
    }, []);

    const Number = ({ n }) => {
        const { number } = useSpring({
            from: { number: 0 },
            number: n,
            delay: 200,
            config: { mass: 1, tension: 20, friction: 10 },
        });
        return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>
    }

    const subAdminPermission = () => {
        admin_permission_detail({ id: AdminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([]);
                }
            })
            .catch((error) => {
                setPermission([]);
            });
    }

    useEffect(() => {
        if (role !== "S") {
            subAdminPermission();
        }
    }, [])

    const dashboardPermission = permissions.find(permission => permission.menu_name === "Dashboard");

    // Check if view is allowed for "Dashboard"
    const isViewAllowed = dashboardPermission && dashboardPermission.view === 1;

    //Filter Logic

    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [selectedOption, setSelectedOption] = useState('');

    const currentDate = new Date();
    const defaultStartDate = currentDate.toISOString().split('T')[0];
    const defaultStartMonth = currentDate.toISOString().slice(0, 7);

    let initialStartDate = defaultStartDate;
    let initialEndDate = defaultStartDate;

    if (selectedPeriod === 'daily') {
        initialStartDate = currentDate.toISOString().split('T')[0];
        initialEndDate = currentDate.toISOString().split('T')[0];
    } else if (selectedPeriod === 'weekly') {
        const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        initialStartDate = sevenDaysAgo.toISOString().split('T')[0];
        initialEndDate = currentDate.toISOString().split('T')[0];
    }

    // Set the state variables with the initial values
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);

    const [startMonth, setStartMonth] = useState(defaultStartMonth);
    const [endMonth, setEndMonth] = useState(defaultStartMonth);

    const [startYear, setStartYear] = useState(new Date().getFullYear());
    const [endYear, setEndYear] = useState(new Date().getFullYear());

    const navigate = useNavigate();

    useEffect(() => {
        fetchCount();
        countryListData();
    }, [selectedPeriod, startDate, endDate, startMonth, endMonth, startYear, endYear, value]);

    const fetchCount = () => {
        let requestData = {};

        if (selectedPeriod === 'daily') {
            requestData = {
                type: 'daily',
                startDate: startDate,
                endDate: endDate
            };
        } else if (selectedPeriod === 'weekly') {
            requestData = {
                type: 'weekly',
                startDate: startDate,
                endDate: endDate
            };
        } else if (selectedPeriod === 'monthly') {
            requestData = {
                type: 'monthly',
                startDate: startMonth,
                endDate: endMonth
            };
        } else if (selectedPeriod === 'yearly') {
            requestData = {
                type: 'yearly',
                startYear: startYear,
                endYear: endYear
            };
        }

        if (value && value.label && value.label.props && value.label.props.children) {
            let country_label = value.label.props.children[1];
            requestData.country = country_label;
        } else {
            requestData.country = "";
        }
        isLoader(true)
        dashboardCountApi(requestData)
            .then((response) => {
                if (response.code == 1) {
                    setTotal(response.data);
                    isLoader(false)
                } else {
                    setTotal([]);
                    isLoader(false)
                }
            })
            .catch((error) => {
                setTotal([]);
            });
    };

    const handlePeriodChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSelectedPeriod(selectedOption.value);

        // Update start and end dates immediately
        const currentDate = new Date();
        if (selectedOption.value === 'daily') {
            setStartDate(currentDate.toISOString().split('T')[0]);
            setEndDate(currentDate.toISOString().split('T')[0]);
        } else if (selectedOption.value === 'weekly') {
            const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
            setStartDate(sevenDaysAgo.toISOString().split('T')[0]);
            setEndDate(currentDate.toISOString().split('T')[0]);
        }
    };

    const handleStartMonthChange = (value) => {
        setStartMonth(value);
    };

    const handleEndMonthChange = (value) => {
        setEndMonth(value);
    };

    const getYearOptions = (minYear) => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = minYear; year <= currentYear; year++) {
            years.push(year);
        }
        return years;
    };

    const renderYearContent = (year) => {
        const tooltipText = `Tooltip for year: ${year}`;
        return <span title={tooltipText}>{year}</span>;
    };

    const getCurrentMonthYear = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
        return `${year}-${month}`;
    };

    const currentMonthYear = getCurrentMonthYear();

    const renderCustomHeader = ({
        date,
        changeYear,
        decreaseYear,
        increaseYear,
        prevYearButtonDisabled,
        nextYearButtonDisabled
    }) => (
        <div>
            <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                {'<'}
            </button>
            <span>{date.getFullYear()}</span>
            <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
                {'>'}
            </button>
        </div>
    );

    // Function to download reports in Excel format
    const downloadExcel = (data, selectedCountry = "", startDate = "", endDate = "", month = "", year = "", selectedPeriod = "") => {
        // Initialize the filteredData object with the common properties
        const filteredData = [{
            Sr_Number: 1,
            LearnerCount: data.learner_count,
            ParentCount: data.parent_count,
            Over18Count: data.above_18_count,
            School_AfterSchoolCount: data.after_school_count,
            TeacherCount: data.teacher_count,
            OrganizationCount: data.organization_count,
            SubjectCount: data.subject_count,
            Earnings: data.admin_earnings
        }];

        if (selectedCountry) {
            filteredData[0].Country = selectedCountry;
        }

        // Add conditional properties based on selectedPeriod
        if (selectedPeriod === "daily" || selectedPeriod === "weekly") {
            filteredData[0].StartDate = startDate;
            filteredData[0].EndDate = endDate;
        } else if (selectedPeriod === "monthly") {
            filteredData[0].Month = month;
        } else if (selectedPeriod === "yearly") {
            filteredData[0].Year = year;
        }

        // Create a new workbook and worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(filteredData);

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Write the workbook to a file
        XLSX.writeFile(workbook, 'dashboard_report.xlsx');
    };

    const countryListData = async () => {
        try {
            isLoader(true)
            const response = await add_class_country_list();
            console.log('response :', response);
            if (response?.code == 1) {
                isLoader(false)
                const transformedOptions = response?.data.map(country => ({
                    value: country.country_name,
                    id: country.country_id,
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                                style={{
                                    fontSize: '20px',
                                    marginRight: '8px'
                                }}
                            >
                                {country.country_image}
                            </span>
                            {country.country_name}
                        </div>
                    ),
                }));
                setCountryName(transformedOptions);
            } else {
                isLoader(false)
                // Handle the else case
            }
        } catch (error) {
            // Handle the error case
        }
    };

    return <>
        <JqueryLoad />
        <Hdashboard />
        {localStorage.getItem("AToken") && (
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <button className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </button>
                                <Link to="index.php" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </Link>
                            </div>
                        </div>
                    </header>

                    <div className="dashboard-content">
                        <div className="container-fluid">
                            <div className="row dashboard_part">
                                <div className="col-12 right">
                                    <div className="learn_static">
                                        {role === "S" || isViewAllowed ? (<>

                                            {/* <div className="title mt-4 mb-4">
                                                <p className="fs-3 fw-500">Dashboard</p>
                                            </div> */}

                                            <div className="d-flex justify-content-between title mt-4 mb-2">
                                                <p className="fs-3 fw-500">Dashboard</p>
                                                <div>
                                                    <button onClick={downloadPdf} className="btn-theme bg-yellow m-2">Download PDF</button>
                                                    <button className="btn-theme bg-yellow m-2" onClick={() => downloadExcel(total, value.label ? value.label.props.children[1] : "", startDate, endDate, selectedPeriod === 'monthly' ? `${startMonth} - ${endMonth}` : "", selectedPeriod === 'yearly' ? `${startYear} - ${endYear}` : "", selectedPeriod)}>Download Excel</button>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between mt-2 mb-5">
                                                <div className='col-md-2'>
                                                    <label className="mb-2 fw-600">Country :</label>
                                                    {/* <Select
                                                        {...register("country")}
                                                        options={options}
                                                        value={value}
                                                        onChange={(location) => {
                                                            setValue("country", location.value);
                                                            setCountry(location);
                                                            clearErrors("country");
                                                        }}
                                                        placeholder={"Select Country"}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                border: "1px solid rgba(102, 102, 102, 0.35)",
                                                                borderRadius: "12px",
                                                                padding: "5px 10px",
                                                                height: "46px",
                                                            }),
                                                        }}
                                                        name="country"
                                                    /> */}
                                                    <Select
                                                        {...register("country")}
                                                        options={countryName}
                                                        onChange={(location) => {
                                                            setValue("country", location.value);
                                                            setCountry(location);
                                                            clearErrors("country");
                                                        }}

                                                        placeholder={"Select Country"}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                border: "1px solid rgba(102, 102, 102, 0.35)",
                                                                borderRadius: "12px",
                                                                padding: "5px 10px",
                                                                height: "46px",
                                                            }),
                                                        }}
                                                        name="country"
                                                    />
                                                </div>

                                                <div className=' d-flex col-md-10 justify-content-end'>
                                                    <div className=' col-md-2'>
                                                        <label className="mb-2 fw-600">Select Time Period :</label>
                                                        <Select
                                                            options={[
                                                                { value: "daily", label: "Daily" },
                                                                { value: "weekly", label: "Weekly" },
                                                                { value: "monthly", label: "Monthly" },
                                                                { value: "yearly", label: "Yearly" },
                                                            ]}
                                                            className="skip_pdf"
                                                            id="period-select"
                                                            value={selectedOption}
                                                            onChange={handlePeriodChange}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    border: "1px solid rgba(102, 102, 102, 0.35)",
                                                                    borderRadius: "12px",
                                                                    padding: "5px 10px",
                                                                    height: "46px",
                                                                    width: "230px"
                                                                }),
                                                            }}
                                                            placeholder="Select Time Period"
                                                        />
                                                    </div>

                                                    <div className='col-md-5'>
                                                        {selectedPeriod === 'daily' && (
                                                            <div className="d-flex justify-content-end">
                                                                <div className='col-md-5 ms-4'>
                                                                    <label htmlFor="daily-start-date" className='mb-2 fw-600'>Start Date:</label>
                                                                    <input
                                                                        type="date"
                                                                        name="start_date"
                                                                        id="daily-start-date"
                                                                        className="form-control"
                                                                        required=""
                                                                        value={startDate}
                                                                        onChange={(e) => setStartDate(e.target.value)}
                                                                        style={{
                                                                            borderRadius: '12px',
                                                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                                                            padding: '10px',
                                                                            height: '46px',
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="col-md-5 ms-4">
                                                                    <label htmlFor="daily-end-date" className='mb-2 fw-600'>End Date:</label>
                                                                    <input
                                                                        type="date"
                                                                        name="end_date"
                                                                        id="daily-end-date"
                                                                        className="form-control"
                                                                        min={startDate}
                                                                        required=""
                                                                        value={endDate}
                                                                        onChange={(e) => setEndDate(e.target.value)}
                                                                        style={{
                                                                            borderRadius: '12px',
                                                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                                                            padding: '10px',
                                                                            height: '46px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {selectedPeriod === 'weekly' && (
                                                            <div className="d-flex justify-content-end">
                                                                <div className='col-md-5 ms-4'>
                                                                    <label htmlFor="weekly-start-date" className='mb-2 fw-600'>Start Date:</label>
                                                                    <input
                                                                        type="date"
                                                                        name="start_date"
                                                                        id="weekly-start-date"
                                                                        className="form-control"
                                                                        required=""
                                                                        value={startDate}
                                                                        onChange={(e) => {
                                                                            const selectedStartDate = e.target.value;
                                                                            const sevenDaysLater = new Date(new Date(selectedStartDate).getTime() + 7 * 24 * 60 * 60 * 1000);
                                                                            const formattedEndDate = sevenDaysLater.toISOString().split('T')[0];
                                                                            setStartDate(selectedStartDate);
                                                                            setEndDate(formattedEndDate);
                                                                        }}
                                                                        style={{
                                                                            borderRadius: '12px',
                                                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                                                            padding: '10px',
                                                                            height: '46px',
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className='col-md-5 ms-4'>
                                                                    <label htmlFor="weekly-end-date" className="mb-2 fw-600">End Date:</label>
                                                                    <input
                                                                        type="date"
                                                                        name="end_date"
                                                                        id="weekly-end-date"
                                                                        className="form-control"
                                                                        required=""
                                                                        value={endDate}
                                                                        onChange={(e) => {
                                                                            const selectedEndDate = e.target.value;
                                                                            const sevenDaysBefore = new Date(new Date(selectedEndDate).getTime() - 7 * 24 * 60 * 60 * 1000);
                                                                            const formattedStartDate = sevenDaysBefore.toISOString().split('T')[0];
                                                                            if (new Date(formattedStartDate) > new Date(startDate)) {
                                                                                setStartDate(formattedStartDate);
                                                                            }
                                                                            setEndDate(selectedEndDate);
                                                                        }}
                                                                        style={{
                                                                            borderRadius: '12px',
                                                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                                                            padding: '10px',
                                                                            height: '46px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {selectedPeriod === 'monthly' && (
                                                            <div className="d-flex justify-content-end">
                                                                <div className="col-md-5 ms-4">
                                                                    <label htmlFor="startMonthPicker" className="mb-2 fw-600">Start Month-Year:</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="month"
                                                                        id="startMonthPicker"
                                                                        value={startMonth}
                                                                        onChange={(e) => handleStartMonthChange(e.target.value)}
                                                                        max={currentMonthYear}
                                                                        style={{
                                                                            borderRadius: '12px',
                                                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                                                            padding: '10px',
                                                                            height: '46px',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-5 ms-4">
                                                                    <label htmlFor="endMonthPicker" className="mb-2 fw-600">End Month-Year:</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="month"
                                                                        id="endMonthPicker"
                                                                        min={startMonth}
                                                                        value={endMonth}
                                                                        onChange={(e) => handleEndMonthChange(e.target.value)}
                                                                        max={currentMonthYear}
                                                                        style={{
                                                                            borderRadius: '12px',
                                                                            border: "1px solid rgba(102, 102, 102, 0.35)",
                                                                            padding: '10px',
                                                                            height: '46px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {selectedPeriod === 'yearly' && (
                                                            <div className="d-flex justify-content-end">
                                                                <div className='col-md-5 ms-4'>
                                                                    <label htmlFor="yearPicker" className='mb-2 fw-600'>Start Year:</label>
                                                                    <DatePicker
                                                                        selected={new Date(startYear, 0, 1)}
                                                                        onChange={(date) => {
                                                                            const newStartYear = date.getFullYear();
                                                                            setStartYear(newStartYear);
                                                                            if (newStartYear > endYear) {
                                                                                setEndYear(newStartYear);
                                                                            }
                                                                        }}
                                                                        showYearPicker
                                                                        dateFormat="yyyy"
                                                                        renderCustomHeader={renderCustomHeader}
                                                                        className="form-control"
                                                                        wrapperClassName="date-picker-wrapper"
                                                                    />
                                                                </div>
                                                                <div className='col-md-5 ms-4'>
                                                                    <label htmlFor="yearPickerEnd" className="mb-2 fw-600">End Year:</label>
                                                                    <DatePicker
                                                                        selected={new Date(endYear, 0, 1)}
                                                                        onChange={(date) => {
                                                                            const newEndYear = date.getFullYear();
                                                                            setEndYear(newEndYear);
                                                                        }}
                                                                        showYearPicker
                                                                        dateFormat="yyyy"
                                                                        renderCustomHeader={renderCustomHeader}
                                                                        includeDates={getYearOptions(startYear).map(year => new Date(year, 0, 1))}
                                                                        className="form-control"
                                                                        wrapperClassName="date-picker-wrapper"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row px-2 mb-4" id="download_reports">
                                                <div className="col-lg-4 col-sm-6"
                                                // onClick={() => navigate("/student-parent")}
                                                >
                                                    <div className="widget-panel widget-style-2 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h2 className="m-0 counter font-600 personal_user start_number">
                                                                {/* <Number n={student_count} /> */}
                                                                {student_count ? student_count : 0}
                                                            </h2>
                                                            <div className="counter-text m-t-5 fs-5">Total Learners</div>
                                                        </div>

                                                        <div className="mx-2">
                                                            <FontAwesome name="user" className="fa-solid fa-user text-light fs-1" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-sm-6"
                                                // onClick={() => navigate("/student-parent")}
                                                >
                                                    <div className="widget-panel widget-style-2 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h2 className="m-0 counter font-600 personal_user start_number">
                                                                {/* <Number n={total.parent_count} /> */}
                                                                {total.parent_count}
                                                            </h2>
                                                            <div className="counter-text m-t-5 fs-5">Total Parents</div>
                                                        </div>

                                                        <div className="mx-2">
                                                            <FontAwesome name="user1" className="fa-solid fa-users text-light fs-1" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-sm-6"
                                                // onClick={() => navigate("/teacher")}
                                                >
                                                    <div className="widget-panel widget-style-2 d-flex align-items-center justify-content-between ">
                                                        <div>
                                                            <h2 className="m-0 counter font-600 business_user start_number">
                                                                {/* <Number n={total.teacher_count} /> */}
                                                                {total.teacher_count}
                                                            </h2>
                                                            <div className="counter-text m-t-5 fs-5">Total Teachers</div>
                                                        </div>
                                                        <div className="mx-2">
                                                            <FontAwesome name="user2" className="fa-solid fa-user text-light fs-1" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-sm-6"
                                                // onClick={() => navigate("/organization")}
                                                >
                                                    <div className="widget-panel widget-style-2 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h2 className="m-0  counter font-600 total_cards start_number">
                                                                {/* <Number n={total.organization_count} /> */}
                                                                {total.organization_count}
                                                            </h2>
                                                            <div className="counter-text m-t-5 fs-5">Total Organization</div>
                                                        </div>
                                                        <div className="mx-2">
                                                            <FontAwesome name="user3" className="fa-solid fa-graduation-cap text-light fs-1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr />

                                            <div className="row px-2 mt-5" id="download_reports">

                                                <div className="col-lg-4 col-sm-6"
                                                // onClick={() => navigate("/subject")}
                                                >
                                                    <div className="widget-panel widget-style-2 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h2 className="m-0  counter font-600 total_cards start_number">
                                                                {/* <Number n={total.subject_count} /> */}
                                                                {total.subject_count}
                                                            </h2>
                                                            <div className="counter-text m-t-5 fs-5">Total Subjects</div>
                                                        </div>

                                                        <div className="mx-2">
                                                            <FontAwesome name="user4" className="fa-solid fa-folder-open text-light fs-1" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-sm-6"
                                                //  onClick={() => navigate("/earnings")}
                                                >
                                                    <div className="widget-panel widget-style-2 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h2 className="m-0 counter font-600 total_cards start_number">
                                                                {/* ${<Number n={total.admin_earnings} />} */}
                                                                {total.admin_earnings ? `$${total.admin_earnings}` : '$0'}
                                                            </h2>
                                                            <div className="counter-text m-t-5 fs-5">Total Earnings</div>
                                                        </div>
                                                        <div className="mx-2">
                                                            <FontAwesome name="user5" className="fa fa-money text-light fs-1" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                        ) : (
                                            <div className="fs-2 fw-500 text-center mt-3" style={{ color: "rgb(87, 8, 97)" }}>
                                                Welcome to the StudentScholars admin panel.
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </main >
        )}
    </>
}