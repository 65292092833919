import { useNavigate, useLocation } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { update_faq } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import Select from 'react-select';

export default function EditFaq({ isLoader }) {

    const navigate = useNavigate();
    const location = useLocation();
    const FaqId = location.state && location.state.FaqId;
    const Title = location.state && location.state.Title;
    const Description = location.state && location.state.Description;
    const Panels = location.state && location.state.Panels;
    console.log('Panels :', Panels);

    const [selectAll, setSelectAll] = useState(false);
    const [selectedPanels, setSelectedPanels] = useState([]);

    const options = [
        { label: 'Landing Page', value: 'landing_page' },
        { label: 'Teacher', value: 'teacher' },
        { label: 'Organization', value: 'organization' },
        { label: 'Organization Teacher', value: 'org_teacher' },
        { label: 'Parents', value: 'below_18' },
        { label: 'Kids', value: 'learner' },
        { label: 'Over 18', value: 'above_18' },
        { label: 'Schools-After School Centers', value: 'after_school' }
    ];

    useEffect(() => {
        if (Panels && Panels.length > 0) {
            const selectedPanelNames = Panels.map(panel => panel.panel_name);
            setSelectedPanels(selectedPanelNames);
            faqForm.setFieldValue('panels', selectedPanelNames);
        }
    }, [Panels]);

    const handleSelectAllChange = (e) => {
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            const allPanels = options.map(option => option.value);
            setSelectedPanels(allPanels);
            faqForm.setFieldValue('panels', allPanels);
        } else {
            setSelectedPanels([]);
            faqForm.setFieldValue('panels', []);
        }
    };

    const handlePanelChange = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setSelectedPanels(selectedValues);
        faqForm.setFieldValue('panels', selectedValues);
        setSelectAll(selectedValues.length === options.length);
    };

    const faqSchema = Yup.object({
        title: Yup.string()
            .matches(/^[a-zA-Z?]+(?: [a-zA-Z?]+)*$/, 'Please enter a proper FAQ title')
            .required("Please enter FAQ title"),
        description: Yup.string().required("Please enter FAQ description"),
        panels: Yup.array().min(1, "Please select at least one panel").required("Please select at least one panel"),
    });

    const faqForm = useFormik({
        initialValues: {
            title: '',
            description: '',
            panels: []
        },
        validationSchema: faqSchema,
        onSubmit: async (values) => {
            const obj = {
                id: FaqId,
                title: values.title,
                description: values.description,
                panels: selectedPanels
            };
            isLoader(true);
            update_faq(obj).then((response) => {
                if (response.code == 1) {
                    TOAST_SUCCESS(response.message);
                    navigate('/faq');
                    isLoader(false);
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false);
                }
            });
        },
    });

    useEffect(() => {
        faqForm.setValues({
            title: Title,
            description: Description,
            panels: Panels ? Panels.map(panel => panel.panel_name) : []
        });
    }, [FaqId, Title, Description, Panels]);

    const handleBack = () => {
        navigate(-1);
    };

    const displayError = (fieldName) =>
        faqForm.touched[fieldName] && faqForm.errors[fieldName];

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="index.php" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="mx-5 mt-4">
                        <span onClick={handleBack} className="mb-5">
                            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313"></path>
                            </svg>
                            <span className="ms-2">Back</span>
                        </span>
                        <div className="text-center fs-3 fw-500 mt-4 ms-4 mb-1">
                            Edit FAQ
                        </div>
                        <div className="row align-items-center justify-content-center mt-5">
                            <div className="col-lg-8">
                                <div className="card border-purple p-4">
                                    <form onSubmit={faqForm.handleSubmit} className="custom_form mt-4">
                                        <div className="form-group mb-4">
                                            <label className="mb-2">Title:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                onChange={faqForm.handleChange}
                                                value={faqForm.values.title}
                                            />
                                            {displayError("title") && (
                                                <div className="text-danger">{faqForm.errors.title}</div>
                                            )}
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="mb-2">Description:</label>
                                            <textarea
                                                className="form-control"
                                                name="description"
                                                onChange={faqForm.handleChange}
                                                value={faqForm.values.description}
                                                rows={4}
                                            />
                                            {displayError("description") && (
                                                <div className="text-danger">{faqForm.errors.description}</div>
                                            )}
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="mb-2">In which side you want to show this FAQ:</label>
                                            {!selectAll && (
                                                <Select
                                                    options={options}
                                                    value={options.filter(option => selectedPanels.includes(option.value))}
                                                    className='mt-2'
                                                    onChange={handlePanelChange}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    placeholder="Select Panels..."
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            border: '1px solid rgba(102, 102, 102, 0.35)',
                                                            borderRadius: '12px',
                                                            padding: '5px 10px',
                                                            minHeight: '46px',
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                />
                                            )}
                                            {displayError("panels") && (
                                                <div className="text-danger">{faqForm.errors.panels}</div>
                                            )}
                                            {selectAll && (
                                                <input
                                                    type="text"
                                                    className="form-control py-2 mt-2"
                                                    name="select_all"
                                                    placeholder="All are selected"
                                                    readOnly
                                                    style={{ borderRadius: "12px" }}
                                                />
                                            )}
                                        </div>
                                        <div className="form-check mt-2">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="selectAll"
                                                checked={selectAll}
                                                onChange={handleSelectAllChange}
                                            />
                                            <label className="form-check-label text-secondary" htmlFor="selectAll">Select All Panels</label>
                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                            <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
