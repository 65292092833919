import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { formatDate } from '../../../utils/common.service';
import { new_leads } from '../../../api/apiHandler';
import RelativeTime from '../../../utils/RelativeTime';

export default function New_Leads({ CategoriesData, subCategoriesData, startDate, endDate, classSize, isLoader, search }) {
    const navigate = useNavigate();

    var [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const teacherNewLeads = () => {

        var search_item = {};
        if (CategoriesData && subCategoriesData?.[0]) {
            search_item.category_list = CategoriesData
            search_item.sub_category = subCategoriesData
        }
        if (startDate) {
            search_item.start_date = formatDate(startDate, 'DD-MM-YYYY')
        }
        if (endDate) {
            search_item.end_date = formatDate(endDate, 'DD-MM-YYYY')
        }
        if (classSize) {
            search_item.class_size = classSize
        }
        if (search) {
            search_item.search = search
        }
        search_item.page = currentPage;
        isLoader(true)

        new_leads(search_item).then((response) => {
            console.log('response :', response);
            if (response?.code == 1) {
                setData(response?.data?.result)
                setTotalPages(response?.data?.totalPages);
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        });
    }

    useEffect(() => {
        teacherNewLeads();
    }, [CategoriesData, subCategoriesData, startDate, endDate, classSize, search, currentPage]);

    const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            <div className="tab-pane fade show active" id="pills-new_leads" role="tabpanel" aria-labelledby="pills-new_leads-tab">
                <div className="row">
                    <div className="col-xl-9">
                        <div className="lead_request">
                            {data?.length > 0 ?
                                data?.map((leads_data, index) => {
                                    return (
                                        <div className="card">
                                            <a className="lead_req_img d-flex position-relative align-items-center">
                                                {/* <img src="./assets/images/user/user.png" alt="user" /> */}
                                                <img className="rounded-circle" src={leads_data.profile_image} alt="user" />
                                                <div className="ms-3">
                                                    <span className="fw-600">{leads_data.name}</span>
                                                    {/* {leads_data?.is_read == 0 ?
                                                        <span className="ms-3 green_dots position-absolute" /> : <></>
                                                    } */}
                                                </div>
                                            </a>
                                            <a className="d-md-flex mb-3 align-items-center justify-content-between">
                                                <ul className="mb-md-0 mb-4">
                                                    <li className="mb-2">
                                                        <span className="font-12">
                                                            <span>

                                                            </span>
                                                            <span className="fw-500 font-14" style={{ color: '#570861' }}>Learner’s Country :
                                                                <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.country_name}</span>
                                                            </span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14" style={{ color: '#570861' }}>Learner’s Local Time :
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.local_time}</span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14" style={{ color: '#570861' }}>Requested Categories :
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.category_list}</span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14" style={{ color: '#570861' }}>Requested Subjects :
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.subcategory_list}</span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14" style={{ color: '#570861' }}>Class Size :
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.class_size == "Individual/private" ? "1-on-1 Private Lesson" : "Group Lesson"}</span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14" style={{ color: '#570861' }}>Frequency of Lessons :
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.want_lessons}</span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14" style={{ color: '#570861' }}>Number of times requested:
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.times_requested}</span>
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        <span className="fw-500 font-14 underline" style={{ color: '#570861', cursor: "pointer" }} onClick={() => navigate("/new_leads_class_details", { state: { user_id: leads_data.user_id } })}>Number of times actually subscribed :
                                                            <span className="fw-500 text-dif-black font-14">&nbsp;{leads_data.actually_subscribed}</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div className="num_of_teach text-end">
                                                    <span className="mb-3 d-block underline" style={{ fontSize: '-3.75rem !important' }} onClick={()=> navigate("/responded_teacher_list" , {state : {request_class_id : leads_data.id}})}>Number Of Teachers Have Responded.</span>

                                                    <ul className="d-flex align-items-center justify-content-end">
                                                        {[...Array(5)].map((_, index) => (
                                                            <li key={index} className={index < leads_data.Respond_teacher ? 'active' : ''} />
                                                        ))}
                                                        <span className="ms-3 fw-600">{leads_data.Respond_teacher}/5</span>
                                                    </ul>
                                                </div>
                                            </a>
                                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                                                <div className="button d-sm-flex align-items-center">
                                                    <Link to="/new_leads_details" state={{ id: leads_data.id }} className="discussion_btn btn-theme px-3 d-inline-block text-center font-14">View Details</Link>
                                                </div>
                                                <div className="list_btw_dots mb-xl-0 mb-4">
                                                    <ul className="d-sm-flex align-items-center">
                                                        <span style={{ color: "#131313 !important" }} className='fw-500'>Requested on :</span>&nbsp;<span className="text-purple fw-600 font-14"> {leads_data.updated_date}</span>
                                                        <li>
                                                            <span>{leads_data.formatted_date}</span><span className="mx-1"> <RelativeTime dateString={leads_data.created_at} /> </span>
                                                        </li>
                                                        <li>
                                                            <span className="text-purple fw-600 font-14">{leads_data.formate_date}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) :
                                <h4 className="d-flex justify-content-center mb-5 mt-5" style={{ "color": "#570861" }}>Data Not Found</h4>
                            }           
                        </div>

                        <div className="custom_pagenation mb-5 mt-1">
                            <ul className="d-flex align-items-center justify-content-center">
                                <li className={`circle ${currentPage === 1 ? 'disabled' : ''}`} onClick={handlePreviousPage}>
                                    <Link className="text-purple font-w" onClick={handlePreviousPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M11.354 12.354a.5.5 0 0 1-.708 0L5.5 7.707l5.146-5.147a.5.5 0 0 1 .708.708L6.707 7.5l4.647 4.646a.5.5 0 0 1 0 .708z" />
                                        </svg>
                                    </Link>
                                </li>
                                {[...Array(totalPages).keys()].map((pageNumber) => (
                                    <li key={pageNumber} className={`circle ${pageNumber + 1 === currentPage ? 'active' : ''}`} onClick={() => handlePaginationClick(pageNumber + 1)}>
                                        <Link className="text-purple font-w" onClick={() => handlePaginationClick(pageNumber + 1)}>
                                            {pageNumber + 1}
                                        </Link>
                                    </li>
                                ))}
                                <li className={`circle ${currentPage === totalPages ? 'disabled' : ''}`} onClick={handleNextPage}>
                                    <Link className="text-purple font-w" onClick={handleNextPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4.646 3.646a.5.5 0 0 1 .708 0L10.5 9.293l-5.146 5.147a.5.5 0 0 1-.708-.708L9.293 9.5 4.646 4.854a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
