import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { CoupounList, get_contactus_list } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import { Link } from "react-router-dom";
import customStyles from "../../../utils/dataTableStyles";

export default function Coupons({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [search, setSearch] = useState('');

    const handleGetData = () => {
        isLoader(true)
        CoupounList({ search: search }).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    const handleView = (id) => {
        navigate('/coupons_details', { state: { coupan_id: id } });
    }

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "100px"
        },
        {
            name: 'Created Date',
            selector: row => {
                const date = new Date(row.created_at);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "170px"
        },
        {
            name: 'Id Code',
            selector: row => row.code_generate,
            sortable: true,
            width: "130px",
            cell: row => row.code_generate == null ? "0000" : row.code_generate
        },
        {
            name: 'Role',
            selector: row => row.category,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Full Name',
            selector: row => row.teacher_name,
            sortable: true,
            width: "210px"
        },
        {
            name: 'Coupon Name',
            selector: row => row.coupan_name,
            sortable: true,
            width: "220px"
        },
        {
            name: 'Coupon Number',
            selector: row => row.coupan_number,
            sortable: true,
            width: "210px"
        },
        {
            name: 'Amount Percentage',
            selector: row => row.amount_percentages ? row.amount_percentages : "-",
            sortable: true,
            width: "180px"
        },
        {
            name: 'View Details',
            cell: (row) => (
                <div className="col me-2">
                    <FontAwesome
                        name="view"
                        className="fs-5 fa-solid fa-eye  mx-2 my-2 bg-light text-secondary"
                        onClick={() => handleView(row.id)}
                    ></FontAwesome>
                </div>
            ),
            sortable: true,
            width: "110px"
        }
    ]

    useEffect(() => {
        handleGetData()
    }, [search])

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <Link className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </Link>
                            <Link to="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </Link>
                        </div>
                    </div>
                </header>
                <>
                    <div className="fs-3 fw-500 mt-4 ms-4">
                        Coupons List
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                        <div className="row me-5">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome className="fa-solid fa-search" name="search" />
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                    </div>
                    <div className="px-5 mt-1">
                        <div>
                            {(
                                data && data.length > 0 ? (
                                    <DataTable title="" columns={columns} data={data} pagination customStyles={customStyles} />
                                ) : (
                                    <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                                )
                            )}
                        </div>
                    </div>
                </>
            </div>
        </main>
    </>
}