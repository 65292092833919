import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { get_contactus_list } from "../../../api/apiHandler";
import DataTable from "react-data-table-component";
import FontAwesome from "react-fontawesome";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import { Link } from "react-router-dom";
import customStyles from "../../../utils/dataTableStyles";

export default function ContactUs({isLoader}) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [search, setSearch] = useState('');

    const handleGetData = () => {
        isLoader(true)
        get_contactus_list({ search: search }).then((response) => {
            if (response.code == 1) {
                const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                setData(dataWithRowCount);
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    const handleContactUsDetails = (PersonId) => {
        navigate('/contactus-details', { state: { PersonId: PersonId } })
    }

    const columns = [
        {
            name: 'No',
            selector: row => row.count,
            sortable: false,
            width: "100px"
          },
        {
            name: 'Id Code',
            selector: row => row.code,
            sortable: true,
            width: "150px",
            cell: row => (
                <span className={`${row.reply_status === 0 ? 'fw-bold' : ''} ${row.current_status === "New to StudentScholars" ? 'text-purple' : ''}`}>
                    {row.code == null ? "0000" : row.code}
                </span>
            ),
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "200px",
            cell: row => (
                <span
                    className={`${row.reply_status === 0 ? 'fw-bold' : ''} ${row.current_status === "New to StudentScholars" ? 'text-purple' : ''}`}
                >
                    {row.name}
                </span>
            )
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "270px",
            cell: row => (
                <span className={`${row.reply_status === 0 ? 'fw-bold' : ''} ${row.current_status === "New to StudentScholars" ? 'text-purple' : ''}`}>
                    {row.email}
                </span>
            )
        },
        {
            name: 'Phone',
            width : "220px",
            selector: row => row.country_code + row.phone,
            sortable: true,
            cell: row => (
                <span className={`${row.reply_status === 0 ? 'fw-bold' : ''} ${row.current_status === "New to StudentScholars" ? 'text-purple' : ''}`}>
                    {row.country_code + row.phone ? row.country_code + row.phone : '-'}
                </span>
            )
        },
        {
            name: 'Current Status',
            width : "270px",
            selector: row => row.current_status,
            sortable: true,
            cell: row => (
                <span className={`${row.reply_status === 0 ? 'fw-bold' : ''} ${row.current_status === "New to StudentScholars" ? 'text-purple' : ''}`}>
                    {row.current_status}
                </span>
            )
        },
        {
            name: 'Status of Message',
            width : "180px",
            cell: row => (
                <span className={`${row.reply_status === 0 ? 'fw-bold' : ''} ${row.current_status === "New to StudentScholars" ? 'text-purple' : ''}`}>
                    {row.reply_status === 1 ? 'Replied' : 'New'}
                </span>
            ),
            sortable: true,
        },
        {
            name: 'Actions',
            width : "100px",
            cell: (row) => (
                <div className="d-flex justify-content-center">
                    <div className="col me-2">
                        <FontAwesome
                            name="view"
                            className="fs-5 fa-solid fa-eye  mx-2 my-2 bg-light text-secondary"
                            onClick={() => handleContactUsDetails(row.id)}
                        ></FontAwesome>
                    </div>
                </div>
            ),
        }
    ]

    useEffect(() => {
        handleGetData()
    }, [search])

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <Link className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </Link>
                            <Link to="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </Link>
                        </div>
                    </div>
                </header>
                <>
                    <div className="fs-3 fw-500 mt-4 ms-4">
                        Contact Us List
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                        <div className="row me-5">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome className="fa-solid fa-search" name="search" />
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                    </div>
                    <div className="px-5 mt-1">
                        <div>
                            {(
                                data && data.length > 0 ? (
                                    <DataTable title="" columns={columns} data={data} pagination customStyles={customStyles}/>
                                ) : (
                                    <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                                )
                            )}
                        </div>
                    </div>
                </>
            </div>
        </main>
    </>
}