import { useLocation, useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useRef, useState } from "react";
import { sendComment, teacherApplications, teacherStepAccept } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";

export default function TApplication({ isLoader }) {

    const [data, setData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const teacherID = location.state && location.state.tID;
    const name = location.state && location.state.name

    const GetTApplicationData = () => {
        isLoader(true)
        teacherApplications({ teacher_id: teacherID }).then((response) => {
            console.log('response :', response);
            if (response.code == 1) {
                setData(response.data[0])
                isLoader(false)
            } else {
                setData([])
                isLoader(false)
            }
        })
    }

    useEffect(() => {
        GetTApplicationData();
    }, [teacherID]);

    const handleBack = () => {
        navigate(-1);
    };

    const handleAccpet = () => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to accept teacher application?",
            showCancelButton: true,
            confirmButtonText: "Accept",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objAccept = {
                        teacher_id: teacherID,
                        email: data.email,
                        accept: "2",
                        step: "1",
                        accept_for: "1",
                        teacher_name: name
                    };
                    isLoader(true)
                    teacherStepAccept(objAccept).then((response) => {
                        if (response.code == 1) {
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, step_1: "2" }));
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!");
                }
            }
        });
    }

    const handleReject = () => {

        Swal.fire({
            icon: "warning",
            title: "Are you sure you want to reject teacher application?",
            showCancelButton: true,
            confirmButtonText: "Reject",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    var objReject = {
                        teacher_id: teacherID,
                        email: data.email,
                        accept: "3",
                        step: "1",
                        accept_for: "1",
                        teacher_name: name
                    };
                    isLoader(true)
                    teacherStepAccept(objReject).then((response) => {
                        if (response.code == 1) {
                            TOAST_SUCCESS(response.message);
                            setData(prevData => ({ ...prevData, step_1: "3" }));
                            isLoader(false)
                        } else {
                            TOAST_ERROR(response.message);
                            isLoader(false)
                        }
                    });
                } catch (error) {
                    TOAST_ERROR("Error..!!");
                }
            }
        });
    }

    const fileInputs = useRef([]);
    const resetFileInputs = () => {
        fileInputs.current.forEach((input) => {
            input.value = '';
        });
    };

    const commentSchema = Yup.object({
        comment: Yup.string()
    });

    const Email = data.email;
    const commentForm = useFormik({
        initialValues: {
            comment: '',
        },
        validationSchema: commentSchema,

        onSubmit: async (data) => {
            data.id = teacherID;
            data.email = Email;
            data.comment_for = 1; //comment_for 1 = teacher , 2 = organization , 3 = organization_teacher
            data.step = 1;

            isLoader(true)
            sendComment(data).then((response) => {
                if (response.code == 1) {
                    TOAST_SUCCESS(response.message);
                    commentForm.resetForm();
                    resetFileInputs();
                    GetTApplicationData();
                    isLoader(false)
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            });
        },
    });

    const displayError = (fieldName) =>
        commentForm.touched[fieldName] && commentForm.errors[fieldName];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </header>
        <section className="main_sec">
            <div className="mt-4 mx-5 mb-5">
                <div>
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                </div>
                {(Object.keys(data).length > 0 ? (
                    <>
                        <p className="ms-3 fs-3 fw-500 text-center mb-3">Teacher Application</p>
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col col-lg-8 mb-4 mb-lg-0">
                                <div className="card mb-3 p-3" style={{ borderRadius: ".5rem" }}>
                                    <div className="row g-0">
                                        <div className="col-md-12">
                                            <div className="card-body p-4">
                                                <p className="mb-2 fs-5 fw-600">Application</p>
                                                <hr className="mt-0 mb-4" />
                                                <div className="row pt-1">
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Legal Name</p>
                                                        <p className="text-muted mt-1">{data.legal_name}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Public Name</p>
                                                        <p className="text-muted mt-1">{data.public_name}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Email</p>
                                                        <p className="text-muted mt-1">{data.email}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Country</p>
                                                        <p className="text-muted mt-1">{data.country}</p>
                                                    </div>
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Phone</p>
                                                        <p className="text-muted mt-1">{data.phone_no}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">How proficient are you in English language?</p>
                                                        <p className="text-muted mt-1">
                                                            {data.proficiency == "low"
                                                                ? "Low - Can understand, difficulty writing or reading English"
                                                                : data.proficiency == "moderate"
                                                                    ? "Moderate - Can understand, speak, read and write English"
                                                                    : "Fluent - Native English speaker"}
                                                        </p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Please give us an elevator speech of your accomplishments. It should include experience you have in teaching or working with youth, whether as a professional, a volunteer, or in your personal life </p>
                                                        <p className="text-muted mt-1">{data.elecator_speech}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Please list any academic degrees, teaching credentials (current or expired), professional training, or other relevant professional certifications you hold. Include anything that will help us learn more about your background. Please include Year, Subject, and Institution.</p>
                                                        <p className="text-muted mt-1">{data.academy_degree}</p>
                                                    </div>
                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">How many years of teaching experience do you have? You need a minimum of 2 years experience to apply.</p>
                                                        <p className="text-muted mt-1">{data.teaching_exp}</p>
                                                    </div>
                                                    
                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Teaching Resume</p>
                                                        {data.teacher_resume && (
                                                            <a
                                                                href={data.teacher_resume}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-purple underline mt-1"
                                                            >
                                                                View Document
                                                            </a>
                                                        )}
                                                    </div>

                                                    {data.social_profile_link && (
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500"> (Optional) Online profiles: do you have a professional website, Facebook page, or LinkedIn profile that helps to describe your experience and expertise? Share it with us here!</p>
                                                            <p className="text-muted mt-1">{data.social_profile_link}</p>
                                                        </div>
                                                    )}

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Please Upload any certificates of your education and professional qualifications. </p>
                                                        {data.teacher_certificate && data.teacher_certificate.length > 0 ? (
                                                            data.teacher_certificate.map((certificate, index) => (
                                                                <div key={certificate.id} className="mt-1">
                                                                    <a href={certificate.teacher_certificate} className="text-purple underline" target="_blank" rel="noopener noreferrer">
                                                                        View Document {index + 1}
                                                                    </a>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p>No certificates available.</p>
                                                        )}
                                                    </div>

                                                    {data.teacher_add_material && (
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500"> Optional : Any additional material you would like to send us relating to your teaching experience</p>
                                                            {data.teacher_add_material && (
                                                                <a href={data.teacher_add_material} className="text-purple underline mt-1">View Document</a>
                                                            )}
                                                        </div>
                                                    )}

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">How many classes would you like to teach on our platform?</p>
                                                        <p className="text-muted mt-1">{data.class_statistics}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500"> Teachers create their own classes. What topics are you passionate about teaching? Which ages of learners do you want to work with? What experience or expertise do you have in these subject areas? It’s fine to share high-level topics here (no need to have the perfect class title).</p>
                                                        <p className="text-muted mt-1">{data.topic_about}</p>
                                                    </div>

                                                    {data.country == "Canada" && (
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500">Is your annual self-employed income over CAD $30,000 ?</p>
                                                            <p className="text-muted mt-1">{data.above_earning_30}</p>
                                                        </div>
                                                    )}

                                                    {data.above_earning_30 == "yes" && (
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500">Is your company required to have a GST/HST number?</p>
                                                            <p className="text-muted mt-1">{data.company_required_gst_hst}</p>
                                                        </div>
                                                    )}

                                                    {data.company_required_gst_hst == "yes" && (
                                                        <>
                                                            <div className="col-12 mb-4">
                                                                <p className="fw-500"> GST/HST number</p>
                                                                <p className="text-muted mt-1">{data.gst_hst_number}</p>
                                                            </div>

                                                            <div className="col-12 mb-4">
                                                                <p className="fw-500"> Which Province are you residing in?</p>
                                                                <p className="text-muted mt-1">{data.province}</p>
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Record a 2-3 minute video where you teach a sample of the class you described above. This video will stay private and will not be visible to families. It’s a way for our team to get a quick read on who you are and the kind of teaching environment you’ll provide to our learners. You should: Show us (and show off!) the space where you will teach. Show us your teaching personality. Share your expertise! Teach us something that you are excited to share with learners.</p>
                                                        {data.teacher_sample_video && (
                                                            <a href={data.teacher_sample_video} className="text-purple underline mt-2" >View Video</a>
                                                        )}
                                                    </div>

                                                    {data.additional_info && (
                                                        <div className="col-12 mb-4">
                                                            <p className="fw-500">Optional : Any additional information you would like us to know</p>
                                                            <p className="text-muted mt-1">{data.additional_info}</p>
                                                        </div>
                                                    )}

                                                    <div className="col-4 mb-4">
                                                        <p className="fw-500">Full Name</p>
                                                        <p className="text-muted mt-1">{data.first_name}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Your Country of Residence :</p>
                                                        <p className="text-muted mt-1">{data.country_residence}</p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Date : </p>
                                                        <p className="text-muted mt-1">
                                                            {new Date(data.updated_at).toLocaleDateString('en-GB')}
                                                        </p>
                                                    </div>

                                                    <div className="col-12 mb-4">
                                                        <p className="fw-500">Admin Comments</p>
                                                        <p className="text-muted mt-1">{data.comments}</p>
                                                    </div>
                                                </div>

                                                <form className="custom_form mt-3" name="commentForm" onSubmit={commentForm.handleSubmit}>
                                                    <div className="form-group mb-4">
                                                        <p className="fw-500 mb-2">Any comments you have, please add them here for the teacher.</p>
                                                        <textarea type="text" className="form-control" name="comment" value={commentForm.values.comment} rows="5" cols="33" onChange={commentForm.handleChange} ></textarea>
                                                        {displayError("comment") && (
                                                            <div className="text-danger">{commentForm.errors.comment}</div>
                                                        )}
                                                        <div className="button mt-2 mb-2 d-flex justify-content-end">
                                                            <button type="submit" className="btn-theme px-4 py-2  text-center text-dif-black fw-500 bg-yellow mb-xl-0 mb-3 d-inline-block">Send</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                {(data.step_1 == 0 || data.step_1 == 1) &&

                                                    <div className="d-flex justify-content-center mt-3">
                                                        <button className="btn-theme bg-yellow mx-1" onClick={() => handleAccpet()}>
                                                            {data.step_1 == "2" ? "Accpted" : "Accept"}
                                                        </button>
                                                        <button className="btn-theme mx-1" onClick={() => handleReject()}>
                                                            {data.step_1 == "3" ? "Rejected" : "Reject"}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <p className="text-center fs-5 fw-500 text-danger mt-5">The Teacher has not filled out the application form.</p>
                    </div>
                )
                )}

            </div>
        </section>
    </>
}

