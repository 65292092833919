import { useNavigate, useLocation } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useRef, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS, convertToBase64 } from "../../../utils/common.service";
import { uploadImageOnAWS } from "../../aws/Service";
import { add_faq_video } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";

export default function AddVideo({isLoader}) {

    const navigate = useNavigate()
    const fileRef = useRef();
    const [image, setImage] = useState(null);
    const AdminId = localStorage.getItem("aID")
    const location = useLocation()
    const FaqId = location.state && location.state.FaqId
    
    const videoSchema = Yup.object({
        title: Yup.string()
        .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, 'Please enter proper video title')
        .required("Please enter video title"),
        media: Yup.mixed().test('fileType', 'Invalid file format, only videos are allowed', function (value) {
            if (!value) return true;  // If no file is provided, it's considered valid
            return value && value.type.startsWith('video/');
        }).required("Please upload video"),
        description: Yup.string().required("Please enter video description"),
    });

    const videoForm = useFormik({
        initialValues: {
            title: '',
            media: null,
            description: '',
        },
        validationSchema: videoSchema,
        onSubmit: async (values) => {

            if (values.media != null) {
                var video = await uploadImageOnAWS(values.media, "training_videos");
            }
            var obj = {
                title: values.title,
                description: values.description,
                media_type: 'video',
                admin_id: AdminId,
                faq_id: FaqId,
            };
            if (values.media !== null) {
                obj.media = video;
            }
            isLoader(true)
            add_faq_video(obj).then((response) => {
                if (response.code == 1) {
                    TOAST_SUCCESS(response.message);
                    navigate('/video', { state : FaqId  })
                    isLoader(false)
                } else {
                    TOAST_ERROR(response.message);
                    isLoader(false)
                }
            });
        }
    });

    const uploadImage = () => {
        fileRef.current.click();
        videoForm.setFieldValue('media', null);
        if (image) {
            setImage(null)
        }
    };

    const uploadImageEvent = async (e) => {
        const bufferImage = await convertToBase64(e.target.files[0]);
        setImage(bufferImage);
        videoForm.setFieldValue('media', e.target.files[0]);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const displayError = (fieldName) =>
        videoForm.touched[fieldName] && videoForm.errors[fieldName];

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>

                    </div>
                </header>
                <div className="mx-5 mt-4">

                    <span onClick={handleBack} className="mb-5">
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>

                        <span className="ms-2">Back</span>
                    </span>
                    <h2 className="d-flex justify-content-center"> Add Video</h2>
                    <div className="row align-items-center justify-content-center mt-5">
                        <div className="col-lg-8">
                            <div className="card border-purple p-4" >

                                <form onSubmit={videoForm.handleSubmit} className="custom_form mt-4">

                                    <div className="d-flex justify-content-center">

                                        <span onClick={uploadImage}>
                                            {image !== null ? (
                                                <video controls height="200px" width="200px" className="card border-purple p-2">
                                                    <source src={image} type="video/mp4" accept="video/*" />
                                                    {/* Your browser does not support the video tag. */}
                                                </video>
                                            ) : (
                                                <>
                                                    <img
                                                        src={image === null ? "./assets/images/image_upload.png" : image}
                                                        alt=""
                                                        className="img img-responsive card border-purple p-2"
                                                        height={"100px"}
                                                        width={"100px"}
                                                        accept="image/*"
                                                        style={{ borderRadius: "50%" }}
                                                    /><br /></>
                                            )}

                                        </span>

                                        <input
                                            type="file"
                                            ref={fileRef}
                                            className="form-control d-none"
                                            name="media"
                                            accept={'video/mp4'}
                                            onChange={uploadImageEvent}
                                        />

                                    </div>
                                    {displayError("media") && (

                                        <div className="text-danger d-flex justify-content-center">{videoForm.errors.media}</div>
                                    )}
                                    <div className="form-group mb-4">
                                        <label className="mb-2">Title:</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="title"
                                            onChange={videoForm.handleChange}
                                            // onBlur={videoForm.handleBlur}
                                            value={videoForm.values.title}
                                        />
                                        {displayError("title") && (
                                            <div className="text-danger">{videoForm.errors.title}</div>
                                        )}
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="mb-2">Description:</label>
                                        <textarea
                                            className="form-control"
                                            name="description"
                                            onChange={videoForm.handleChange}
                                            value={videoForm.values.description}
                                        />
                                        {displayError("description") && (
                                            <div className="text-danger">{videoForm.errors.description}</div>
                                        )}
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                        <button type="submit" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>
}