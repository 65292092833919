import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { notificationListing } from '../../../api/apiHandler';
import { Helmet } from 'react-helmet';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import dayjs from 'dayjs';
// import { useNotification } from "../../../NotificationContext"
import Hdashboard from '../../../components/Hdashboard';

export default function Notification({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // const { updateNotificationCount } = useNotification()

  const navigate = useNavigate();

  const [notificationData, setNotificationData] = useState([]);
  console.log('notificationData :', notificationData);

  // const NotificationList = async () => {
  //   try {
  //     isLoader(true)
  //     const response = await notificationListing();
  //     if (response?.code == "1") {
  //       console.log('response :', response);
  //       setNotificationData(response.data)
  //       document.getElementById('notification_span').click()
  //       isLoader(false)
  //       // updateNotificationCount(0)
  //     } else {
  //       setNotificationData([])
  //       isLoader(false)
  //     }
  //   } catch (error) {
  //     isLoader(false)
  //     console.log('error :', error);
  //   }
  // }

  const NotificationList = () => {
    isLoader(true)
    notificationListing().then((response) => {
      console.log('response :', response);
      if (response.code == 1) {
        isLoader(false)
        setNotificationData(response.data)
        document.getElementById('notification_span').click()
      } else {
        setNotificationData([])
        isLoader(false)
      }
    })
  }

  useEffect(() => {
    NotificationList();
  }, [])

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <>
      <JqueryLoad />
      <Hdashboard />
      <Helmet>
        <style>
          {`
                                        .Calendar {
                                          --cl-color-black: #444444;
                                          --cl-color-disabled: #d4d4d4;
                                          --cl-color-error: #ff2929;
                                          font-size: 10px;
                                          background: #fff;
                                          box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07);
                                          border-radius: 1em;
                                          position: relative;
                                          -webkit-user-select: none;
                                          user-select: none;
                                          padding-top: 1.2em;
                                          display: flex;
                                          flex-direction: column;
                                          width: 41em !important;
                                          z-index: 10;
                                          max-width: 90vw;
                                          min-height: 36.7em;
                                      }
                                    .drop_calendar.filter_box {
                                        min-width: 523px;
                                    }
                                    .Calendar__weekDays {
                                        color : #570861
                                    }
                                    .Calendar__day.-selected, .Calendar__day.-selectedStart, .Calendar__day.-selectedEnd {
                                        background: #FFC924;
                                        color: #212529;
                                    }
                                    .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
                                        color: #212529;
                                    }
                                    .Calendar__day.-selectedBetween {
                                        background: rgba(241, 241, 241, 0.65);
                                        color: #060706;
                                        border-radius: 0
                                    }
                                    .scrollable-content {
                                      max-height: 200px; /* Adjust the height as needed */
                                      overflow-y: auto;
                                    }    

                                    .scrollable-content::-webkit-scrollbar {
                                      width: 5px; /* Width of the scrollbar */
                                    }
                                    
                                    .scrollable-content::-webkit-scrollbar-thumb {
                                      background-color: #570861; /* Color of the thumb */
                                      border-radius: 10px; /* Rounded corners for the thumb */
                                    }
                                    
                                    .scrollable-content::-webkit-scrollbar-track {
                                      background-color: #f0f0f0; /* Color of the track */
                                    }

                                    .off_box.card .off_bg_img img.off_user {
                                      position: absolute;
                                      bottom: -22px;
                                      left: 20px;
                                      width: 65px;
                                      height: 65px;
                                      object-fit: cover;
                                      object-position: center;
                                      border-radius: 50%;
                                    }
                                                                      
                                    .highlight-date {
                                      font-weight: bolder;
                                      color: #570861 !important;
                                    }

                                    .Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText, .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
                                      background-color: #570861;
                                      color: #fff;
                                  }
                       
                                    `}
        </style>
      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <a className="menu-toggle align-items-center d-lg-none d-flex">
                <i className="fa fa-bars" />
              </a>
              <a href="index.php" className="d-lg-none d-block brand-logo">
                <img src="./assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </header>

          <span onClick={handleBack} className="mt-3 ms-4">
            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
            </svg>
            <span className="ms-2">Back</span>
          </span>

          <div className="my-3 mx-2">
            <div className="container-fluid">
              <div className="row dashboard_part">
                <div className="col-xl-12 right">
                  <div className="d-flex justify-content-between title mb-2 mt-2 ms-4">
                    <p className="fs-3 fw-500">Notifications</p>
                    <button onClick={() => navigate("/send_notifications")} className="btn-theme bg-yellow mx-4">Send Notifications</button>
                  </div>

                  {Array.isArray(notificationData) && notificationData.map((dateItem, index) => (
                    <div key={index} className='px-5 mt-5'>
                      {dayjs(dateItem.date).isSame(dayjs(), 'day') ? (
                        <span className="mb-4 d-block">Today</span>
                      ) : dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') ? (
                        <span className="mb-4 d-block">Yesterday</span>
                      ) : (
                        <span className="mb-4 d-block">{dateItem.date}</span>
                      )}
                      <div className="list_notification">
                        <ul>
                          {dateItem.notification_data.map((notification, index) => {
                            const notificationContent = (
                              <>
                                <div className={`noti_img bg-${notification.tag === 'add_class' ? 'pink' : notification.tag === 'quiz' ? 'diff-yellow-op-1' : 'sky-blue-op-1'} position-relative`}>
                                  <span className={`text-uppercase text-${notification.tag === 'add_class' ? 'danger' : notification.tag === 'quiz' ? 'yellow' : 'blue'}`}>{notification.message.charAt(0)}</span>
                                  <span className="notifi_logs">
                                    <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M10 4.5V9C10 9.13261 9.94732 9.25979 9.85355 9.35355C9.75979 9.44732 9.63261 9.5 9.5 9.5H0.5C0.367392 9.5 0.240215 9.44732 0.146447 9.35355C0.0526784 9.25979 0 9.13261 0 9V4.5H10ZM10 2.5H0V1C0 0.867392 0.0526784 0.740215 0.146447 0.646447C0.240215 0.552678 0.367392 0.5 0.5 0.5H9.5C9.63261 0.5 9.75979 0.552678 9.85355 0.646447C9.94732 0.740215 10 0.867392 10 1V2.5Z" fill="#3F3F44" />
                                    </svg>
                                  </span>
                                </div>
                                <p className="ms-3 noti_text">{notification.message}</p>
                              </>
                            );

                            let linkTo = '';
                            let linkState = {};

                            if (notification.tag === 'add_class') {
                              linkTo = '/class_detail';
                              linkState = { class_id: notification.action_id }
                            } else if (notification.tag === 'add_club') {
                              linkTo = '/club_details';
                              linkState = { club_id: notification.action_id }
                            } else if (notification.tag === 'add_quiz') {
                              linkTo = '/quiz_details';
                              linkState = { quiz_id: notification.action_id }
                            } else if (notification.tag === 'add_competition') {
                              linkTo = '/competition_details';
                              linkState = { competition_id: notification.action_id }
                            } else if (notification.tag === 'add_contactus') {
                              linkTo = '/contactus-list';
                              linkState = {}
                            } else if (notification.tag === "add_feedback") {
                              linkTo = '/complaint_details';
                              linkState = { teacherId: notification.teacher_id }
                            } else if (notification.tag === "add_teacher_introduction") {
                              linkTo = "/teacher-profile";
                              linkState = { teacherId: notification.sender_id }
                            } else if (notification.tag === "add_org_introduction") {
                              linkTo = "/org-profile";
                              linkState = { orgId: notification.sender_id }
                            } else if (notification.tag === "add_teacher_identity") {
                              linkTo = "/teacher-identity-details";
                              linkState = { teachID: notification.sender_id }
                            } else if (notification.tag === "add_teacher_application") {
                              linkTo = "/teacher-applications-details";
                              linkState = { tID: notification.sender_id }
                            } else if (notification.tag === "add_teacher_background") {
                              linkTo = "/teacher-background-details";
                              linkState = { teacherId: notification.sender_id }
                            } else if (notification.tag === "suspecious_activity") {
                              linkTo = "/student-parent";
                            }

                            return (
                              <li key={index} className="d-flex mb-4">
                                {notification.shouldRedirect !== false ? (
                                  <Link
                                    to={linkTo} state={linkState}
                                    className="notif_left d-flex align-items-center"
                                    style={{ textDecoration: 'none', color: 'inherit' }} // Maintain text styling
                                  >
                                    {notificationContent}
                                  </Link>
                                ) : (
                                  <div className="notif_left d-flex align-items-center">
                                    {notificationContent}
                                  </div>
                                )}
                                <span className="noti_timer">
                                  {dayjs(notification.created_at).format('hh:mm A')}
                                </span>
                              </li>
                            );
                          })}

                        </ul>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}


