import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import Modals from 'react-modal';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./Ratingstyle.css";
import Hdashboard from '../../../components/Hdashboard';
import { New_Leads_details } from '../../../api/apiHandler';
var COMMON_STYLE_FOR_POPUP = {
  content: {
    background: 'none',
    border: '0px',
  },
}

export default function New_Leads_Details() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const location = useLocation();

  const navigate = useNavigate();
  var id = location?.state;
  const [modalIsOpen, setIsOpen] = useState(false);

  var [data, setData] = useState([]);
  var [newData, setNewData] = useState([]);

  const schema = yup.object().shape({
    select_class: yup.string().required("Class is required "),

  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const NewLeadsDetail = () => {


    New_Leads_details({ lead_id: id?.id }).then((resposnse) => {
      console.log('resposnse :', resposnse);

      if (resposnse?.code == 1) {
        setData(resposnse?.data[0])
        setNewData(resposnse?.data[0].class_details[0])

      } else {
        setData([])
      }
    });
  }

  useEffect(() => {
    NewLeadsDetail();
  }, []);

  var [teacherData, setTeacherData] = useState([]);

  return (
    <>
      <JqueryLoad />
      <Hdashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div>
                  <div className="row">
                    <div className="col-xl-9">
                      <div className="d-flex mb-4 justify-content-between">
                        <p className="ms-3 fs-3 fw-500 text-center mb-3">Class Details</p>
                      </div>
                      <div className="lead_request pt-2">
                        <div className="card">
                          <div className="lead_req_img d-flex position-relative align-items-center">
                            <img className="rounded-circle" src={data.profile_image} alt="user" />

                            <div className="ms-3">
                              <span className="fw-600">{data.full_name}</span>
                            </div>
                          </div>
                          <div className="d-md-flex align-items-end  justify-content-between ">
                            <ul>
                              <li className="mb-2">
                                <span className="font-12">

                                  <span className="fw-500 font-14" style={{ color: '#570861' }}>Learner’s Country : <span className="fw-500 text-dif-black font-14"> &nbsp;{data.country_name != null ? data.country_name : "N/A"}</span></span>
                                </span>
                              </li>
                              <li className="mb-2">
                                <span className="fw-500 font-14" style={{ color: '#570861' }}>Learner’s Local Time : <span className="fw-500 text-dif-black font-14">&nbsp;{data.local_time}</span></span>
                              </li>
                              <li className="mb-2">
                                <span className="fw-500 font-14" style={{ color: '#570861' }}>Requested Categories : <span className="fw-500 text-dif-black font-14">&nbsp;{data.category_list}</span></span>
                              </li>
                              <li className="mb-2">
                                <span className="fw-500 font-14" style={{ color: '#570861' }}>Requested Subjects : <span className="fw-500 text-dif-black font-14">&nbsp;{data.subcategory_list} </span></span>
                              </li>
                              <li className="mb-2">
                                <span className="fw-500 font-14" style={{ color: '#570861' }}>Class Size :
                                  <span className="fw-500 text-dif-black font-14">&nbsp;{data.class_size == "Individual/private" ? "1-on-1 Private Lesson" : "Group Lesson"}</span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="card request_class_info">
                        <div className="req_info_ques">
                          <span className="text-purple">What is your budget?</span>
                          <p>{newData.budget_price}</p>
                        </div>
                        <div className="req_info_ques">
                          <span className="text-purple">What is your day preference?</span>
                          <p>{newData.day_preference}</p>
                        </div>
                        <div className="req_info_ques">
                          <span className="text-purple">What you are expecting from the class?</span>
                          <p>{newData.your_expectation}</p>
                        </div>
                        <div className="req_info_ques">
                          <span className="text-purple">How often do you want lessons?</span>
                          <p>{newData.want_lessons}</p>
                        </div>
                        <div className="req_info_ques">
                          <span className="text-purple">Are you ready to hire someone?</span>
                          <p>{newData.hire_someone}</p>
                        </div>
                        {newData.comments != "null" ? (
                          <div className="req_info_ques">
                            <span className="text-purple">Any Comments</span>
                            <p>{newData.comments != "null" ? newData.comments : ""}</p>
                          </div>
                        ) : ""}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >
        {/* <Teacher_Response setIsOpen={setIsOpen} setTeacherData={setTeacherData} teacherData={teacherData}/> */}
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-bottom-0 pb-0">
              <h5 className="modal-title" id="exampleModalLabel" />
              <button type="button" className="btn-close" onClick={() => setIsOpen(false)} />
            </div>
            <div className="modal-body meet_techer">
              <div className="meet_details mb-5 d-lg-flex align-items-start">
                <div className="meet_img mb-lg-0 mb-4">
                  <img src={teacherData.profile_image} alt="meet_techer" />
                </div>
                <div className="meet_right">
                  <div className>
                    <div className="deg_name d-flex mb-xl-0 mb-3 align-items-center">
                      <h2 className="poppins fw-600 text-purple">{teacherData.full_name}</h2>
                      <span className=" ms-2 text-dif-black">(MSC.BED)</span>
                    </div>
                  </div>
                  <div className="live_canada align-items-center mb-3 d-flex">
                    <span className="fs-5">Lives in {teacherData.country_name}</span>
                    <img src="./assets/images/country_flag.png" alt="country_flag" className="ms-3" />
                  </div>
                  <p className="fs-5 text-dif-black mb-3 fw-600">Experienced Multi-Subject Teacher: Creative Arts &amp; World Travels</p>
                  <div className="mb-3 d-lg-flex align-items-center">

                    {/* <ul className="d-flex mb-lg-0 mb-2 align-items-center"> */}
                    <i data-star={teacherData.teacher_ratings}></i>
                    <span className="fs-5 fw-600 ms-lg-3">{teacherData.teacher_ratings} Reviews</span>
                    <span className="fw-bold text-gray d-sm-inline-block d-flex fs-5 ms-sm-2">({teacherData.teacher_reviews} Total Reviews)</span>
                  </div>
                  <span className="text-gray mb-3 d-block fw-600">238 Completed Class</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modals>
    </>
  )
}
