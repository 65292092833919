import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
// import { org_teacher_permission_listing } from '../../../api/apiHandler';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad'
import Leeds_Filter from './Leeds_Filter'
import New_Headers from './New_Headers'
import New_Leads from './New_Leads'
import Hdashboard from '../../../components/Hdashboard';
import PrefferedTeachers from './PrefferedTeachers';
import NonPrefferedTeachers from './NonPrefferedTeachers';
import Statistics from './Statistics';

export default function New_Leeds({ isLoader }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();

    const handleStepChange = (newStep) => setStep(newStep);

    const [CategoriesData, setCategoriesData] = useState(null)
    const [subCategoriesData, setSubCategoriesData] = useState([])
    const [classSize, setclassSize] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [search, setSearch] = useState('')

    const [step, setStep] = useState(parseInt(localStorage.getItem("Leeds_Step", 1)) ? parseInt(localStorage.getItem("Leeds_Step", 1)) : 1);

    useEffect(() => {
        localStorage.setItem("Leeds_Step", step)
    }, [step, parseInt(localStorage.getItem("Leeds_Step", 1))]);

    const renderStep = () => {
        switch (localStorage.getItem("Leeds_Step", 1) ? parseInt(localStorage.getItem("Leeds_Step", 1)) : 1) {
            case 1:
                return (
                    <New_Leads CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} classSize={classSize} startDate={startDate} endDate={endDate} isLoader={isLoader} search={search} />
                );
            case 2:
                return (
                    <PrefferedTeachers CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} classSize={classSize} startDate={startDate} endDate={endDate} isLoader={isLoader} search={search} />
                );
            case 3:
                return (
                    <NonPrefferedTeachers CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} classSize={classSize} startDate={startDate} endDate={endDate} isLoader={isLoader} search={search} />
                );
            case 4:
                return (
                    <Statistics isLoader={isLoader} />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-2 mt-1 arrow_left d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span className="ms-2">Back</span>
                                </span></a>
                            <div className>
                                <div className="d-xl-flex align-items-center mb-4 justify-content-between">
                                    <New_Headers onStepChange={handleStepChange} />
                                    {step !== 4 && (
                                        <Leeds_Filter CategoriesData={CategoriesData} subCategoriesData={subCategoriesData} setCategoriesData={setCategoriesData} setSubCategoriesData={setSubCategoriesData} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} classSize={classSize} setclassSize={setclassSize} search={search} setSearch={setSearch} />
                                    )}
                                </div>
                                {renderStep()}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
