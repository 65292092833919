import { useEffect, useState,useRef } from 'react';

function getRelativeTime(dateString) {
  const currentDate = new Date();
  const givenDate = new Date(dateString);

  const diffSeconds = Math.floor((currentDate - givenDate) / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffWeeks = Math.floor(diffDays / 7);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffDays / 365);

  if (diffSeconds < 60) {
    return `${diffSeconds} seconds ago`;
} else if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
} else if (diffHours < 24) {
    return `${diffHours} hours ago`;
} else if (diffDays < 7) {
    return `${diffDays} days ago`;
} else if (diffWeeks < 4) {
    return `${diffWeeks} weeks ago`;
} else if (diffMonths < 12) {
    if (diffMonths === 0) {
        return `1 month ago`;
    } else {
        return `${diffMonths} months ago`;
    }
} else {
    return `${diffYears} years ago`;
}

}

const useRelativeTime = (dateString) => {
    const [relativeTime, setRelativeTime] = useState(getRelativeTime(dateString));
    const requestRef = useRef();
  
    useEffect(() => {
      const updateRelativeTime = () => {
        setRelativeTime(getRelativeTime(dateString));
        requestRef.current = requestAnimationFrame(updateRelativeTime);
      };
  
      requestRef.current = requestAnimationFrame(updateRelativeTime);
  
      return () => cancelAnimationFrame(requestRef.current);
    }, [dateString]);
  
    return relativeTime;
  };
export default useRelativeTime;