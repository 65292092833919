import React, { useState, useEffect } from 'react';
import Hdashboard from '../../../components/Hdashboard';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import { send_notification, fetch_user_data } from '../../../api/apiHandler';
import Select from 'react-select';
import * as Yup from "yup";
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import { useFormik } from 'formik';

export default function Messages({ isLoader }) {
    const [selectedCategory, setSelectedCategory] = useState({ value: 'all', label: 'All' });
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedUserEmails, setSelectedUserEmails] = useState(new Set());
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [users, setUsers] = useState([]);

    const categoryOptions = [
        { value: 'all', label: 'All' },
        { value: 'teacher', label: 'Teacher' },
        { value: 'organization', label: 'Organization' },
        { value: 'org_teacher', label: 'Organization Teacher' },
        { value: 'below_18', label: 'Parents' },
        { value: 'learner', label: 'Kids' },
        { value: 'above_18', label: 'Over 18' },
        { value: 'after_school', label: 'Schools-After School Centers' },
    ];

    useEffect(() => {
        fetchUsers(selectedCategory.value);
    }, [selectedCategory]);

    const fetchUsers = (category) => {
        isLoader(true)
        fetch_user_data({ category: category })
            .then((response) => {
                if (response.code === "1") {
                    isLoader(false)
                    if (category === 'all') {
                        const allUsers = [...response.data.user_data, ...response.data.learner_data];
                        setUsers(allUsers);
                    } else if (category === 'learner') {
                        setUsers(response.data.learner_data);
                    } else if (category != 'all' && category != 'learner') {
                        setUsers(response.data.user_data);
                    }
                } else {
                    setUsers([]);
                    isLoader(false)
                }
            })
            .catch((error) => {
                setUsers([]);
            });
    };

    const handleCategoryChange = (selectedOption) => {
        setSelectedCategory(selectedOption);
        // Reset user selection when category changes
        notificationForm.setFieldValue('selectedUsers', []);
        setSelectedUsers([]);
        setSelectedUserEmails(new Set());
        setSelectedUserIds([]);
        setSelectAll(false);
    };

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        if (checked) {
            const allUserIds = users.map(user => user.id);
            const allEmails = new Set(users.map(user => user.email));
            setSelectedUsers(users);
            setSelectedUserEmails(allEmails);
            setSelectedUserIds(allUserIds);
            notificationForm.setFieldValue('selectedUsers', users);
        } else {
            setSelectedUsers([]);
            setSelectedUserEmails(new Set());
            setSelectedUserIds([]);
            notificationForm.setFieldValue('selectedUsers', []);
        }
    };

    const handleUserSelect = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.value);
        const selectedEmails = new Set(selectedOptions.map(option => option.email));
        setSelectedUsers(selectedOptions);
        setSelectedUserIds(selectedIds);
        setSelectedUserEmails(selectedEmails);
        notificationForm.setFieldValue('selectedUsers', selectedOptions);
    };

    const notificationSchema = Yup.object({
        title: Yup.string().required("Please enter title"),
        body: Yup.string().required("Please enter body"),
        selectedUsers: Yup.array().min(1, "Please select at least one user")
    });

    const notificationForm = useFormik({
        initialValues: {
            title: '',
            body: '',
            selectedUsers: []
        },
        validationSchema: notificationSchema,

        onSubmit: async (data, { resetForm }) => {
            const notificationData = {
                emails: Array.from(selectedUserEmails),
                ids: selectedUserIds,
                title: data.title,
                body: data.body,
                receiverTypes: ['W'],
                notification_tag: "admin_message"
            };

            isLoader(true)
            send_notification(notificationData)
                .then((response) => {
                    if (response.code === "1") {
                        TOAST_SUCCESS(response.message);
                        resetForm();
                        setSelectedUsers([]);
                        setSelectedUserEmails(new Set());
                        setSelectedUserIds([]);
                        setSelectedCategory({ value: 'all', label: 'All' });
                        setSelectAll(false);
                        isLoader(false)
                    } else {
                        TOAST_ERROR(response.message);
                        isLoader(false)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    });

    const displayError = (fieldName) =>
        notificationForm.touched[fieldName] && notificationForm.errors[fieldName];

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="index.php" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <>
                        <div className="fs-3 fw-500 mt-4 ms-4 mb-2">
                            Messages
                        </div>

                        <div className='d-flex justify-content-center mt-4'>
                            <div className="col-lg-8 p-5 mt-3" style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 8px" }}>
                                <form name='notificationForm' onSubmit={notificationForm.handleSubmit}>
                                    <div className="form-group">
                                        <label className='fs-6 fw-500'>Select User Category:</label>
                                        <Select
                                            options={categoryOptions}
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                            className="mt-2"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: '1px solid rgba(102, 102, 102, 0.35)',
                                                    borderRadius: '12px',
                                                    padding: '5px 10px',
                                                    minHeight: '46px',
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />

                                        <label className='fs-6 fw-500 mt-4'>Select Users:</label>
                                        {!selectAll && (
                                            <Select
                                                options={users.map(user => ({
                                                    label: `${user.full_name} (${user.code_generate})`,
                                                    value: user.id,
                                                    email: user.email
                                                }))}
                                                value={selectedUsers}
                                                className='mt-2'
                                                onChange={handleUserSelect}
                                                isSearchable={true}
                                                isMulti={true}
                                                placeholder="Search user..."
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        border: '1px solid rgba(102, 102, 102, 0.35)',
                                                        borderRadius: '12px',
                                                        padding: '5px 10px',
                                                        minHeight: '46px',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                            />
                                        )}
                                        {selectAll && (
                                            <input
                                                type="text"
                                                className="form-control py-2 mt-2"
                                                name="select_all"
                                                placeholder={selectAll ? "All users are selected" : "Select User"}
                                                value={selectAll ? "All users are selected" : ""}
                                                readOnly
                                                style={{ borderRadius: "12px" }}
                                            />
                                        )}
                                        {displayError("selectedUsers") && (
                                            <div className="text-danger">{notificationForm.errors.selectedUsers}</div>
                                        )}
                                    </div>
                                    <div className="form-check mt-2">
                                        <input type="checkbox" className="form-check-input" id="selectAll" checked={selectAll} onChange={handleCheckboxChange} />
                                        <label className="form-check-label" htmlFor="selectAll">Select All Users</label>
                                    </div>
                                    <div className="form-group">
                                        <label className='mb-1 fs-6 fw-500 mt-4'>Message Title:</label>
                                        <input
                                            type="text"
                                            className="form-control py-2 mt-2"
                                            name="title"
                                            placeholder="Enter Title"
                                            value={notificationForm.values.title}
                                            onChange={notificationForm.handleChange}
                                            style={{ borderRadius: "12px" }}
                                        />
                                        {displayError("title") && (
                                            <div className="text-danger">{notificationForm.errors.title}</div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label className='fs-6 fw-500 mt-4'>Message:</label>
                                        <textarea rows={5}
                                            type="text"
                                            className="form-control mt-2"
                                            name="body"
                                            placeholder="Enter message"
                                            value={notificationForm.values.body}
                                            onChange={notificationForm.handleChange}
                                            style={{ borderRadius: "12px" }} />
                                        {displayError("body") && (
                                            <div className="text-danger">{notificationForm.errors.body}</div>
                                        )}
                                    </div>
                                    <div className="mt-5 mb-3 d-flex justify-content-start gap-2">
                                        <button className="btn-theme bg-yellow mx-1" type='submit'>Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                </div>
            </main>
        </>
    );
}