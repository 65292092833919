import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { admin_permission_detail, delete_blog, get_blog } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import FontAwesome from "react-fontawesome";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import Image from "../../../user.png";
import RelativeTime from "../../../utils/RelativeTime";
import { Link } from "react-router-dom";

export default function Blog({ isLoader }) {

    const [data, setData] = useState([])
    const [permissions, setPermission] = useState([]);
    const [search, setSearch] = useState('')
    const [role, setRole] = useState(localStorage.getItem("adminRole"));
    const [adminId, setAdminId] = useState(localStorage.getItem("aID"));
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [action, setAction] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handleClose = () => setShow(false);
    const handleShow = (action) => {
        setShow(true);
        setAction(action);
    };

    const handleGetData = (search, page) => {
        isLoader(true)
        get_blog({ search: search, page: page }).then((response) => {
            console.log('response :', response);
            if (response.code == 1) {
                setData(response.data.result)
                setTotalPages(response?.data?.totalPages);
                isLoader(false)
            } else {
                setData([]);
                isLoader(false)
            }
        })
    }

    const handleLinkClick = (BlogId) => {
        navigate('/blog-details', { state: { BlogId: BlogId } })
    }

    const handleEditClick = (BlogId, admin_id) => {
        if (role === "A" && adminId != admin_id) {
            handleShow('edit');
        } else {
            navigate('/edit-blog', { state: { BlogId: BlogId } });
        }
    };

    const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleDelete = (id, admin_id) => {

        if (role === "A" && adminId != admin_id) {
            handleShow('delete');
        } else {
            Swal.fire({
                icon: "warning",
                title: "Are you sure you want to delete?",
                showCancelButton: true,
                confirmButtonText: "Delete",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        isLoader(true)
                        delete_blog({ id: id }).then((response) => {
                            if (response.code == 1) {
                                TOAST_SUCCESS(response.message);
                                handleGetData(search, currentPage)
                                isLoader(false)
                            } else {
                                TOAST_ERROR(response.message);
                                isLoader(false)
                            }
                        });
                    } catch (error) {
                        TOAST_ERROR("Error!!");
                    }
                }
            });
        }
    };

    useEffect(() => {
        handleGetData(search, currentPage)
        if (role !== "S") {
            subAdminPermission();
        }
    }, [search, currentPage])

    const addBlog = () => {
        navigate('/add-blog')
    }

    const subAdminPermission = () => {
        admin_permission_detail({ id: adminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                    setPermission([])
                }
            })
            .catch((error) => {
                setPermission([])
            });
    }

    const blogPermission = permissions.find(permission => permission.menu_name === "Blogs");

    // Check if add,edit,delete is allowed for "Blogs"
    const isAddAllowed = blogPermission && blogPermission.add === 1;
    const isEditAllowed = blogPermission && blogPermission.edit === 1;
    const isDeleteAllowed = blogPermission && blogPermission.delete === 1;

    return <>
        <JqueryLoad />
        <Hdashboard />
        <main>
            <div className="dashboard-app d-lg-flex">
                <header className="dashboard-toolbar d-lg-flex justify-content-between">
                    <div className="align-items-center justify-content-between d-flex">
                        <div className="d-flex align-items-center">
                            <Link className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </Link>
                            <Link to="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </Link>
                        </div>
                    </div>
                </header>

                <div className="mx-5 mt-1">
                    <p className="fs-3 fw-500 mt-4">Blogs</p>
                    <div className="d-flex justify-content-end align-items-center mb-3 search-container">
                        <div className="row me-2">
                            <div className="col">
                                <CInputGroup>
                                    <CFormInput type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
                                    <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                                        <FontAwesome className="fa-solid fa-search" name="search" />
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>
                        {(role === "S" || isAddAllowed) &&
                            <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={addBlog}>
                                + Add Blog
                            </button>
                        }
                    </div>

                    <div className="row blogs_lists">
                        {(
                            data?.length === 0 && !isLoader ? (
                                <div className="col-12 text-center">
                                    <p className="text-purple fw-500 fs-3 mt-5">No Data Found</p>
                                </div>
                            ) : (
                                data && data.map((blog) => (
                                    <div key={blog.id} className="col-xl-4 col-lg-6 col-md-6 mb-3 mt-3">
                                        <div className="certi_box bg-transparent shadow-none mb-0">
                                            <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0" onClick={() => handleLinkClick(blog.id)}>
                                                {blog?.blog_media && blog?.blog_media[0]?.type === "video" ? (
                                                    <video controls className="w-100 img-fluid border-radius-10" style={{ height: "300px", objectFit: "cover" }}>
                                                        <source src={blog?.blog_media[0]?.media} type="video/mp4" />
                                                    </video>
                                                ) : (
                                                    <img src={blog?.blog_media[0]?.media} alt="blog" className="w-100 img-fluid border-radius-10" style={{ height: "300px", objectFit: "cover" }} />
                                                )}
                                                <span className="border-radius-10 music-tag d-inline-block px-3" style={{ position: "absolute", top: "10px", left: "10px" }}>
                                                    {blog?.media_type}
                                                </span>
                                            </div>

                                            <div className="certi_ctn px-3">
                                                <div className="mt-2">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h2 className="fw-600 text-dif-black text-capitalize poppins cursor-pointer" onClick={() => handleLinkClick(blog.id)} style={{ maxWidth: "calc(100% - 100px)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{blog.title}</h2>

                                                        <div className="d-flex gap-3">
                                                            {(role === "S" || isEditAllowed) && (
                                                                <div>
                                                                    <FontAwesome
                                                                        name="edit"
                                                                        className="fs-5 fa-solid fa-edit text-info cursor-pointer"
                                                                        onClick={() => handleEditClick(blog.id, blog.admin_id)}
                                                                    ></FontAwesome>
                                                                </div>
                                                            )}
                                                            {(role === "S" || isDeleteAllowed) && (
                                                                <div>
                                                                    <FontAwesome
                                                                        name="delete"
                                                                        className="fs-5 fa-solid fa-trash bg-light text-danger cursor-pointer"
                                                                        onClick={() => handleDelete(blog.id, blog.admin_id)}
                                                                    ></FontAwesome>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center mt-3" onClick={() => handleLinkClick(blog.id)}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="dwnl_img">
                                                            <img src={blog.profile_image ? blog.profile_image : Image} alt="profile_image" className="rounded-circle" style={{ height: "50px", width: "50px" }} />
                                                        </div>
                                                        <div className="ms-3">
                                                            <span className="fs-5 fw-500 overflow-hidden">{blog.admin_name}</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="mx-3">{blog.formatted_date}</span> • <span className="mx-1"> <RelativeTime dateString={blog.created_at} /> </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                        )}
                    </div>

                    {data?.length !== 0 ? (
                        <div className="custom_pagenation mb-5 mt-1">
                            <ul className="d-flex align-items-center justify-content-center">
                                <li className={`circle ${currentPage === 1 ? 'disabled' : ''}`} onClick={handlePreviousPage}>
                                    <Link className="text-purple font-w" onClick={handlePreviousPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M11.354 12.354a.5.5 0 0 1-.708 0L5.5 7.707l5.146-5.147a.5.5 0 0 1 .708.708L6.707 7.5l4.647 4.646a.5.5 0 0 1 0 .708z" />
                                        </svg>
                                    </Link>
                                </li>
                                {[...Array(totalPages).keys()].map((pageNumber) => (
                                    <li key={pageNumber} className={`circle ${pageNumber + 1 === currentPage ? 'active' : ''}`} onClick={() => handlePaginationClick(pageNumber + 1)}>
                                        <Link className="text-purple font-w" onClick={() => handlePaginationClick(pageNumber + 1)}>
                                            {pageNumber + 1}
                                        </Link>
                                    </li>
                                ))}
                                <li className={`circle ${currentPage === totalPages ? 'disabled' : ''}`} onClick={handleNextPage}>
                                    <Link className="text-purple font-w" onClick={handleNextPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4.646 3.646a.5.5 0 0 1 .708 0L10.5 9.293l-5.146 5.147a.5.5 0 0 1-.708-.708L9.293 9.5 4.646 4.854a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    ) : (null)}
                </div>
            </div>
        </main>

        {/* Modal for show error */}
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body className="fs-5 mt-3 text-danger text-center">
                {action === "edit" ? "You don't have permission to edit this blog." : "You don't have permission to delete this blog."}
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <Button variant="warning" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}