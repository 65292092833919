import React, { useState, useEffect } from 'react';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import { GroupTransactions } from '../../../api/apiHandler';
import 'jspdf-autotable';
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import customStyles from '../../../utils/dataTableStyles';

export default function EwalletGroup({ isLoader }) {

    const [allData, setAllData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation()
    const learnerId = location.state && location.state.learner_id;
    const activity_id = location.state && location.state.activity_id

    const fetchEwalletData = () => {
        isLoader(true)
        GroupTransactions({ user_id: learnerId, activity_id: activity_id })
            .then((response) => {
                console.log('response :', response);
                if (response.code == "1") {
                    const dataWithRowCount = response.data.map((row, index) => ({ ...row, id: index + 1 }));
                    setAllData(dataWithRowCount);
                    isLoader(false)
                } else {
                    setAllData([]);
                    isLoader(false)
                }
            })
            .catch((error) => {
                setAllData([]);
                isLoader(false)
            });
    };

    useEffect(() => {
        fetchEwalletData();
    }, []);

    const AllDataColumns = [
        {
            name: 'No',
            selector: row => row.id,
            sortable: false,
            width: "100px"
        },
        {
            name: 'PaymentDate',
            selector: row => {
                const date = new Date(row.created_at);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedDay = day < 10 ? `0${day}` : day;
                const year = date.getFullYear();
                return `${formattedDay}-${formattedMonth}-${year}`;
            },
            sortable: true,
            width: "180px"
        },
        {
            name: 'Transaction Id',
            selector: row => row.transaction_id,
            sortable: true,
            width: "300px"
        },
        // {
        //     name: 'FromCode',
        //     selector: row => row.usercode ? row.usercode : "-",
        //     sortable: true,
        //     width: "120px"
        // },
        // {
        //     name: 'From Name',
        //     selector: row => row.from_account,
        //     sortable: true,
        //     width: "160px",
        //     cell: row => (
        //         <span
        //             onClick={() => navigate('/user_details', { state: { id: row.user_id } })}
        //             style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
        //         >
        //             {row.from_account}
        //         </span>
        //     )
        // },
        // {
        //     name: 'To Code',
        //     selector: row => row.teachercode ? row.teachercode : "-",
        //     sortable: true,
        //     width: "100px"
        // },
        // {
        //     name: 'To Name',
        //     selector: row => row.to_account,
        //     sortable: true,
        //     width: "160px",
        //     cell: row => (
        //         row.teacher_category == 'teacher' ? (
        //             <span
        //                 onClick={() => navigate('/teacher_details', { state: { teacherId: row.teacher_id } })}
        //                 style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
        //             >
        //                 {row.to_account}
        //             </span>
        //         ) : row.teacher_category == 'organization' ? (
        //             <span
        //                 onClick={() => navigate('/org_details', { state: { userId: row.teacher_id } })}
        //                 style={{ color: "rgb(87, 8, 97)", border: "none", background: "none", cursor: 'pointer' }}
        //             >
        //                 {row.to_account}
        //             </span>
        //         ) : (
        //             <span>{row.to_account}</span>
        //         )
        //     )
        // },
        {
            name: 'Program Name',
            selector: row => row.activity_title,
            sortable: true,
            width: "280px"
        },
        {
            // name: (
            //     <>
            //         Program<br />Type
            //     </>
            // ),
            name: 'Program Type',
            selector: row => row.activity_type,
            sortable: true,
            width: "180px"
        },
        {
            // name: (
            //     <>
            //         Platform<br />Type
            //     </>
            // ),
            name: 'Program Type',
            selector: row => row.platform_type,
            sortable: true,
            width: "180px"
        },
        {
            name: 'Duration',
            selector: row => row.duration,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Amount',
            selector: row => `$ ${row.amount}`,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Status',
            selector: row => {
                let buttonClass = "bg-secondary";
                let buttonText = row.payment_status;

                if (row.payment_status === "paid") {
                    buttonClass = "bg-info text-dark";
                } else if (row.payment_status === "succeeded") {
                    buttonClass = "bg-success text-light";
                } else if (row.payment_status === "refunded") {
                    buttonClass = "bg-warning";
                }

                return (
                    <button className={`${buttonClass} border-0 px-1 fw-500`} style={{ width: "80px" }}>
                        {buttonText}
                    </button>
                );
            },
            sortable: true,
            width: "115px"
        },
    ]

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <>
            <JqueryLoad />
            {/* <Hdashboard /> */}
            <main>
                {/* <div className="dashboard-app d-lg-flex px-5 mt-1"> */}
                <div className="px-4 mt-3">

                    <header className="dashboard-toolbar d-lg-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <a className="menu-toggle align-items-center d-lg-none d-flex">
                                <i className="fa fa-bars" />
                            </a>
                            <a href="index.php" className="d-lg-none d-block brand-logo">
                                <img src="./assets/images/logo.png" alt="logo" />
                            </a>
                        </div>
                    </header>

                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>

                    <div className="d-flex justify-content-start title mt-3">
                        <p className="fs-3 fw-500">Learner's All transactions for that Particular class</p>
                    </div>

                    <div id='download_earnings'>
                        <div className='mt-5'>
                            <>
                                {allData && allData.length > 0 ? (
                                    <DataTable columns={AllDataColumns} data={allData} keyField="id" pagination customStyles={customStyles} />
                                ) : (
                                    <p className="mt-5 text-center text-purple fw-500 fs-3">No data Found</p>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
