import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import Ckeditor from "./Ckeditor";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import {
    admin_permission_detail,
    get_cms_pages,
} from "../../../api/apiHandler";
import { TOAST_ERROR } from "../../../utils/common.service";

export default function Pages() {
    const [activeAccordion, setActiveAccordion] = useState(null);

    const [aboutUsPage, setAboutUsPage] = useState(null);
    const [privacyPolicyPage, setPrivacyPolicyPage] = useState(null);
    const [termsConditionsPage, setTermsConditionsPage] = useState(null);

    const AdminId = localStorage.getItem("aID");
    const [permissions, setPermission] = useState([]);
    const role = localStorage.getItem("adminRole");

    useEffect(() => {
        // Fetch about us page data
        get_cms_pages({ keyword: "about_us" }).then((response) => {
            if (response.code === "1") {
                setAboutUsPage(response.data.content);
            } else {
                TOAST_ERROR(response.message);
            }
        });

        // Fetch privacy policy page data
        get_cms_pages({ keyword: "privacy_policy" }).then((response) => {
            if (response.code === "1") {
                setPrivacyPolicyPage(response.data.content);
            } else {
                TOAST_ERROR(response.message);
            }
        });

        // Fetch terms and conditions page data
        get_cms_pages({ keyword: "terms_conditions" }).then((response) => {
            if (response.code === "1") {
                setTermsConditionsPage(response.data.content);
            } else {
                TOAST_ERROR(response.message);
            }
        });
    }, []);

    const subAdminPermission = () => {
        admin_permission_detail({ id: AdminId })
            .then((response) => {
                if (response.code == 1) {
                    setPermission(response.data);
                } else {
                   setPermission([])
                }
            })
            .catch((error) => {
                setPermission([])
            });
    };

    useEffect(() => {
        if (role !== "S") {
            subAdminPermission();
        }
    }, []);

    const pagesPermission = permissions.find(
        (permission) => permission.menu_name === "Pages"
    );

    // Check if add,edit ,delete is allowed for "Pages"
    const isEditAllowed = pagesPermission && pagesPermission.edit === 1;

    // Function to render HTML content safely
    const renderHtmlContent = (content) => {
        return { __html: content };
    };

    return (
        <>
            <JqueryLoad />
            <Hdashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="index.php" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>

                    <div className="mx-5 mt-3">
                        <p className="fs-3 fw-500 mt-3 mb-5">Pages</p>

                        <div id="accordion" className="mt-4">
                            <div className="card mb-3">
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded={activeAccordion === "accordion1"}
                                            onClick={() =>
                                                setActiveAccordion(
                                                    activeAccordion === "accordion1" ? null : "accordion1"
                                                )
                                            }
                                        >
                                            <p className="fs-5 fw-500">
                                                <i className="fa fa-plus"></i> About us
                                            </p>
                                        </button>
                                    </h5>
                                </div>

                                <div
                                    id="collapseOne"
                                    className={`collapse ${activeAccordion === "accordion1" ? "show" : ""
                                        }`}
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        {isEditAllowed || role === "S" ? <Ckeditor data={{ content: aboutUsPage, keyword: 'about_us' }} /> : <div dangerouslySetInnerHTML={renderHtmlContent(aboutUsPage)} />}
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded={activeAccordion === "accordion2"}
                                            onClick={() =>
                                                setActiveAccordion(
                                                    activeAccordion === "accordion2" ? null : "accordion2"
                                                )
                                            }
                                        >
                                            <p className="fs-5 fw-500">
                                                <i className="fa fa-plus"></i> Privacy Policy
                                            </p>
                                        </button>
                                    </h5>
                                </div>

                                <div
                                    id="collapseTwo"
                                    className={`collapse ${activeAccordion === "accordion2" ? "show" : ""
                                        }`}
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        {isEditAllowed || role === "S" ? <Ckeditor data={{ content: privacyPolicyPage, keyword: 'privacy_policy' }} /> : <div dangerouslySetInnerHTML={renderHtmlContent(privacyPolicyPage)} />}
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-header" id="headingThree">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded={activeAccordion === "accordion3"}
                                            onClick={() =>
                                                setActiveAccordion(
                                                    activeAccordion === "accordion3" ? null : "accordion3"
                                                )
                                            }
                                        >
                                            <p className="fs-5 fw-500">
                                                <i className="fa fa-plus"></i> Terms And Condition
                                            </p>
                                        </button>
                                    </h5>
                                </div>

                                <div
                                    id="collapseThree"
                                    className={`collapse ${activeAccordion === "accordion3" ? "show" : ""
                                        }`}
                                    aria-labelledby="headingThree"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">
                                        {isEditAllowed || role === "S" ? <Ckeditor data={{ content: termsConditionsPage, keyword: 'terms_conditions' }} /> : <div dangerouslySetInnerHTML={renderHtmlContent(termsConditionsPage)} />}
                                    </div>
                                </div>
                            </div>
                            {/* Add other accordion items similarly */}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
