import React, { useState, useEffect } from 'react';
import JqueryLoad from '../../../JqueryLoad/JqueryLoad';
import Hdashboard from '../../../components/Hdashboard';
import { useNavigate } from 'react-router-dom';
import { admin_earning } from '../../../api/apiHandler';
import 'jspdf-autotable';
import generatePDF from "react-to-pdf";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import Select from 'react-select';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const options = {
  filename: "My_Earnings.pdf",
  page: {
    margin: 20
  }
};

const getTargetElement = () => document.getElementById("download_earnings");
const downloadPdf = () => {
  // Exclude Apply and Clear buttons from the PDF
  const excludedElements = document.querySelectorAll('.btn-theme');
  excludedElements.forEach(element => element.style.display = 'none');

  // Generate PDF with the remaining content
  generatePDF(getTargetElement, options);

  // Restore the display of the excluded elements
  excludedElements.forEach(element => element.style.display = '');
};

export default function Earning({isLoader}) {

  const [selectedPeriod, setSelectedPeriod] = useState('yearly');
  const [selectedOption, setSelectedOption] = useState({ value: "yearly", label: "Yearly" });
  const [earningData, setEarningData] = useState([]);

  const currentDate = new Date();
  const defaultStartDate = currentDate.toISOString().split('T')[0];
  const defaultStartMonth = currentDate.toISOString().slice(0, 7);

  let initialStartDate = defaultStartDate;
  let initialEndDate = defaultStartDate;

  if (selectedPeriod === 'daily') {
    initialStartDate = currentDate.toISOString().split('T')[0];
    initialEndDate = currentDate.toISOString().split('T')[0];
  } else if (selectedPeriod === 'weekly') {
    const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    initialStartDate = sevenDaysAgo.toISOString().split('T')[0];
    initialEndDate = currentDate.toISOString().split('T')[0];
  }

  // Set the state variables with the initial values
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [startMonth, setStartMonth] = useState(defaultStartMonth);
  const [endMonth, setEndMonth] = useState(defaultStartMonth);
  const currentYear = new Date().getFullYear();
  const [startYear, setStartYear] = useState(currentYear);
  const [endYear, setEndYear] = useState(currentYear);

  const navigate = useNavigate();

  useEffect(() => {
    fetchEarningsData();
  }, [selectedPeriod, startDate, endDate, startMonth, endMonth, startYear, endYear]);

  const fetchEarningsData = () => {
    let requestData = {};

    if (selectedPeriod === 'daily' || selectedPeriod === 'weekly') {
      requestData = {
        type: selectedPeriod,
        startDate: startDate,
        endDate: endDate
      };
    } else if (selectedPeriod === 'monthly') {
      requestData = {
        type: 'monthly',
        startDate: startMonth,
        endDate: endMonth
      };
    } else if (selectedPeriod === 'yearly') {
      requestData = {
        type: 'yearly',
        startYear: startYear,
        endYear: endYear
      };
    }
    isLoader(true)
    admin_earning(requestData)
      .then(response => {
        if (response.code === "1") {
          let formattedData = response.data.map(item => ({
            Date: item.Date,
            admin_earnings: Array.isArray(item.admin_earnings) ?
              item.admin_earnings.reduce((acc, val) => acc + val.admin_earnings, 0) :
              item.admin_earnings.admin_earnings
          }));
          setEarningData(formattedData);
          isLoader(false)
        } else {
          setEarningData([]);
          isLoader(false)
        }
      })
      .catch(error => {
        setEarningData([]);
        isLoader(false)
      });
  };

  const handlePeriodChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedPeriod(selectedOption.value);

    // Update start and end dates immediately
    const currentDate = new Date();
    if (selectedOption.value === 'daily') {
      setStartDate(currentDate.toISOString().split('T')[0]);
      setEndDate(currentDate.toISOString().split('T')[0]);
    } else if (selectedOption.value === 'weekly') {
      const sevenDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      setStartDate(sevenDaysAgo.toISOString().split('T')[0]);
      setEndDate(currentDate.toISOString().split('T')[0]);
    }
  };

  const handleStartMonthChange = (value) => {
    setStartMonth(value);
  };

  const handleEndMonthChange = (value) => {
    setEndMonth(value);
  };

  const getYearOptions = (minYear) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = minYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  const getCurrentMonthYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
    return `${year}-${month}`;
};

const currentMonthYear = getCurrentMonthYear();

  const renderCustomHeader = ({
    date,
    changeYear,
    decreaseYear,
    increaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled
  }) => (
    <div>
      <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
        {'<'}
      </button>
      <span>{date.getFullYear()}</span>
      <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
        {'>'}
      </button>
    </div>
  );

  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    const rectWidth = 40;
    const rectHeight = 20; // Adjusted height

    if (value !== 0 && value !== null && value !== undefined) {
      return (
        <g>
          <rect x={x + width / 2 - rectWidth / 2} y={y - rectHeight} width={rectWidth} height={rectHeight} fill="#FFC924" />
          <text x={x + width / 2} y={y - rectHeight / 2} fill="rgb(87, 8, 97)" textAnchor="middle" dominantBaseline="middle">
            {value}
          </text>
        </g>
      );
    }
    return null;
  };

  const handleBack = () => {
    navigate(-1);
  };

  const downloadExcel = () => {
    const filteredData = earningData.map((item, index) => {
      let earnings = 0;

      // Determine earnings based on selected period
      // You can use your existing logic here
      // This is just a placeholder logic for demonstration
      if (selectedPeriod === 'weekly' || selectedPeriod === 'daily') {
        earnings = item.admin_earnings ? item.admin_earnings.admin_earnings : 0;
      } else if (selectedPeriod === 'monthly') {
        if (Array.isArray(item.admin_earnings) && item.admin_earnings.length > 0) {
          earnings = item.admin_earnings.reduce((total, entry) => total + entry.admin_earnings, 0);
        }
      } else if (selectedPeriod === 'yearly') {
        earnings = item.admin_earnings ? item.admin_earnings.admin_earnings : 0;
      }

      return {
        Sr_Number: index + 1,
        Date: item.Date,
        Earnings: earnings
      };
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'earning_report.xlsx');
  };

  return (
    <>
      <JqueryLoad />
      <Hdashboard />
      <main>
        <div className="dashboard-app d-lg-flex px-5 mt-1">
          <header className="dashboard-toolbar d-lg-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <a className="menu-toggle align-items-center d-lg-none d-flex">
                <i className="fa fa-bars" />
              </a>
              <a href="index.php" className="d-lg-none d-block brand-logo">
                <img src="./assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </header>
          <span onClick={handleBack} className="mt-3">
            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
            </svg>
            <span className="ms-2">Back</span>
          </span>

          <div className="d-flex justify-content-between title mt-2 mb-2">
            <p className="fs-3 fw-500">My Earnings</p>
            <div>
              <button onClick={downloadPdf} className="btn-theme bg-yellow m-2">Download PDF</button>
              <button onClick={() => downloadExcel(earningData)} className="btn-theme bg-yellow m-2">Download Excel</button>
            </div>
          </div>

          <div>
            <div className="d-flex align-items-center mb-5 mt-3">
              <div className="col-md-2">
                <label className="mb-2">Select Time Period :</label>
                <Select
                  options={[
                    { value: "daily", label: "Daily" },
                    { value: "weekly", label: "Weekly" },
                    { value: "monthly", label: "Monthly" },
                    { value: "yearly", label: "Yearly" },
                  ]}
                  className="skip_pdf"
                  id="period-select"
                  value={selectedOption}
                  onChange={handlePeriodChange}
                  styles={{
                    width: "100%",
                    maxWidth: "200px",
                    minHeight: "40px"
                  }}
                  placeholder="Select Time Period"
                />
              </div>

              {selectedPeriod === 'daily' && (
                <div className="d-flex align-items-center ms-4">
                  <div className='ms-4 col-md-6'>
                    <label htmlFor="daily-start-date" className='mb-2'>Start Date:</label>
                    <input
                      type="date"
                      name="start_date"
                      id="daily-start-date"
                      className="form-control"
                      required=""
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>

                  <div className="ms-4 col-md-6">
                    <label htmlFor="daily-end-date" className='mb-2' >End Date:</label>
                    <input
                      type="date"
                      name="end_date"
                      id="daily-end-date"
                      className="form-control"
                      required=""
                      value={endDate}
                      min={startDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              )}

              {selectedPeriod === 'weekly' && (
                <div className="d-flex align-items-center ms-4">
                  <div className='ms-4 col-md-6'>
                    <label htmlFor="weekly-start-date" className='mb-2'>Start Date:</label>
                    <input
                      type="date"
                      name="start_date"
                      id="weekly-start-date"
                      className="form-control"
                      required=""
                      value={startDate}
                      onChange={(e) => {
                        const selectedStartDate = e.target.value;
                        const sevenDaysLater = new Date(new Date(selectedStartDate).getTime() + 7 * 24 * 60 * 60 * 1000);
                        const formattedEndDate = sevenDaysLater.toISOString().split('T')[0];
                        setStartDate(selectedStartDate);
                        setEndDate(formattedEndDate);
                      }}
                    />
                  </div>

                  <div className='ms-4 col-md-6'>
                    <label htmlFor="weekly-end-date" className="mb-2">End Date:</label>
                    <input
                      type="date"
                      name="end_date"
                      id="weekly-end-date"
                      className="form-control"
                      required=""
                      value={endDate}
                      min={startDate}
                      onChange={(e) => {
                        const selectedEndDate = e.target.value;
                        const sevenDaysBefore = new Date(new Date(selectedEndDate).getTime() - 7 * 24 * 60 * 60 * 1000);
                        const formattedStartDate = sevenDaysBefore.toISOString().split('T')[0];
                        if (new Date(formattedStartDate) > new Date(startDate)) {
                          setStartDate(formattedStartDate);
                        }
                        setEndDate(selectedEndDate);
                      }}
                    />
                  </div>
                </div>
              )}

              {selectedPeriod === 'monthly' && (
                <div className="d-flex justify-content-end">
                  <div className="col-md-5 ms-4">
                    <label htmlFor="startMonthPicker" className="mb-2">Start Month-Year:</label>
                    <input
                      className="form-control"
                      type="month"
                      id="startMonthPicker"
                      value={startMonth}
                      onChange={(e) => handleStartMonthChange(e.target.value)}
                      max={currentMonthYear}
                    />
                  </div>
                  <div className="col-md-5 ms-4">
                    <label htmlFor="endMonthPicker" className="mb-2">End Month-Year:</label>
                    <input
                      className="form-control"
                      type="month"
                      id="endMonthPicker"
                      min={startMonth}
                      value={endMonth}
                      onChange={(e) => handleEndMonthChange(e.target.value)}
                      max={currentMonthYear}
                    />
                  </div>
                </div>
              )}

              {selectedPeriod === 'yearly' && (
                <div className="d-flex align-items-center ms-4">
                  <div className='ms-4 col-md-3'>
                    <label htmlFor="yearPicker" className='mb-2'>Start Year:</label>
                    <DatePicker
                      selected={new Date(startYear, 0, 1)}
                      onChange={(date) => {
                        const newStartYear = date.getFullYear();
                        setStartYear(newStartYear);
                        if (newStartYear > endYear) {
                          setEndYear(newStartYear);
                        }
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                      renderCustomHeader={renderCustomHeader}
                      className="form-control"
                    />
                  </div>
                  <div className='ms-4 col-md-3'>
                    <label htmlFor="yearPickerEnd" className="mb-2">End Year:</label>
                    <DatePicker
                      selected={new Date(endYear, 0, 1)}
                      onChange={(date) => {
                        const newEndYear = date.getFullYear();
                        setEndYear(newEndYear);
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                      renderCustomHeader={renderCustomHeader}
                      includeDates={getYearOptions(startYear).map(year => new Date(year, 0, 1))}
                      className="form-control"
                    />
                  </div>
                </div>
              )}

            </div>

            <ResponsiveContainer width="100%" height={700} id='download_earnings'>
              <BarChart
                data={earningData.length > 0 ? earningData : [{ Date: '', admin_earnings: 0 }]}
                margin={{ top: 20, right: 10, left: 10, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="admin_earnings" name="Earnings" fill="rgb(87, 8, 97)" barSize={40}>
                  <LabelList dataKey="admin_earnings" content={renderCustomizedLabel} position="inside" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          
        </div>
      </main>
    </>
  );
}

