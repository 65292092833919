import { useNavigate } from "react-router";
import Hdashboard from "../../../components/Hdashboard";
import React, { useEffect, useState } from "react";
import { Most_Complaint} from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import DataTable from "react-data-table-component";
import generatePDF from "react-to-pdf";
import { CButton, CFormInput, CInputGroup } from "@coreui/react";
import FontAwesome from "react-fontawesome";
import customStyles from "../../../utils/dataTableStyles";

const options = {
    filename: "Complaint_Reports.pdf",
    page: {
        margin: 20
    }
};
const getTargetElement = () => document.getElementById("download_report");
const downloadPdf = () => {
    // Exclude Apply and Clear buttons from the PDF
    const excludedElements = document.querySelectorAll('.skip_pdf');
    excludedElements.forEach(element => element.style.display = 'none');

    // Generate PDF with the remaining content
    generatePDF(getTargetElement, options);

    // Restore the display of the excluded elements
    excludedElements.forEach(element => element.style.display = '');
};

export default function NineReport({isLoader}) {

    const [data, setData] = useState([])
    const navigate = useNavigate()

    const handleBack = () => {
        navigate(-1);
    };

    // Function to download reports in Excel format
    const downloadExcel = (data) => {

        const dataArray = Object.values(data);

        const filteredData = dataArray
        .filter(item => item !== null) // Filter out null items
        .map((item, index) => ({
            Sr_Number: index + 1,
            Id_Code: item.code_generate,
            Role: item.category,
            Name: item.full_name,
            Email: item.email,
            No_of_Complaint: item.overall_complaint_count
        }));

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'complaint_report.xlsx');
    };

    const columns = [
        {
            name: 'Sr. No',
            selector: 'count',
            sortable: false,
            width: "130px"
        },
        {
            name: 'ID Code',
            selector: row => row.code_generate,
            sortable: true,
            width: "180px",
            cell: row => row.code_generate == null ? "0000" : row.code_generate
        },
        {
            name: 'Role',
            selector: row => row.category,
            sortable: true,
            width: "230px"
        },
        {
            name: 'Name',
            selector: row => row.full_name,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "250px"
        },
        {
            name: 'Complaint Count',
            selector: row => row.overall_complaint_count,
            sortable: true,
            width: "200px"
        },
        {
            name: 'View Complaints',
            cell: (row) => (
                <button style={{ color: "rgb(87, 8, 97)", border: "none", background: "none" }}
                    onClick={() => navigate("/complaint_details", { state: { teacherId: row.teacher_id } })}
                >Click Here</button>
            ),
            sortable: true,
            width: "180px"
        },
    ]

    // Add Filter Functionality for select date

    const [requestData, setRequestData] = useState({});
    const [search, setSearch] = useState('');
    const [paginationOption, setPaginationOption] = useState(10);
    const [paginationOptions, setPaginationOptions] = useState([]);

    const FetchReportData = () => {

        // Call the API with the updated request data
        const requestDataWithSearch = { ...requestData, search: search };
        isLoader(true)
        Most_Complaint(requestDataWithSearch)
            .then((response) => {
                if (response.code === "1") {
                    const dataWithRowCount = response.data.map((row, index) => ({ ...row, count: index + 1 }));
                    setData(dataWithRowCount);
                    const newPaginationOptions = [];
                    for (let i = 10; i <= dataWithRowCount.length; i += 10) {
                        newPaginationOptions.push(i);
                    }
                    if (dataWithRowCount.length <= 100) { // Only add "All" option if data length is less than or equal to 100
                        newPaginationOptions.push(dataWithRowCount.length);
                    }
                    setPaginationOptions(newPaginationOptions);
                    isLoader(false)
                } else {
                    setData([]);
                    isLoader(false)
                }
            })
            .catch((error) => {
                setData([]);
                isLoader(false)
            });
    };

    useEffect(() => {
        FetchReportData();
    }, [requestData , search]);

    const handlePaginationChange = (rowsPerPage, currentPage) => {
        setPaginationOption(rowsPerPage);
    };



    return <>
        <JqueryLoad />
        <Hdashboard />
        <header className="dashboard-toolbar d-lg-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
                <div className="d-flex align-items-center">
                    <a className="menu-toggle align-items-center d-lg-none d-flex">
                        <i className="fa fa-bars" />
                    </a>
                    <a href="index.php" className="d-lg-none d-block brand-logo">
                        <img src="./assets/images/logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </header>

        <section className="main_sec" id="download_report">
            <div className="mt-4 mx-5">
                <div className="skip_pdf">
                    <span onClick={handleBack}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" ></path>
                        </svg>
                        <span className="ms-2">Back</span>
                    </span>
                </div>
                <p className="fs-4 fw-500 text-center mb-3">Most number of complaints received and the teachers   and organizations</p>

                <div className='d-flex justify-content-end'>
                    <button onClick={downloadPdf} className="btn-theme bg-yellow m-2 skip_pdf">Download PDF</button>
                    <button onClick={() => downloadExcel(data)} className="btn-theme bg-yellow m-2 skip_pdf">Download Excel</button>
                </div>

                <div className="skip_pdf mt-3 mb-4">
                    <CInputGroup>
                        <CFormInput type="text" placeholder="Search" value={search} onChange={(e) => {
                            setSearch(e.target.value);
                        }} />

                        <CButton style={{ backgroundColor: "rgb(87, 8, 97)", color: "light" }}>
                            <FontAwesome className="fa-solid fa-search" name="search" />
                        </CButton>
                    </CInputGroup>
                </div>


                <div className="mt-4" >
                    {data && data.length > 0 ? (
                        <>
                            <DataTable
                                columns={columns}
                                data={data}
                                pagination
                                paginationPerPage={paginationOption}
                                onChangeRowsPerPage={handlePaginationChange}
                                paginationRowsPerPageOptions={paginationOptions}
                                customStyles={customStyles}
                            />
                        </>
                    ) : (
                        <>
                            <p className="mt-5 text-center text-purple fw-500 fs-3 mt-5">No data Found</p>
                        </>
                    )}
                </div>
            </div>
        </section>
    </>
}

