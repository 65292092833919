export function loginRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/login" 
//    window.open(path,'mywindow').focus()
console.log(path)
    window.location.href = path;
}

export function homeRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/dashboard"
//    window.open(path,'mywindow').focus()
    console.log(path)
    window.location.href = path;  
}

export function logOutRedirectCall(){
  
  localStorage.removeItem("AToken");
  localStorage.removeItem("aID");
  localStorage.removeItem("adminRole");
  localStorage.removeItem("Search_Tab");

  loginRedirectCall()
}

export function loginRedirectCallWithDataStore(data){
  localStorage.setItem("AToken",data.token);
  localStorage.setItem("aID",data.id);
  localStorage.setItem("adminRole",data.role);
  localStorage.setItem("Search_Tab",1);
  
  // homeRedirectCall()
}


  